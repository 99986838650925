import { cloneDeep } from "lodash";
import { appConstants } from "../_constants";
import { VersementSupprementaire } from "../_entities/versementSupplementaire";
import { utils } from "../_helpers";

class VersementSuppls {
  montantVersement: VersementSupprementaire;
  hasAnneeFuturVersement: boolean;
  ArraymontantVersement: Array<VersementSupprementaire>;

  ancienMontantVersement?: Array<VersementSupprementaire>;

  montantAssurance: VersementSupprementaire;
  hasAnneeFuturAssurance: boolean;
  ArraymontantAssurance: Array<VersementSupprementaire>;

  ancienMontantAssurance?: Array<VersementSupprementaire>;

  montantApportInitVersement: VersementSupprementaire;
  hasAnneeFuturApportInitVersement: boolean;
  ArraymontantApportInitVersement: Array<VersementSupprementaire>;

  ancienMontantApportInitVersement?: Array<VersementSupprementaire>;

  constructor(
    montantVersement: VersementSupprementaire = new VersementSupprementaire(),
    hasAnneeFuturVersement: boolean = false,
    ArraymontantVersement: Array<VersementSupprementaire> = new Array<VersementSupprementaire>(),
    montantAssurance: VersementSupprementaire = new VersementSupprementaire(),
    hasAnneeFuturAssurance: boolean = false,
    ArraymontantAssurance: Array<VersementSupprementaire> = new Array<VersementSupprementaire>(),

    montantApportInitVersement: VersementSupprementaire = new VersementSupprementaire(),
    hasAnneeFuturApportInitVersement: boolean = false,
    ArraymontantApportInitVersement: Array<VersementSupprementaire> = new Array<VersementSupprementaire>(),
  ) {
    this.montantVersement = montantVersement;
    this.hasAnneeFuturVersement = hasAnneeFuturVersement;
    this.ArraymontantVersement = ArraymontantVersement;

    this.montantAssurance = montantAssurance
    this.hasAnneeFuturAssurance = hasAnneeFuturAssurance;
    this.ArraymontantAssurance = ArraymontantAssurance;

    this.montantApportInitVersement = montantApportInitVersement;
    this.hasAnneeFuturApportInitVersement = hasAnneeFuturApportInitVersement;
    this.ArraymontantApportInitVersement = ArraymontantApportInitVersement;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  montantAssuranceArraySerialize() {
    if (this.ArraymontantAssurance.length > 0) {
      return this.ArraymontantAssurance.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelAssurance(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new VersementSupprementaire(elt))
    }
    return this.ArraymontantAssurance
  }

  montantApportArraySerialize() {
    if (this.ArraymontantApportInitVersement.length > 0) {
      return this.ArraymontantApportInitVersement.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelmontantApport(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new VersementSupprementaire(elt))
    }
    return this.ArraymontantApportInitVersement
  }

  montantVersementMensuelSerialize() {
    if (this.ArraymontantVersement.length > 0) {
      return this.ArraymontantVersement.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelVersementMensuel(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new VersementSupprementaire(elt))
    }
    return this.ArraymontantVersement
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
        return this.montantVersement.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        return this.hasAnneeFuturVersement;
      case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
        return this.montantAssurance.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        return this.hasAnneeFuturAssurance;
      case appConstants.INPUT_KEYS.MONTANT_APPORT_INITIAL:
        return this.montantApportInitVersement.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        return this.hasAnneeFuturApportInitVersement;
      default:
        break;
    }
  }

  setMontantVersement(montant: number) {
    while (this.ArraymontantVersement.length) {
      this.ArraymontantVersement.pop();
    }
    if (this.ancienMontantVersement?.length) {
      while (this.ancienMontantVersement.length) {
        this.ancienMontantVersement.pop();
      }
    }
    this.hasAnneeFuturVersement = false;
    this.montantVersement.montant = montant;

  }

  setMontantAssurance(montant: number) {
    while (this.ArraymontantAssurance.length) {
      this.ArraymontantAssurance.pop();
    }
    if (this.ancienMontantAssurance?.length) {
      while (this.ancienMontantAssurance.length) {
        this.ancienMontantAssurance.pop();
      }
    }
    this.hasAnneeFuturAssurance = false;
    this.montantAssurance.montant = montant;
  }

  setMontantApport(montant: number) {
    while (this.ArraymontantApportInitVersement.length) {
      this.ArraymontantApportInitVersement.pop();
    }
    if (this.ancienMontantApportInitVersement?.length) {
      while (this.ancienMontantApportInitVersement.length) {
        this.ancienMontantApportInitVersement.pop();
      }
    }
    this.hasAnneeFuturApportInitVersement = false;
    this.montantApportInitVersement.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        return {
          leading: this.montantVersement.annee.value,
          trailling: this.montantVersement.devise.symbole,
          label: true,
          value: this.montantVersement.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        return {
          leading: this.montantAssurance.annee.value,
          trailling: this.montantAssurance.devise.symbole,
          label: true,
          value: this.montantAssurance.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        return {
          leading: this.montantApportInitVersement.annee.value,
          trailling: this.montantApportInitVersement.devise.symbole,
          label: true,
          value: this.montantApportInitVersement.montant,
        };
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        return this.getMontantVersement();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        return this.getMontantAssurance();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        return this.getMontantApport();
      default:
        throw new Error("Invalid label key");
    }
  }

  getMontantVersement() {
    if (this.ancienMontantVersement?.length && !this.ArraymontantVersement.length) {
      this.ArraymontantVersement = cloneDeep(this.ancienMontantVersement);
    } else {
      if (this.ArraymontantVersement.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantVersement);
          item.annee.value += i;
          item.id = i;
          this.ArraymontantVersement.push(item);
        }
      }
    }
    return this.ArraymontantVersement;
  }

  getMontantAssurance() {
    if (this.ancienMontantAssurance?.length && !this.ArraymontantAssurance.length) {
      this.ArraymontantAssurance = cloneDeep(this.ancienMontantAssurance);
    } else {
      if (this.ArraymontantAssurance.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantAssurance);
          item.annee.value += i;
          item.id = i;
          this.ArraymontantAssurance.push(item);
        }
      }
    }
    return this.ArraymontantAssurance;
  }

  getMontantApport() {
    if (this.ancienMontantApportInitVersement?.length && !this.ArraymontantApportInitVersement.length) {
      this.ArraymontantApportInitVersement = cloneDeep(this.ancienMontantApportInitVersement);
    } else {
      if (this.ArraymontantApportInitVersement.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantApportInitVersement);
          item.annee.value += i;
          item.id = i;
          this.ArraymontantApportInitVersement.push(item);
        }
      }
    }
    return this.ArraymontantApportInitVersement;
  }

  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        return "Versement supplementaire pour les 20 prochaines années";
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        return "Assurance décè pour les 20 prochaines années";
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        return "Assurance décè pour les 20 prochaines années";
      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
          this.ancienMontantVersement = cloneDeep(this.ArraymontantVersement);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
          this.ancienMontantAssurance = cloneDeep(this.ArraymontantAssurance);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
          this.ancienMontantApportInitVersement = cloneDeep(this.ArraymontantApportInitVersement);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
          if (!this.ancienMontantVersement) {
            this.hasAnneeFuturVersement = false;
          }
          while (this.ArraymontantVersement.length) {
            this.ArraymontantVersement.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
          if (!this.ancienMontantAssurance) {
            this.hasAnneeFuturAssurance = false;
          }
          while (this.ArraymontantAssurance.length) {
            this.ArraymontantAssurance.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
          if (!this.ancienMontantApportInitVersement) {
            this.hasAnneeFuturApportInitVersement = false;
          }
          while (this.ArraymontantApportInitVersement.length) {
            this.ArraymontantApportInitVersement.pop();
          }
          break;
        default:
          break;
      }
    }
  }

  updateMontantVersement(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArraymontantVersement.length;
    const index = this.ArraymontantVersement.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArraymontantVersement[j].montant || 0 : this.montantVersement.montant || 0;
        if (value === 0) {
          this.ArraymontantVersement[i].montant = montant;
        } else {
          this.ArraymontantVersement[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArraymontantVersement[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArraymontantVersement[i].montant = Math.round(value);
        this.ArraymontantVersement[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateMontantAssurance(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArraymontantAssurance.length;
    const index = this.ArraymontantAssurance.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArraymontantAssurance[j].montant || 0 : this.montantAssurance.montant || 0;
        if (value === 0) {
          this.ArraymontantAssurance[i].montant = montant;
        } else {
          this.ArraymontantAssurance[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArraymontantAssurance[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArraymontantAssurance[i].montant = Math.round(value);
        this.ArraymontantAssurance[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateMontantApport(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArraymontantApportInitVersement.length;
    const index = this.ArraymontantApportInitVersement.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArraymontantApportInitVersement[j].montant || 0 : this.montantApportInitVersement.montant || 0;
        if (value === 0) {
          this.ArraymontantApportInitVersement[i].montant = montant;
        } else {
          this.ArraymontantApportInitVersement[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArraymontantApportInitVersement[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArraymontantApportInitVersement[i].montant = Math.round(value);
        this.ArraymontantApportInitVersement[i].revalorisationAnnuelle = 0;
      }
    }
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        this.updateMontantVersement(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        this.updateMontantAssurance(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        this.updateMontantApport(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
        this.setMontantVersement(val);
        break;
      case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
        this.setMontantAssurance(val);
        break;
      case appConstants.INPUT_KEYS.MONTANT_APPORT_INITIAL:
        this.setMontantApport(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VERSEMENT:
        this.hasAnneeFuturVersement = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_ASSURRANCE:
        this.hasAnneeFuturAssurance = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_APPORT_INITIAL:
        this.hasAnneeFuturApportInitVersement = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }
}

export { VersementSuppls };
