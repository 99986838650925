import {
    ChargeScpiSynthese, 
    ChargeSynthese, 
    MontageFinancier,
    ScpiType, 
    SituationFoyer, 
    SolutionDispositif
  } from "../_entities";

class Synthese {
    montageFinancier: MontageFinancier;
    solutionDispositif: SolutionDispositif;
    charges: ChargeSynthese;
    situationFoyer: SituationFoyer;
    scpiType: ScpiType;
    chargeScpi: ChargeScpiSynthese;

    capitalAmortiPeriodeEmprunt: number | null;
    capitalAmortiPeriodeFiscale: number | null;
    creditPeriodeEmprunt: string;
    creditPeriodeFiscale: string;
    dateFinPeriodeEmprunt:	number | null;
    dateFinPeriodeFiscale:	number | null;
    epargneMoyennePeriodeEmprunt:	number | null;
    epargneMoyennePeriodeFiscale:	number | null;
    gainPeriodeEmprunt:	number | null;
    gainPeriodeFiscale:	number | null;
    id: number | null;
    labelCapitalAmortiPeriodeFiscale: string;
    labelCapitalAquisPeriodeEmprunt: string;
    labelEpargneMoyennePeriodeEmprunt: string;
    labelEpargneMoyennePeriodeFiscale: string;
    pourEndettementPeriodeEmprunt: number | null;
    pourEndettementPeriodeFiscale: number | null;
    rentabilitePeriodeEmprunt: number ;
    rentabilitePeriodeFiscale: number ;

    constructor(){
        this.montageFinancier = new MontageFinancier();
        this.solutionDispositif = new SolutionDispositif();
        this.charges = new ChargeSynthese();
        this.chargeScpi = new ChargeScpiSynthese();
        this.situationFoyer = new SituationFoyer();
        this.scpiType = new ScpiType();
        this.capitalAmortiPeriodeEmprunt = null;
        this.capitalAmortiPeriodeFiscale = null;
        this.creditPeriodeEmprunt = "";
        this.creditPeriodeFiscale = "";
        this.dateFinPeriodeEmprunt = null;
        this.dateFinPeriodeFiscale = null;
        this.epargneMoyennePeriodeEmprunt = null;
        this.epargneMoyennePeriodeFiscale = null;
        this.gainPeriodeEmprunt = null;
        this.gainPeriodeFiscale = null;
        this.id = null;
        this.labelCapitalAmortiPeriodeFiscale = "";
        this.labelCapitalAquisPeriodeEmprunt = "";
        this.labelEpargneMoyennePeriodeEmprunt = "";
        this.labelEpargneMoyennePeriodeFiscale = "";
        this.pourEndettementPeriodeEmprunt = null;
        this.pourEndettementPeriodeFiscale = null;
        this.rentabilitePeriodeEmprunt = 0;
        this.rentabilitePeriodeFiscale = 0;
    }

    hydrateSynthese(data: any){
        this.montageFinancier.fromJson(data?.montageFinancier);
        this.solutionDispositif.formJson(data?.solution);
        this.charges.formJson(data?.charge);
        this.chargeScpi.formJson(data?.chargeScpi);
        this.situationFoyer.formJson(data?.situation);
        this.scpiType.formJson(data?.solutionScpi);
        this.capitalAmortiPeriodeEmprunt = data?.capitalAmortiPeriodeEmprunt;
        this.capitalAmortiPeriodeFiscale = data?.capitalAmortiPeriodeFiscale;
        this.creditPeriodeEmprunt = data?.creditPeriodeEmprunt;
        this.creditPeriodeFiscale = data?.creditPeriodeFiscale;
        this.dateFinPeriodeEmprunt = data?.dateFinPeriodeEmprunt;
        this.dateFinPeriodeFiscale = data?.dateFinPeriodeFiscale;
        this.epargneMoyennePeriodeEmprunt = data?.epargneMoyennePeriodeEmprunt;
        this.epargneMoyennePeriodeFiscale = data?.epargneMoyennePeriodeFiscale;
        this.gainPeriodeEmprunt = data?.gainPeriodeEmprunt;
        this.gainPeriodeFiscale = data?.gainPeriodeFiscale;
        this.id = data?.id;
        this.labelCapitalAmortiPeriodeFiscale = data?.labelCapitalAmortiPeriodeFiscale;
        this.labelCapitalAquisPeriodeEmprunt = data?.labelCapitalAquisPeriodeEmprunt;
        this.labelEpargneMoyennePeriodeEmprunt = data?.labelEpargneMoyennePeriodeEmprunt;
        this.labelEpargneMoyennePeriodeFiscale = data?.labelEpargneMoyennePeriodeFiscale;
        this.pourEndettementPeriodeEmprunt = data?.pourEndettementPeriodeEmprunt;
        this.pourEndettementPeriodeFiscale = data?.pourEndettementPeriodeFiscale;
        this.rentabilitePeriodeEmprunt = data?.rentabilitePeriodeEmprunt;
        this.rentabilitePeriodeFiscale = data?.rentabilitePeriodeFiscale;
    }
}
export {Synthese}