import React from 'react';
import { Route, Redirect } from  "react-router-dom";
import { connector, PropsFromRedux } from "../_reducers";

const AuthRoute: React.FC<{
        component: any;
        path: string;
        exact: boolean;
    }& PropsFromRedux> = (props: any) => {
       
        const condition = getAuthUser();

    return  condition ? (<Route exact={props.exact}  path={props.path}   component={props.component} />) : 
        (<Redirect  to="/accueil"/>);
}

function getAuthUser(){
    let user:any = localStorage.getItem("userData");
    if (JSON.parse(user)){
        return false;
    }else{
        return true;
    }
}
const connected = connector(AuthRoute)
export {connected as AuthRoute};


