import { cloneDeep } from "lodash";
import { appConstants } from "../_constants";
import { AutreCharge } from "../_entities/AutreCharge";
import { LieuResidence } from "../_entities/LieuResidence";
import { LoyerDonne } from "../_entities/LoyerDonne";
import { LoyerPercu } from "../_entities/LoyerPercu";
import { utils } from "../_helpers";

class Depense {
  loyerDonne: LoyerDonne;
  autreCharge: AutreCharge;
  loyerPercu: LoyerPercu;
  hasAnneeFuturLoyerDonne: boolean;
  hasAnneeFuturAutreCharge: boolean;
  hasAnneeFuturLoyerPercu: boolean;
  loyerDonnes: Array<LoyerDonne>;
  autreCharges: Array<AutreCharge>;
  loyerPercus: Array<LoyerPercu>;

  ancienLoyerDonnes?: Array<LoyerDonne>;
  ancienAutreCharges?: Array<AutreCharge>;
  ancienLoyerPercus?: Array<LoyerPercu>;

  static lieuResidences: Array<LieuResidence>;

  constructor(
    loyerDonne: LoyerDonne = new LoyerDonne(),
    autreCharge: AutreCharge = new AutreCharge(),
    loyerPercu: LoyerPercu = new LoyerPercu(),
    hasAnneeFuturLoyerDonne: boolean = false,
    hasAnneeFuturAutreCharge: boolean = false,
    hasAnneeFuturLoyerPercu: boolean = false,
    loyerDonnes: Array<LoyerDonne> = new Array<LoyerDonne>(),
    autreCharges: Array<AutreCharge> = new Array<AutreCharge>(),
    loyerPercus: Array<LoyerPercu> = new Array<LoyerPercu>()
  ) {
    this.loyerDonne = loyerDonne;
    this.autreCharge = autreCharge;
    this.loyerPercu = loyerPercu;
    this.hasAnneeFuturLoyerDonne = hasAnneeFuturLoyerDonne;
    this.hasAnneeFuturAutreCharge = hasAnneeFuturAutreCharge;
    this.hasAnneeFuturLoyerPercu = hasAnneeFuturLoyerPercu;
    this.loyerDonnes = loyerDonnes;
    this.autreCharges = autreCharges;
    this.loyerPercus = loyerPercus;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  loyerDonnesArraySerialize() {
    if (this.loyerDonnes.length > 0) {
      return this.loyerDonnes.map((elt) => this.serialize(elt))
    }
    return []
  }

  autreChargesArraySerialize() {
    if (this.autreCharges.length > 0) {
      return this.autreCharges.map((elt) => this.serialize(elt))
    }
    return []
  }

  loyerPercusArraySerialize() {
    if (this.loyerPercus.length > 0) {
      return this.loyerPercus.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelLoyerDonner(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new LoyerDonne(elt))
    }
    return this.loyerDonnes
  }

  hydrateArrayModelAutreCharge(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new AutreCharge(elt))
    }
    return this.autreCharges
  }

  hydrateArrayModelLoyerPercue(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new LoyerPercu(elt))
    }
    return this.loyerPercus

  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.LOYERS_DONNES:
        return this.loyerDonne.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        return this.hasAnneeFuturLoyerDonne;
      case appConstants.INPUT_KEYS.LOYERS_PERCU:
        return this.loyerPercu.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        return this.hasAnneeFuturLoyerPercu;
      case appConstants.INPUT_KEYS.AUTRES_CHANGE:
        return this.autreCharge.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        return this.hasAnneeFuturAutreCharge;
      default:
        break;
    }
  }

  setLoyerDonneMontant(montant: number) {
    while (this.loyerDonnes.length) {
      this.loyerDonnes.pop();
    }
    if (this.ancienLoyerDonnes?.length) {
      while (this.ancienLoyerDonnes.length) {
        this.ancienLoyerDonnes.pop();
      }
    }
    this.hasAnneeFuturLoyerDonne = false;
    this.loyerDonne.montant = montant;
  }

  setLoyerPercuMontant(montant: number) {
    while (this.loyerPercus.length) {
      this.loyerPercus.pop();
    }
    if (this.ancienLoyerPercus?.length) {
      while (this.ancienLoyerPercus.length) {
        this.ancienLoyerPercus.pop();
      }
    }
    this.hasAnneeFuturLoyerPercu = false;
    this.loyerPercu.montant = montant;
  }

  setAutreChargeMontant(montant: number) {
    while (this.autreCharges.length) {
      this.autreCharges.pop();
    }
    if (this.ancienAutreCharges?.length) {
      while (this.ancienAutreCharges.length) {
        this.ancienAutreCharges.pop();
      }
    }
    this.hasAnneeFuturAutreCharge = false;
    this.autreCharge.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.LOYERS_DONNES:
        this.setLoyerDonneMontant(val);
        break;
      case appConstants.INPUT_KEYS.LOYERS_PERCU:
        this.setLoyerPercuMontant(val);
        break;
      case appConstants.INPUT_KEYS.AUTRES_CHANGE:
        this.setAutreChargeMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        this.hasAnneeFuturLoyerPercu = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        this.hasAnneeFuturLoyerDonne = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        this.hasAnneeFuturAutreCharge = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        this.updateLoyerPercuItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        this.updateLoyerDonneItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        this.updateAutreChargeItem(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  updateLoyerPercuItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.loyerPercus.length;
    const index = this.loyerPercus.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.loyerPercus[j].montant || 0 : this.loyerPercu.montant || 0;
        if (value === 0) {
          this.loyerPercus[i].montant = montant;
        } else {
          this.loyerPercus[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.loyerPercus[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.loyerPercus[i].montant = Math.round(value);
        this.loyerPercus[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateLoyerDonneItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.loyerDonnes.length;
    const index = this.loyerDonnes.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.loyerDonnes[j].montant || 0: this.loyerDonne.montant|| 0;
        if (value === 0) {
          this.loyerDonnes[i].montant = montant;
        } else {
          this.loyerDonnes[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.loyerDonnes[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.loyerDonnes[i].montant = Math.round(value);
        this.loyerDonnes[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateAutreChargeItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.autreCharges.length;
    const index = this.autreCharges.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.autreCharges[j].montant || 0 : this.autreCharge.montant || 0;
        if (value === 0) {
          this.autreCharges[i].montant = montant;
        } else {
          this.autreCharges[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.autreCharges[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.autreCharges[i].montant = Math.round(value);
        this.autreCharges[i].revalorisationAnnuelle = 0;
      }
    }
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        return {
          leading: this.loyerDonne.annee.value,
          trailling: this.loyerDonne.devise.symbole,
          label: true,
          value: this.loyerDonne.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        return {
          leading: this.loyerPercu.annee.value,
          trailling: this.loyerPercu.devise.symbole,
          label: true,
          value: this.loyerPercu.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        return {
          leading: this.autreCharge.annee.value,
          trailling: this.autreCharge.devise.symbole,
          label: true,
          value: this.autreCharge.montant,
        };

      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        return this.getLoyerDonnes();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        return this.getLoyerPercus();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        return this.getAutreCharges();

      default:
        throw new Error("Invalid label key");
    }
  }

  getLoyerDonnes() {
    if (this.ancienLoyerDonnes?.length && !this.loyerDonnes.length) {
      this.loyerDonnes = cloneDeep(this.ancienLoyerDonnes);
    } else {
      if (this.loyerDonnes.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.loyerDonne);
          item.annee.value += i;
          item.id = i;
          this.loyerDonnes.push(item);
        }
      }
    }
    return this.loyerDonnes;
  }

  getLoyerPercus() {
    if (this.ancienLoyerPercus?.length && !this.loyerDonnes.length) {
      this.loyerDonnes = cloneDeep(this.ancienLoyerPercus);
    } else {
      if (this.loyerPercus.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.loyerPercu);
          item.annee.value += i;
          item.id = i;
          this.loyerPercus.push(item);
        }
      }
    }
    return this.loyerPercus;
  }


  getAutreCharges() {
    if (this.ancienAutreCharges?.length && !this.autreCharges.length) {
      this.autreCharges = cloneDeep(this.ancienAutreCharges);
    } else {
      if (this.autreCharges.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.autreCharge);
          item.annee.value += i;
          item.id = i;
          this.autreCharges.push(item);
        }
      }
    }
    return this.autreCharges;
  }


  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
        return "Loyers donnés sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
        return "Loyers perçus sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
        return "Autres change sur les 20 prochaines années";

      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
          this.ancienLoyerDonnes = cloneDeep(this.loyerDonnes);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
          this.ancienLoyerPercus = cloneDeep(this.loyerPercus);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
          this.ancienAutreCharges = cloneDeep(this.autreCharges);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES:
          if (!this.ancienLoyerDonnes) {
            this.hasAnneeFuturLoyerDonne = false;
          }
          while (this.loyerDonnes.length) {
            this.loyerDonnes.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU:
          if (!this.ancienLoyerPercus) {
            this.hasAnneeFuturLoyerPercu = false;
          }
          while (this.loyerPercus.length) {
            this.loyerPercus.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE:
          if (!this.ancienAutreCharges) {
            this.hasAnneeFuturAutreCharge = false;
          }
          while (this.autreCharges.length) {
            this.autreCharges.pop();
          }
          break;
        default:
          break;
      }
    }
  }
}

export { Depense };
