import { Revenu } from "./Revenu";
import { QuotientFamilial, ResultatFiscale } from "../_entities";
import { ResultatFoncier } from "./ResultatFoncier";
import { Autre } from "./Autre";
import { Endettement } from "./Endettement";
import { Client } from "./client";
import { DetailsIFI } from "./DetailsIFI";

class SituationFiscale {
  activeSection: string;
  revenu: Revenu;
  autre: Autre;
  resultatFoncier: ResultatFoncier;
  endettement: Endettement;
  quotientFamilial: QuotientFamilial;
  resultatFiscale: ResultatFiscale;
  client: Client;
  detail: DetailsIFI;
  resultatFiscales: Array<any>;
  idDeclarationRevenu: number | null;
  idDetail: number | null;
  idResultatFoncier: number | null;

  constructor() {
    this.activeSection = "revenus";
    this.revenu = new Revenu();
    this.autre = new Autre();
    this.client = new Client();
    this.detail = new DetailsIFI();
    this.endettement = new Endettement();
    this.quotientFamilial = new QuotientFamilial();
    this.resultatFiscale = new ResultatFiscale();
    this.resultatFoncier = new ResultatFoncier();
    this.resultatFiscales = new Array<any>();
    this.idDeclarationRevenu = null;
    this.idDetail = null;
    this.idResultatFoncier = null;
  }

  changeSection(value: string) {
    this.activeSection = value;
  }

  getPrevSection(): string {
    switch (this.activeSection) {
      case "resultats":
        return "revenus";
      case "details":
        return "resultats";
      case "client":
        return "details";
      default:
        return this.activeSection;
    }
  }

  getNextSection(): string {
    switch (this.activeSection) {
      case "revenus":
        return "resultats";
      case "resultats":
        return "details";
      case "details":
        return "client";
      default:
        return this.activeSection;
    }
  }
  getPartenaire(){
    let partenaire: any = localStorage.getItem("userData");
    return JSON.parse(partenaire);
  }
  
}

export { SituationFiscale };
