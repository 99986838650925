import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import PlafonnementAvantagesFiscauxRow from "./PlafonnementAvantagesFiscauxRow";

class PlafonnementAvantagesFiscaux extends Component<PropsFromRedux, any> {
  render() {
    return (
      <div className="d-flex flex-column declarant-wrapper3 px-2 pb-2">
        <h1 className="title-section" style={{ textAlign: "left" }}>
          <span className="px-2 big-text font-weight-bold">Plafonnement des avantages fiscaux</span>
        </h1>
        <PlafonnementAvantagesFiscauxRow />
      </div>
    );
  }
}

export default connector(PlafonnementAvantagesFiscaux);
