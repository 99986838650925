import { appConstants } from "../_constants";

class Duflot {
    engargement_min: boolean;
    engargement_max: boolean;
    metropole:boolean;
    outre_mere:boolean;
    taux:number;
    plafond_investissement: number;
    passage: number | null;
    regime:boolean;
    lmnp:boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    id: number | null ;
    loi: String;


    constructor(){
        this.engargement_min = true;
        this.engargement_max = false;
        this.metropole = true;
        this.outre_mere = false;
        this.taux = 18;
        this.plafond_investissement = 300000;
        this.passage = 9;
        this.regime = true;
        this.lmnp = false
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.id = null ;
        this.loi =  "DUFLOT";
    
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }
    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.DUFLOT.ENGARGEMENT_MIN:
                this.engargement_min = true;
                this.engargement_max = false;
            break
            case appConstants.DUFLOT.ENGARGEMENT_MAX:
                this.engargement_min = false;
                this.engargement_max = true;
            break
            case appConstants.DUFLOT.METROPOLE:
                this.metropole = true;
                this.outre_mere = false;
                this.taux = 18
            break
            case appConstants.DUFLOT.OUTRE_MERE:
                this.outre_mere = true;
                this.metropole = false;
                this.taux = 29
            break
            case appConstants.DUFLOT.TAUX:
                this.taux = value;
            break
            case appConstants.DUFLOT.PASSAGE:
                this.passage = value;
            break
            case appConstants.DUFLOT.PLAFOND_INVESTISSEMENT:
                this.plafond_investissement = value;
            break
            case appConstants.DUFLOT.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.DUFLOT.LMNP:
                this.regime = false;
                this.lmnp = true;
            break
            case appConstants.DUFLOT.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.DUFLOT.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.DUFLOT.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.DUFLOT.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.DUFLOT.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.DUFLOT.PENDANT:
                this.pendant = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.DUFLOT.ENGARGEMENT_MIN:
            return this.engargement_min; 
            case appConstants.DUFLOT.ENGARGEMENT_MAX:
            return this.engargement_max; 
            case appConstants.DUFLOT.METROPOLE:
            return this.metropole; 
            case appConstants.DUFLOT.OUTRE_MERE:
            return this.outre_mere; 
            case appConstants.DUFLOT.TAUX:
            return this.taux; 
            case appConstants.DUFLOT.PASSAGE:
            return this.passage;
            case appConstants.DUFLOT.PLAFOND_INVESTISSEMENT:
            return this.plafond_investissement;
            case appConstants.DUFLOT.LMNP:
            return this.lmnp;
            case appConstants.DUFLOT.REGIME:
            return this.regime; 
            case appConstants.DUFLOT.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.DUFLOT.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.DUFLOT.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.DUFLOT.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.DUFLOT.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.DUFLOT.PENDANT:
            return this.pendant; 
            default:
            break;
        }
      return false;
    }
}
export {Duflot}