import React, { Component } from 'react';
import { FaRegFileExcel} from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { PropsFromRedux, connector } from "../../_reducers";
import { Loader } from "../../_components";

class PageGarde extends Component<PropsFromRedux,any> {

    constructor(props: any){
        super(props);
        this.onSelectedFile = this.onSelectedFile.bind(this)
        this.state={
            fileSelected: null,
            showModal: false,
            data: null,
            showOuiNon: false,
            showOk: true,
            size: "",
            title: "Easy Defisc",
            isUpload: false
        }
    }

     async onSelectedFile(e: any){
        var typeFile  = e.target.files[0]?.type;
        //console.log(typeFile);
        if(e.target.files[0]?.size > 1048576){
            return await this.setState({
                showModal: true,   
                title: "Erreur",
                size: "sm",
                showOuiNon: false,
                showOk: true,
                data: <p>La taille de votre fichier est tres grand,<br/> 
                Taille max valide est de <strong>1,04 Mbyte</strong></p> 
            });
        }else{
            if("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" === typeFile || "application/vnd.ms-excel" === typeFile){
                this.setState({ fileSelected: e.target.files[0] });
                return await this.setState({
                    showModal: true,   
                    title: "Import de la page de garde",
                    size: "sm",
                    showOuiNon: true,
                    showOk: false,
                    data: <p>Voulez-vous importer votre page de garde, <br/> 
                    <strong>{e.target.files[0].name}</strong> </p> 
                });
            }else{
                await this.setState({ fileSelected: null });
                return await this.setState({
                    showModal: true,   
                    title: "Erreur de fichier!",
                    size: "sm",
                    showOuiNon: false,
                    showOk: true,
                    data: <p>Le format du fichier utilisé ne peut être pris en compte.  <br/> 
                    Veuillez sélectionnez un fichier de type<strong> xlsx ou xls</strong> </p> 
                  });
            }
        }
        
    }

    async onFileUpload(){
        const { model, replaceModel } = this.props
        model.loading = true;
        replaceModel(model);
        const formData = new FormData();
        formData.append(
          "file",
          this.state.fileSelected,
          this.state.fileSelected.name
        );
        let res = await model.addFile(formData,"/dossier/pagegarde/upload/");
        model.loading = false;
        replaceModel(model);
        if(!res){
            return await this.setState({
                showModal: true,   
                title: "Erreur d' importation!",
                size: "sm",
                showOuiNon: false,
                showOk: true,
                data: <p>Une erreur s'est produite lors de l'importation du fichier </p> 
              });
        }else{
            return await this.setState({
                isUpload: true
              });
        }
      };

      clearFils(e:any){
        e.target.value = null;
    }

    render() {
        return (
            <div className="container-fluid">
                <Loader />
                {
                    !this.state.isUpload &&
                    <div className="d-flex justify-content-center align-items-center px-3">
                        <input type="file" name="file" id="file" className="inputfile" onClick={(e)=>this.clearFils(e)} onChange={(e) => this.onSelectedFile(e)}/>
                        <label htmlFor="file"> 
                            <FaRegFileExcel size="30"/>
                            {(this.state.fileSelected === null|| this.state.fileSelected === undefined )? "Charger votre page de garde":this.state.fileSelected?.name}
                        </label>
                    </div>
                }
                {
                    this.state.isUpload &&
                    <p className="text-center">Votre page de garde a bien été importée.</p>
                }
                {this.getModal()}
            </div>
        );
    }

    getModal(): React.ReactNode {
        const { showModal, data, title , size} = this.state;
        return (
            <Modal
              show={showModal}
              onHide={() => this.closeModal()}
              backdrop={true}
              data= {data}
              title = {title}
              dialogClassName={size}
              >
              <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center p-2">{data}</Modal.Body>
              <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
            </Modal>
        );
    }
  
    closeModal( ) {
        this.setState({
            showModal: false,
            showOk: true,
            showOuiNon: false,
            title: "Easy Defisc",
        });
    //     const { model } = this.props.Section;
    //    return model.solutionFiscale.support.estimateurFrais;
    }
  
    getFooter() {
        
        return (
          <>
              {
                  this.state.showOk &&
                  <div className="w-100 d-flex justify-content-end">
              
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => this.closeModal()}
                      >
                          ok
                      </button>
                  </div>
              }
  
              {
                  this.state.showOuiNon &&
                  <div className="w-100 d-flex justify-content-between">
              
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => this.closeModal()}
                      >
                          Non
                      </button>
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => {this.closeModal(); this.onFileUpload()}}
                      >
                          oui
                      </button>
                  </div>
              }
          </>
        );
      }
}
const connected = connector(PageGarde);
export { connected as PageGarde };