export class ScpiType{
    id : any = 0;
    nomScpi : any = '';
    valeurPart : any = '';
    nbPart : any = '';
    valorisationNette : any = '';
    txRevalorisationAnnuel : any = '';
    revenuMensuel : any = '';
    revaluationLoyer : any = '';
    dateAcquisistion : any = '';
    duree : any = '';
    franchiseAvant1erLoyer : any = '';
    franchiseSansLoyer : any = '';
    labelTxRevalorisationAnnuel : any = '';
    labelRevaluationLoyer : any = '';
    labelCharge : any = '';

    formJson(data : any){
        if(!data){
            return;
        }else{
            this.id = data?.id;
            this.nomScpi = data?.nomScpi;
            this.valeurPart = data?.valeurPart;
            this.nbPart = data?.nbPart;
            this.valorisationNette = data?.valorisationNette;
            this.txRevalorisationAnnuel = data?.txRevalorisationAnnuel;
            this.revenuMensuel = data?.revenuMensuel;
            this.revaluationLoyer = data?.revaluationLoyer;
            this.dateAcquisistion = data?.dateAcquisistion;
            this.duree = data?.duree;
            this.franchiseAvant1erLoyer = data?.franchiseAvant1erLoyer;
            this.franchiseSansLoyer = data?.franchiseSansLoyer;
            this.labelTxRevalorisationAnnuel = data?.labelTxRevalorisationAnnuel;
            this.labelRevaluationLoyer = data?.labelRevaluationLoyer;
        }
    }
}