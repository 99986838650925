class Devise {
  id: number;
  nom: string;
  symbole: string;

  constructor(
    id: number = 0,
    nom: string = 'Euro',
    symbole: string = '€'
  ) {
    this.id = id;
    this.nom = nom;
    this.symbole = symbole;
  }
}

export { Devise }