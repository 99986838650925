class Lot{
    chargeCopropriete: number;
    chargeGarantie: number;
    dateLivraison: string | "";
    dureeExoneration: number;
    fraisGestion: number;
    id: number | null;
    loi: string;
    loyerMensuel: number;
    nomProdImoSaisie: string;
    partenaireId: number;
    pourcExoneration: number;
    produitFiscalMontant: number;
    surface: number;
    taxeFonciere: number;
    zone: {};
    aful: number;
    difLoyer: number;
    fraisDeductible: number;
    loyerMensuelRevalAnnuel: number;
    loyerMensuelRevalTaux: number;
    plafondLoyer: number;
    produitFiscalNonAmorti: number;
    produitFiscalTaux: number;
    tva: number;
    valMobFoncier: number;
    zoneScpi: any;


    constructor(data?:any){
        this.chargeCopropriete = data?.chargeCopropriete || 0;
        this.chargeGarantie = data?.chargeGarantie || 0;
        this.dateLivraison = data?.dateLivraison || "";
        this.dureeExoneration = data?.dureeExoneration || 0;
        this.fraisGestion = data?.fraisGestion || 0;
        this.id = data?.id || null;
        this.loi = data?.loi || "";
        this.loyerMensuel = data?.loyerMensuel || 0;
        this.nomProdImoSaisie = data?.nomProdImoSaisie || "";
        this.partenaireId = data?.partenaireId || 0;
        this.pourcExoneration = data?.pourcExoneration || 0;
        this.produitFiscalMontant = data?.produitFiscalMontant || 0;
        this.surface = data?.surface || 0;
        this.taxeFonciere = data?.taxeFonciere || 0;
        this.zone = data?.zone || 0;
        this.aful = data?.aful || 0;
        this.difLoyer = data?.difLoyer || 0;
        this.fraisDeductible = data?.fraisDeductible || 0;
        this.loyerMensuelRevalAnnuel = data?.loyerMensuelRevalAnnuel || 0;
        this.loyerMensuelRevalTaux = data?.loyerMensuelRevalTaux || 0;
        this.plafondLoyer = data?.plafondLoyer || 0;
        this.produitFiscalNonAmorti = data?.produitFiscalNonAmorti || 0;
        this.produitFiscalTaux = data?.produitFiscalTaux || 0;
        this.tva = data?.tva || 0;
        this.valMobFoncier = data?.valMobFoncier || 0;
        this.zoneScpi = data?.zoneScpi || "";
    }
}export {Lot}