import React, { Component } from "react";
import { InputNumber } from "./InputNumber";

class CustomInput extends Component<any, {}> {
  render() {
    const { config } = this.props;
    return (
      <div className="pl-0 pr-0 d-flex justify-content-center align-items-center" style={config?.border}>
        {config?.leading && (
          <small className="font-weight-light text-blue pr-2">
            {config?.leading}
          </small>
        )}
        <div
          className={
            "d-flex px-0 " +
            (!config?.label ? "border-light-gray " : "") +
            (config?.leading ? "w-100" : "w-100") 
          }
        >
          {config?.label && (
            <span className="form-control2 flex-1 mr-1 text-center text-dark">
              {config?.value}
            </span>
          )}
          {!config?.label && config?.type === "number" && <InputNumber config={config}/>}
          {config?.trailling && (
            <div className="align-items-end training py-1 text-center">
              <small className="font-weight-light text-blue">
                {config?.trailling}
              </small>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { CustomInput };
