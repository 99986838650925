import { appConstants } from "../_constants";
import { TauxPinel } from "./TauxPinel";

class Pinel {
    engargement_min: boolean;
    engargement_max: boolean;
    metropole:boolean;
    outre_mere:boolean;
    taux:number;
    plafond_investissement: number;
    passage: number | null;
    regime:boolean;
    lmnp:boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    id: number | null;
    loi: string | null;
    annee_reduction1: boolean;
    annee_reduction2: boolean;
    annee_reduction3: boolean;

    tauxOutreMer : Array<TauxPinel> | null;
    tauxMetropole : Array<TauxPinel> | null; 

    constructor(){
        this.engargement_min = true;
        this.engargement_max = false;
        this.metropole = true;
        this.outre_mere = false;
        this.taux = 10.5;
        this.plafond_investissement = 300000;
        this.passage = 13;
        this.regime = true;
        this.lmnp = false
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.id = null;
        this.loi = "PINEL";
        this.annee_reduction1 = new Date().getFullYear() === 2022? true:false;
        this.annee_reduction2 = new Date().getFullYear() === 2023? true:false;
        this.annee_reduction3 = new Date().getFullYear() === 2024? true:false;

        this.tauxOutreMer = null;
        this.tauxMetropole = null;
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }

    hydrateTauxPinel(data:any){
        this.tauxMetropole = data.PINEL_OUTREMER_NON;
        this.tauxOutreMer = data.PINEL_OUTREMER_OUI;
    }

    setTauxFiscalite(){
        if(this.metropole === true){
            this.tauxMetropole?.map((taux:TauxPinel, i)=>{
                if(this.metropole === true &&  taux.outremer === false && this.engargement_max === true && taux.nbAnnee === 9){
                    if(this.annee_reduction1 && taux.annee === 2022){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                    else if(this.annee_reduction2 && taux.annee === 2023){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }else if(this.annee_reduction3 && taux.annee === 2024){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                }else if(this.metropole === true &&  taux.outremer === false && this.engargement_min === true && taux.nbAnnee === 6){
                    if(this.annee_reduction1 && taux.annee === 2022){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                    else if(this.annee_reduction2 && taux.annee === 2023){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }else if(this.annee_reduction3 && taux.annee === 2024){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                }
            });
        }else{
            this.tauxOutreMer?.map((taux: TauxPinel, i)=>{
                if(this.outre_mere === true &&  taux.outremer === true && this.engargement_min === true && taux.nbAnnee === 6){
                    if(this.annee_reduction1 && taux.annee === 2022){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                    else if(this.annee_reduction2 && taux.annee === 2023){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }else if(this.annee_reduction3 && taux.annee === 2024){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                }else if(this.outre_mere === true &&  taux.outremer === true && this.engargement_max === true && taux.nbAnnee === 9){
                    if(this.annee_reduction1 && taux.annee === 2022){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                    else if(this.annee_reduction2 && taux.annee === 2023 ){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }else if(this.annee_reduction3 && taux.annee === 2024){
                        this.taux = taux.taux;
                        this.plafond_investissement = taux.plafond;
                    }
                }
            })
        }
        

        
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.PINELS.ENGARGEMENT_MIN:
                this.engargement_min = true;
                this.engargement_max = false;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.ENGARGEMENT_MAX:
                this.engargement_min = false;
                this.engargement_max = true;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.METROPOLE:
                this.metropole = true;
                this.outre_mere = false;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.OUTRE_MERE:
                this.outre_mere = true;
                this.metropole = false;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.TAUX:
                this.taux = value;
            break
            case appConstants.PINELS.PASSAGE:
                this.passage = value;
            break
            case appConstants.PINELS.PLAFOND_INVESTISSEMENT:
                this.plafond_investissement = value;
            break
            case appConstants.PINELS.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.PINELS.LMNP:
                this.regime = false;
                this.lmnp = true;
            break
            case appConstants.PINELS.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.PINELS.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.PINELS.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.PINELS.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.PINELS.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.PINELS.PENDANT:
                this.pendant = value;
            break
            case appConstants.PINELS.ANNE1:
                this.annee_reduction1 = true;
                this.annee_reduction2 = false;
                this.annee_reduction3 = false;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.ANNE2:
                this.annee_reduction1 = false;
                this.annee_reduction2 = true;
                this.annee_reduction3 = false;
                this.setTauxFiscalite();
            break
            case appConstants.PINELS.ANNE3:
                this.annee_reduction1 = false;
                this.annee_reduction2 = false;
                this.annee_reduction3 = true;
                this.setTauxFiscalite();
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.PINELS.ENGARGEMENT_MIN:
            return this.engargement_min; 
            case appConstants.PINELS.ENGARGEMENT_MAX:
            return this.engargement_max; 
            case appConstants.PINELS.METROPOLE:
            return this.metropole; 
            case appConstants.PINELS.OUTRE_MERE:
            return this.outre_mere; 
            case appConstants.PINELS.TAUX:
            return this.taux; 
            case appConstants.PINELS.PASSAGE:
            return this.passage;
            case appConstants.PINELS.PLAFOND_INVESTISSEMENT:
            return this.plafond_investissement;
            case appConstants.PINELS.LMNP:
            return this.lmnp;
            case appConstants.PINELS.REGIME:
            return this.regime; 
            case appConstants.PINELS.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.PINELS.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.PINELS.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.PINELS.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.PINELS.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.PINELS.PENDANT:
            return this.pendant; 
            case appConstants.PINELS.ANNE1:
            return this.annee_reduction1; 
            case appConstants.PINELS.ANNE2:
            return this.annee_reduction2; 
            case appConstants.PINELS.ANNE3:
            return this.annee_reduction3; 
            default:
            break;
        }
      return false;
    }
}
export {Pinel}