class CaracteristiqueFinancement{
    amortissable: boolean;
    inFine: boolean;
    montant_financement: number| 0;
    duree: number | null;
    taux_interet: number | null;
    assurance_dece_invalid: number| null;
    rembourssement_capital: boolean;
    rembourssement_interet: boolean;
    mois_rembourssement_capital: number ;
    mois_rembourssement_interet: number ;
    montantInteretDiffere: number;
    
    id:number | null;
    mensualiteCreditTotal: number;

    constructor(
        duree = null,
        txIntere= null,
         mensualiteTotal=0, 
         montantdif=0 ,
         amortissable: boolean = true,
         infine: boolean = false,
         ){
        this.montant_financement = 0;
        this.duree = duree;
        this.taux_interet = txIntere;
        this.assurance_dece_invalid = null;
        this.rembourssement_capital = false;
        this.rembourssement_interet = false;
        this.mois_rembourssement_capital= 12 ;
        this.mois_rembourssement_interet= 12 ;
        this.id = null;
        this.mensualiteCreditTotal = mensualiteTotal;
        this.montantInteretDiffere = montantdif;
        this.amortissable = amortissable;
        this.inFine = infine;
    }

    resetInput(){
        this.montant_financement = 0;
        this.mensualiteCreditTotal = 0;
        this.taux_interet = 0;
        this.duree = 0;
        this.rembourssement_capital = false;
        this.rembourssement_interet = false;
        this.mois_rembourssement_capital = 12;
        this.mois_rembourssement_interet = 12;
        this.montantInteretDiffere = 0;
        this.amortissable = true;
        this.inFine = false;
    }

}
export { CaracteristiqueFinancement}