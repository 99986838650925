import { Depense } from "./Depense";
import { Emprunt } from "../_entities";
import { appConstants } from "../_constants";

class Endettement {
  depense: Depense;
  emprunt1: Emprunt;
  emprunt2: Emprunt;
  emprunt3: Emprunt;
  checkDetailEmprunt: boolean;
  idEmprunt: number | null;

  constructor(
    depense: Depense = new Depense(),
    emprunt1: Emprunt = new Emprunt(),
    emprunt2: Emprunt = new Emprunt(),
    emprunt3: Emprunt = new Emprunt()
  ) {
    this.depense = depense;
    this.emprunt1 = emprunt1;
    this.emprunt2 = emprunt2;
    this.emprunt3 = emprunt3;
    this.checkDetailEmprunt = false ;
    this.idEmprunt =null;
  }

  getValue(key: string): any {
    switch (key) {
      case "CHECK_DETAIL_EMPREUNT":
       return this.checkDetailEmprunt;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DATE_DEBUT:
        return this.emprunt2.dateDebut;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE:
        return this.emprunt2.montant;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DUREE:
        return this.emprunt2.duree;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_TAUX:
        return this.emprunt2.taux;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DATE_DEBUT:
        return this.emprunt3.dateDebut;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE:
        return this.emprunt3.montant;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DUREE:
        return this.emprunt3.duree;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_TAUX:
        return this.emprunt3.taux;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DATE_DEBUT:
        return this.emprunt1.dateDebut;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE:
        return this.emprunt1.montant;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DUREE:
        return this.emprunt1.duree;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_TAUX:
        return this.emprunt1.taux;
      default:
        break;
    }
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked, value } = e.target;
    let res = true;
    switch (id) {
      case "CHECK_DETAIL_EMPREUNT":
        this.checkDetailEmprunt = checked;
        break
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DATE_DEBUT:
        this.emprunt2.dateDebut = value;
        this.emprunt2.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE:
        this.emprunt2.montant = value;
        this.emprunt2.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DUREE:
        this.emprunt2.duree = value;
        this.emprunt2.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_TAUX:
        this.emprunt2.taux = value;
        this.emprunt2.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DATE_DEBUT:
        this.emprunt3.dateDebut = value;
        this.emprunt3.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE:
        this.emprunt3.montant = value;
        this.emprunt3.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DUREE:
        this.emprunt3.duree = value;
        this.emprunt3.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_TAUX:
        this.emprunt3.taux = value;
        this.emprunt3.libelle = "Autre emprunt";
        break;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DATE_DEBUT:
        this.emprunt1.dateDebut = value;
        this.emprunt1.libelle = "Emprunt 1";
        break;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE:
        this.emprunt1.montant = value;
        this.emprunt1.libelle = "Emprunt 1";
        break;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DUREE:
        this.emprunt1.duree = value;
        this.emprunt1.libelle = "Emprunt 1";
        break;
      case appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_TAUX:
        this.emprunt1.taux = value;
        this.emprunt1.libelle = "Emprunt 1";
        break;
      default:
        throw new Error("Invalid label key");
    }
    return res;
  }

   serialize(){
     if( this.checkDetailEmprunt){
      return [
        {
          id: this.emprunt1.id,
          duree: this.emprunt1.duree,
          intitule: this.emprunt1.libelle,
          taux: this.emprunt1.taux,
          montant: this.emprunt1.montant,
          date: this.emprunt1.dateDebut,
         },
         {
          id: this.emprunt2.id,
          duree: this.emprunt2.duree,
          intitule: this.emprunt2.libelle,
          taux: this.emprunt2.taux,
          montant: this.emprunt2.montant,
          date: this.emprunt2.dateDebut,
         },
         {
          id: this.emprunt3.id,
          duree: this.emprunt3.duree,
          intitule: this.emprunt3.libelle,
          taux: this.emprunt3.taux,
          montant: this.emprunt3.montant,
          date: this.emprunt3.dateDebut,
         }
       ];
     }else{
       return [];
     }
     
   }

   hydateModelEndetement(data:Array<any>){
      if (data?.length > 0) {
        // //console.log("=====endetement=====")
        //   //console.log(data);
        // //console.log("=====endetement=====")
        this.emprunt1.dateDebut = data[0]?.date;
        this.emprunt1.duree = data[0]?.duree;
        this.emprunt1.id = data[0]?.id;
        this.emprunt1.taux = data[0]?.taux;
        this.emprunt1.montant = data[0]?.montant;
        this.emprunt1.libelle = data[0]?.intitule
    
        this.emprunt2.dateDebut = data[1]?.date;
        this.emprunt2.duree = data[1]?.duree;
        this.emprunt2.id = data[1]?.id;
        this.emprunt2.taux = data[1]?.taux;
        this.emprunt2.montant = data[1]?.montant;
        this.emprunt2.libelle = data[1]?.intitule
      
        this.emprunt3.dateDebut = data[2]?.date;
        this.emprunt3.duree = data[2]?.duree;
        this.emprunt3.id = data[2]?.id;
        this.emprunt3.taux = data[2]?.taux;
        this.emprunt3.montant = data[2]?.montant;
        this.emprunt3.libelle = data[2]?.intitule
      }
   }
}

export { Endettement };
