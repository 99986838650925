import { appConstants } from "../_constants";

class ObjectifRechercher {
    reduireImpots: boolean;
    constituerPatrimoine: boolean;
    protegerFamille: boolean;
    completerRetraite: boolean;
    profiterInvertissement: boolean;
    completerRevenue: boolean;
    dureeInvestissement:{
      moins5ans:boolean,
      entre5et9ans: boolean,
      plusde9ans: boolean,
    };
    protection_en_place: string;
    

    constructor(
        reduireImports: boolean = false,
        constituerPatrimoine: boolean = false,
        protegerFamille: boolean = false,
        completerRetraite: boolean = false,
        profiterInvertissement: boolean = false,
        completerRevenue: boolean = false,
        dureeInvestissement:any = {
          moins5ans:false,
          entre5et9ans: false,
          plusde9ans: true,
        },
        protection_en_place: string =''
    ){
        this.reduireImpots = reduireImports;
        this.constituerPatrimoine = constituerPatrimoine;
        this.protegerFamille = protegerFamille;
        this.completerRetraite = completerRetraite;
        this.profiterInvertissement = profiterInvertissement;
        this.completerRevenue = completerRevenue;
        this.dureeInvestissement = dureeInvestissement;
        this.protection_en_place = protection_en_place
    }

    onChange(e: any) {
      const { id, checked,value } = e.target;
      let res = true;
      //console.log(id, checked);
        switch (id) {
            case appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS:
              this.reduireImpots = checked;
            break;
            case appConstants.OBJECTIF_KEY.COMPLETER_REVENUE:
              this.completerRevenue = checked;
            break;
            case appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE:
              this.constituerPatrimoine = checked;
            break;
            case appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT:
              this.profiterInvertissement = checked;
            break;
            case appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE:
              this.protegerFamille = checked;
            break;
            case appConstants.OBJECTIF_KEY.PROTECTION_EN_PLACE:
              this.protection_en_place = value;
            break;
            case appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE:
              this.completerRetraite = checked;
            break;
            case appConstants.OBJECTIF_KEY.MOINDE5ANS:
              this.dureeInvestissement.moins5ans = true;
              this.dureeInvestissement.entre5et9ans = false;
              this.dureeInvestissement.plusde9ans = false;
            break;
            case appConstants.OBJECTIF_KEY.DE5A9ANS:
              this.dureeInvestissement.moins5ans = false;
              this.dureeInvestissement.entre5et9ans = true;
              this.dureeInvestissement.plusde9ans = false;
            break;
            case appConstants.OBJECTIF_KEY.PLUSDE9ANS:
              this.dureeInvestissement.moins5ans = false;
              this.dureeInvestissement.entre5et9ans = false;
              this.dureeInvestissement.plusde9ans = true;
            break;

            default:
            res = false;
            break;
        }
        return res;
    }


    getValue(key: string) {
        switch (key) {
          case appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS:
            return this.reduireImpots;
          case appConstants.OBJECTIF_KEY.COMPLETER_REVENUE:
            return this.completerRevenue;  
          case appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE:
            return this.constituerPatrimoine;  
          case appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT:
            return this.profiterInvertissement;  
          case appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE:
            return this.protegerFamille;
          case appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE:
            return this.completerRetraite;  
          case appConstants.OBJECTIF_KEY.MOINDE5ANS:
            return this.dureeInvestissement.moins5ans;
          case appConstants.OBJECTIF_KEY.DE5A9ANS:
            return this.dureeInvestissement.entre5et9ans;
          case appConstants.OBJECTIF_KEY.PLUSDE9ANS:
            return this.dureeInvestissement.plusde9ans;
          case appConstants.OBJECTIF_KEY.PROTECTION_EN_PLACE:
            return this.protection_en_place;
          default:
            break;
        }
      return false;
    }
}
export {ObjectifRechercher}