class GestionBibliotheFiscale {
    key: number | null;
    nom: String | null;
    type_Emprunt: any | null;
    amortissable: boolean | null;
    inFine : boolean | null;
    duree: any | null;
    tauxEmprunt: any | null;
    assurance: any | null;
    differeCapital: any | null;
    differeCapitalMois: any | null;
    differeInteret: any | null;
    differeInteretMois: any | null;
    txCapitalisation: any | null;

    constructor(data?: any) {
        this.key = data?.id;
        this.nom = data?.nom;
        this.type_Emprunt = null;
        this.amortissable = data?.amortissable;
        this.inFine = data?.inFine;
        this.duree = data?.duree;
        this.tauxEmprunt = data?.txInteret
        this.assurance = data?.assurance;
        this.differeCapital = data?.differeCapital || null;
        this.differeCapitalMois = data?.differeCapitalMois || null;
        this.differeInteret = data?.differeInteret || null;
        this.differeInteretMois = data?.differeInteretMois || null;
        this.txCapitalisation = data?.txCapitalisation;
    }
}

export { GestionBibliotheFiscale }