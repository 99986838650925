class ProduitFinancier{
    amortissable: boolean;
    assurance: number| null;
    differeCapital: boolean;
    differeCapitalMois: number | null;
    differeInteret: boolean;
    differeInteretMois:  number | null; 
    duree:  number | null; 
    dureeAdossement:  number | null; 
    id:  number | null; 
    inFine:  boolean; 
    nomAdossement:  string;
    txCapitalisation:  number | null; 
    txFraisEntree:  number | null; 
    txFraisVersement:  number | null; 
    txInteret :  number | null; 

    constructor(data?:any){
        this.amortissable = data?.amortissable;
        this.assurance = data?.assurance;
        this.differeCapital = data?.differeCapital;
        this.differeCapitalMois = data?.differeCapitalMois;
        this.differeInteret = data?.differeInteret;
        this.differeInteretMois = data?.differeInteretMois;
        this.duree = data?.duree;
        this.dureeAdossement = data?.dureeAdossement;
        this.id = data?.id;
        this.inFine = data?.inFine;
        this.nomAdossement = data?.nomAdossement;
        this.txCapitalisation = data?.txCapitalisation;
        this.txFraisEntree = data?.txFraisEntree;
        this.txFraisVersement = data?.txFraisVersement;
        this.txInteret = data?.txInteret;
    }
}
export {ProduitFinancier}