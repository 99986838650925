import React from 'react';
import { Route, Redirect } from  "react-router-dom";
import { connector, PropsFromRedux } from "../_reducers";


const PrivateRoute: React.FC<{
        component: any;
        path: string;
        exact?: boolean;
    }& PropsFromRedux> = (props: any) => {
        const condition = getAuthUser();

        return condition ? (<Route exact={props.exact}  path={props.path}   component={props.component} />) : 
        (<Redirect  to="/"  />);
}

function getAuthUser(){
    let user:any = localStorage.getItem("userData");
    if (JSON.parse(user)){
        return true;
    }else{
        return false;
    }
}
const connected = connector(PrivateRoute)
export {connected as PrivateRoute};


