import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import ImpotFortuneImmobilierRow from "./ImpotFortuneImmobilierRow";

class ImpotFortuneImmobilier extends Component<PropsFromRedux, any> {
  render() {
    return (
      <div className="d-flex flex-column declarant-wrapper3 px-2 pb-2" style={{ minHeight: "100px"}}>
        <h1 className="title-section" style={{ textAlign: "left" }}>
          <span className="px-3 big-text font-weight-bold">Impot sur la fortune immobilière</span>
        </h1>
        <ImpotFortuneImmobilierRow />
      </div>
    );
  }
}

export default connector(ImpotFortuneImmobilier);
