import { appConstants } from "../_constants";

class ScellierInterm {
    engargement_min: boolean;
    engargement_max: boolean;
    metropole:boolean;
    outre_mere:boolean;
    taux:number;
    taux_reduction:number;
    lot_bbc: boolean;
    plafond_investissement: number;
    passage: number | null;
    regime:boolean;
    lmnp:boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    id: number | null;
    loi : String;

    constructor(){
        this.engargement_min = true;
        this.engargement_max = false;
        this.metropole = true;
        this.outre_mere = false;
        this.taux = 13;
        this.taux_reduction = 1;
        this.lot_bbc = true;
        this.plafond_investissement = 300000;
        this.passage = 9;
        this.regime = true;
        this.lmnp = false
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.id = null ;
        this.loi = "SCELLIER_INTERM"
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.SCELLIERINTERM.ENGARGEMENT_MIN:
                this.engargement_min = true;
                this.engargement_max = false;
            break
            case appConstants.SCELLIERINTERM.ENGARGEMENT_MAX:
                this.engargement_min = false;
                this.engargement_max = true;
            break
            case appConstants.SCELLIERINTERM.METROPOLE:
                this.metropole = true;
                this.outre_mere = false;
                this.taux_reduction = 1;
                if (this.lot_bbc) {
                    this.taux =13
                }else{
                    this.taux = 6
                }
            break
            case appConstants.SCELLIERINTERM.OUTRE_MERE:
                this.outre_mere = true;
                this.metropole = false;
                this.taux = 24;
                this.lot_bbc = false;
                this.taux_reduction = 1.333
            break
            case appConstants.SCELLIERINTERM.TAUX:
                this.taux = value;
            break
            case appConstants.SCELLIERINTERM.LOT_BBC:
                this.lot_bbc = !this.lot_bbc ;
                if (this.lot_bbc) {
                    this.taux = 13
                }else{
                    this.taux = 6
                }
            break
            case appConstants.SCELLIERINTERM.PASSAGE:
                this.passage = value;
            break
            case appConstants.SCELLIERINTERM.PLAFOND_INVESTISSEMENT:
                this.plafond_investissement = value;
            break
            case appConstants.SCELLIERINTERM.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
                this.passage = 9;
            break
            case appConstants.SCELLIERINTERM.LMNP:
                this.regime = false;
                this.lmnp = true;
                this.passage = 15;
            break
            case appConstants.SCELLIERINTERM.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.SCELLIERINTERM.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.SCELLIERINTERM.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.SCELLIERINTERM.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.SCELLIERINTERM.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.SCELLIERINTERM.PENDANT:
                this.pendant = value;
            break
            case appConstants.SCELLIERINTERM.TAUX_REDUCTION_SUPP:
                this.taux_reduction = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.SCELLIERINTERM.ENGARGEMENT_MIN:
            return this.engargement_min; 
            case appConstants.SCELLIERINTERM.ENGARGEMENT_MAX:
            return this.engargement_max; 
            case appConstants.SCELLIERINTERM.METROPOLE:
            return this.metropole; 
            case appConstants.SCELLIERINTERM.OUTRE_MERE:
            return this.outre_mere; 
            case appConstants.SCELLIERINTERM.TAUX:
            return this.taux; 
            case appConstants.SCELLIERINTERM.PASSAGE:
            return this.passage;
            case appConstants.SCELLIERINTERM.PLAFOND_INVESTISSEMENT:
            return this.plafond_investissement;
            case appConstants.SCELLIERINTERM.LMNP:
            return this.lmnp;
            case appConstants.SCELLIERINTERM.REGIME:
            return this.regime; 
            case appConstants.SCELLIERINTERM.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.SCELLIERINTERM.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.SCELLIERINTERM.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.SCELLIERINTERM.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.SCELLIERINTERM.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.SCELLIERINTERM.PENDANT:
            return this.pendant;
            case appConstants.SCELLIERINTERM.LOT_BBC:
            return this.lot_bbc;
            case appConstants.SCELLIERINTERM.TAUX_REDUCTION_SUPP:
            return this.taux_reduction; 
            default:
            break;
        }
      return false;
    }
}
export {ScellierInterm}