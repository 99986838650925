import React, { Component } from "react";
import Foyer from "./Foyer";
import FoyerFiscaleAutre from "./FoyerFiscaleAutre";
import LieuResidence from "./LieuResidence";
import { PropsFromRedux, connector } from "../../_reducers";
import { ResultatFiscal } from "../ResultatFiscal/ResultatFiscal";

class FoyerFiscale extends Component<PropsFromRedux, any> {
  componentDidMount(){
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 500);
  }
  render() {
    return (
      <div className="row bg-white mx-0 light-shadow content-row py-3">
        <div className="col-lg-6 ">
          <div className="row">
            <Foyer />
          </div> 
        </div>

        <div className="col-lg-6">
          <div className="row">
            <FoyerFiscaleAutre />
          </div>
        </div>   
        <LieuResidence /> 
        <ResultatFiscal />
           
      </div>
    );
  }
}

const connected = connector(FoyerFiscale);
export { connected as FoyerFiscale };
