class TypeFiscalite{
    key: number;
    nom: String;
    constant: String;
    ischeck: boolean;
    ancienLoi: boolean;

    constructor( key:number, nom:String, constant: String, ischeck:boolean, ancienLoi:boolean ){
        this.key = key;
        this.nom = nom;
        this.constant = constant;
        this.ischeck = ischeck;
        this.ancienLoi = ancienLoi;
    }

    oncheck(ischeck: boolean){
        this.ischeck = ischeck;
    }
}
export {TypeFiscalite}