import { appConstants } from "../_constants";

class Robien {
    passage: number | null;
    regime:boolean;
    lmnp:boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null; 
    id : number | null;

    constructor(){
        this.passage = 9;
        this.regime = true;
        this.lmnp = false
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.id = null;
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.ROBIEN.PASSAGE:
                this.passage = value;
            break
            case appConstants.ROBIEN.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.ROBIEN.LMNP:
                this.regime = false;
                this.lmnp = true;
            break
            case appConstants.ROBIEN.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.ROBIEN.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.ROBIEN.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.ROBIEN.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.ROBIEN.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.ROBIEN.PENDANT:
                this.pendant = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.ROBIEN.PASSAGE:
            return this.passage;
            case appConstants.ROBIEN.LMNP:
            return this.lmnp;
            case appConstants.ROBIEN.REGIME:
            return this.regime; 
            case appConstants.ROBIEN.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.ROBIEN.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.ROBIEN.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.ROBIEN.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.ROBIEN.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.ROBIEN.PENDANT:
            return this.pendant; 
            default:
            break;
        }
      return false;
    }
}
export {Robien}