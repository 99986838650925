// Pollyfills for IE and edge
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './_assets/scss/app.scss';
import './_assets/scss/style.scss';
import './_assets/scss/app-ie.scss';
import {App} from './app/App';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import { ClearCacheProvider } from 'react-clear-cache';


ReactDOM.render(
  <ClearCacheProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </ClearCacheProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
