import React, { Component } from "react";
import ImpotFortuneImmobilier from "./ImpotFortuneImmobilier";
import { PropsFromRedux, connector } from "../../_reducers";
import PlafonnementAvantagesFiscaux from "./PlafonnementAvantagesFiscaux";
import Emprunt from "../Endettement/Emprunt";
import Depense from "../Endettement/Depense";
import { ResultatFiscal } from "../ResultatFiscal/ResultatFiscal";

class DetailsIFI extends Component<PropsFromRedux, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: false,
    };

  }
  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500);
  }
  render() {
    return (
      <div className="bg-white mx-0 p-3 pt-4 h-100 light-shadow content-row">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 justify-content-around o-h flex-1 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                <PlafonnementAvantagesFiscaux />
              </div>
              <div className="col-lg-4 justify-content-around o-h h-95 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                <ImpotFortuneImmobilier />
              </div>
              <div className="col-lg-4 justify-content-around o-h h-95 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                <Depense />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-2 pt-3 justify-content-around text-left d-flex flex-column"></div>
          <div className="col-lg-10 justify-content-around o-h h-95 pt-3">
            <ImpotFortuneImmobilier />
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-lg-2 pt-3 justify-content-around text-left d-flex flex-column">
            <div className="py-3">
              <p className="big-text m-0">Montant</p>
            </div>
          </div>
          <div className="col-lg-10 justify-content-around o-h h-95 pt-3">
            <Depense />
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-8 justify-content-around text-center d-flex flex-column"></div>
          <div className="col-lg-4  justify-content-around o-h h-95 pt-0">
            <div className="custom-control mt-2 ml-4 pl-4 d-flex justify-content-start custom-checkbox">
              <input
                type="checkbox"
                checked={this.getValue("CHECK_DETAIL_EMPREUNT")}
                onChange={(e) => this.onChange(e)}
                className="custom-control-input"
                id="CHECK_DETAIL_EMPREUNT"
              />
              <label
                className="custom-control-label text-small font-weight-light small-radio"
                htmlFor="CHECK_DETAIL_EMPREUNT"
              >
                Saisir les détails des emprunts
              </label>
            </div>
          </div>
        </div>


        {this.getValue("CHECK_DETAIL_EMPREUNT") && (
          <div className="row">
            <div className="col-lg-12  justify-content-around o-h h-95">
              <Emprunt />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12  justify-content-around o-h h-95 px-0">
            <ResultatFiscal />
          </div>
        </div>
      </div>
    );
  }
  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.endettement.getValue(key);
  }

  onChange(e: any) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.endettement.onChange(e);
    if (res) {
      model.eventUpdateDossier = true;
      replaceModel(model);
    }
  }
}

const connected = connector(DetailsIFI);
export { connected as DetailsIFI };
