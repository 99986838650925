import { Declarant } from "../_entities";

class Revenu {
  declarant1: Declarant;
  declarant2: Declarant;
  newCalcule: boolean;
  id: number | null;

  constructor(
    declarant1: Declarant = new Declarant(1, 'Déclarant 1'),
    declarant2: Declarant = new Declarant(2, 'Déclarant 2')
  ) {
    this.declarant1 = declarant1;
    this.declarant2 = declarant2;
    this.newCalcule = true;
    this.id = null;
  }

  getDeclarant(id: string = '') {
    if (id === 'declarant1') {
      return this.declarant1;
    } else if (id === 'declarant2') {
      return this.declarant2;
    } else {
      throw new Error("Unknown declarant key");
      
    }
  }

  serializeRevenu1(){
    return{
      id: this.declarant1.idDeclarent,
      abattement10: this.declarant1.hasAbattement,
      adhesionCentreAgree: this.declarant1.hasAdhesion,
      benefice: (this.declarant1.benefice.montant === 0)? null : this.declarant1.benefice.montant,
      beneficeFutureList:this.declarant1.beneficeArraySerialize(),
      fraisReel: this.declarant1.hasFraisReel,
      montantFraisReel: (this.declarant1.hasAbattement === true)? 0 : (this.declarant1.fraisReel.montant === 0)? null : this.declarant1.fraisReel.montant,
      salaire: (this.declarant1.revenuNetImposable.montant === 0)? null : this.declarant1.revenuNetImposable.montant,
      salaireFutureList: this.declarant1.revenuNetImposablesArraySerialize(),
      saisirBeneficeFuture: this.declarant1.benefices.length > 0 ? true: false,
      saisirSalaireFuture: this.declarant1.revenuNetImposables.length > 0 ? true: false
    }
  }

  JsonFromMapRevenu1(data:any){
    //console.log('-- revenu 1 ---');
    //console.log(data);
    //console.log('-- revenu 1 ---');
    if(data !== null){
      this.declarant1.idDeclarent = data?.id || null;
      this.declarant1.revenuNetImposable.montant = data?.salaire;
      this.declarant1.benefice.montant = data?.benefice;
      this.declarant1.hasAbattement = data?.abattement10;
      this.declarant1.hasFraisReel = data?.fraisReel;
      this.declarant1.hasAdhesion = data?.adhesionCentreAgree;
      this.declarant1.hasAnneFuturRevenuNetImposable = data?.saisirSalaireFuture;
      this.declarant1.hasAnneFuturBenefice = data?.saisirBeneficeFuture;
      this.declarant1.fraisReel.montant = data?.montantFraisReel;
      this.declarant1.revenuNetImposables = this.declarant1.hydrateModelRevenueNetImposable(data?.salaireFutureList);
      this.declarant1.benefices = this.declarant1.hydrateModelBenefice(data?.beneficeFutureList);
    }
  }

  serializeRevenu2(){
    return{
      id: this.declarant2.idDeclarent,
      abattement10: this.declarant2.hasAbattement,
      adhesionCentreAgree: this.declarant2.hasAdhesion,
      benefice: (this.declarant2.benefice.montant === 0)? null : this.declarant2.benefice.montant,
      beneficeFutureList:this.declarant2.beneficeArraySerialize(),
      fraisReel: this.declarant2.hasFraisReel,
      montantFraisReel: (this.declarant2.hasAbattement === true)? 0 : (this.declarant2.fraisReel.montant === 0)? null : this.declarant2.fraisReel.montant,
      salaire: (this.declarant2.revenuNetImposable.montant === 0)? null : this.declarant2.revenuNetImposable.montant,
      salaireFutureList: this.declarant2.revenuNetImposablesArraySerialize(),
      saisirBeneficeFuture: this.declarant2.benefices.length > 0 ? true: false,
      saisirSalaireFuture: this.declarant2.revenuNetImposables.length > 0 ? true: false
    }
  }

  JsonFromMapRevenu2(data:any){
    if(data !== {}){
      this.declarant2.idDeclarent = data?.id || null;
      this.declarant2.revenuNetImposable.montant = data?.salaire;
      this.declarant2.benefice.montant = data?.benefice;
      this.declarant2.hasAbattement = data?.abattement10;
      this.declarant2.hasFraisReel = data?.fraisReel;
      this.declarant2.hasAdhesion = data?.adhesionCentreAgree;
      this.declarant2.hasAnneFuturRevenuNetImposable = data?.saisirSalaireFuture;
      this.declarant2.hasAnneFuturBenefice = data?.saisirBeneficeFuture;
      this.declarant2.fraisReel.montant = data?.montantFraisReel;
      this.declarant2.revenuNetImposables = this.declarant2.hydrateModelRevenueNetImposable(data?.salaireFutureList);
      this.declarant2.benefices = this.declarant2.hydrateModelBenefice(data?.beneficeFutureList);
    }
  }

  
}

export { Revenu }