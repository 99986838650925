import { FoyerFiscalAutre } from "../_entities";
import { LieuResidence } from "../_entities";

class Autre {
  foyerFiscalAutre: FoyerFiscalAutre;
  lieuResidence: LieuResidence;

  static lieuResidences: Array<LieuResidence> = [
    new LieuResidence(1, "Métropole"),
    new LieuResidence(2, "Guadeloupe, Martinique, Réunion"),
    new LieuResidence(3, "Guyanne et Polynésie")
  ];

  constructor(
    foyerFiscalAutre: FoyerFiscalAutre = new FoyerFiscalAutre(),
    lieuResidence: LieuResidence = new LieuResidence()
    
  ) {
    this.foyerFiscalAutre = foyerFiscalAutre;
    this.lieuResidence = lieuResidence;
  }

  seLieuResidence(lieu: LieuResidence): boolean {
    this.lieuResidence = lieu;
    return true;
  }
}

export { Autre }