class ChargeSynthese {
    frais_gestion: any = "";
    frais_entree: any = "";
    frais_sortie: any = "";
    garantie_locative: any = "";
    charge_copropriete: any = "";
    franchise_sans_loyer_fin_scpi: any = "";
    montant_taxe_fiscal: any = "";
    exoneree_a: any = "";
    exoneree_pendant: any = "";
    
    id: any = 0;
    labelCharge: any = "";
    labelAssuranceLocative = "";
    labelChargeGestion: any = "";
    labelChargePropriete: any = "";
    labelExonerationTaxeFonciere: any = "";
    labelRevaluationLoyer: any = "";
    labelTaxeFonciere: any = "";
    taxeFonciere: any = "";
    revaluationLoyer: any = "";
    exonerationTaxeFonciere: any = "";
    chargeGestion: any = "";
    chargeCoPropriete: any = "";
    assuranceLocative: any = "";


    formJson(data : any) {
        if(!data){
            return;
        }else{
           this.id = data?.id;
           this.labelCharge = data?.labelCharge;
           this.labelAssuranceLocative = data?.labelAssuranceLocative;
           this.labelChargeGestion = data?.labelChargeGestion;
           this.labelChargePropriete = data?.labelChargePropriete;
           this.labelExonerationTaxeFonciere = data?.labelExonerationTaxeFonciere;
           this.labelRevaluationLoyer = data?.labelRevaluationLoyer;
           this.labelTaxeFonciere = data?.labelTaxeFonciere;
           this.taxeFonciere = data?.taxeFonciere;
           this.revaluationLoyer = data?.revaluationLoyer;
           this.exonerationTaxeFonciere = data?.exonerationTaxeFonciere;
           this.chargeGestion = data?.chargeGestion;
           this.chargeCoPropriete = data?.chargeCoPropriete;
           this.assuranceLocative = data?.assuranceLocative;
        }
    }
        
}
export {ChargeSynthese}