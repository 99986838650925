export class SituationFoyer{
    evolutionSituationActuelle: any = "";
    hibernateLazyInitializer : any = {};
    id : any = 0;
    impotReference: any = "";
    nbPartFiscal : any = "";
    resultatFoncier : any = "";
    revenuFiscaleReference : any = "";
    tmi : any = "";

    formJson(data : any){
        if(!data){
            return;
        }else{
            this.hibernateLazyInitializer = data?.hibernateLazyInitializer;
            this.id = data?.id;
            this.impotReference = data?.impotReference;
            this.nbPartFiscal = data?.nbPartFiscal;
            this.resultatFoncier = data?.resultatFoncier;
            this.revenuFiscaleReference = data?.revenuFiscaleReference != null ? data?.revenuFiscaleReference.toFixed(2) : 0;
            this.evolutionSituationActuelle = data?.evolutionSituationActuelle;
            this.tmi = data?.tmi;
        }
    }
}