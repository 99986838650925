class Modal {
  title: string;
  size: string;
  mode: string;
  backdrop: string;
  data: any;
  provider: any;
  showCancel = true;
  showOk = false;

  constructor(
    title: string = '',
    size: string = 'lg',
    backdrop: string = 'static',
    mode: string = '',
    data: any = null
  ) {
    this.title = title;
    this.data = data;
    this.size = size;
    this.backdrop = backdrop;
    this.mode = mode;
  }
}

export { Modal }