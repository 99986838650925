import { appConstants } from "../_constants";

class Marlaux {
    zppaup: boolean;
    secteur_sauvage: boolean;
    date_repatition:number;
    passage:number | null;
    subvention:number | null;
    regime:boolean;
    lmnp:boolean;
    taux: boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    taux_reduction: number | null;
    id: number | null;
    loi: String;

    constructor(){
        this.date_repatition = 1;
        this.zppaup =true;
        this.secteur_sauvage= false;
        this.taux = false;
        this.passage = 9;
        this.subvention = null ;
        this.regime = true;
        this.lmnp = false;
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.taux_reduction = 22;
        this.id = null;
        this.loi = "MALRAUX"
    }
    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }
    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.MARLAUX.DATE_REPARTITION:
                this.date_repatition = value;
            break
            case appConstants.MARLAUX.ZPPAUP:
                this.zppaup = true;
                this.secteur_sauvage = false;
                this.taux = false;
                this.taux_reduction = 22;
            break
            case appConstants.MARLAUX.SECTEUR_SAUVEGARDE:
                this.zppaup = false;
                this.secteur_sauvage = true;
                this.taux = false;
                this.taux_reduction = 30;
            break
            case appConstants.MARLAUX.PASSAGE:
                this.passage = value;
            break
            case appConstants.MARLAUX.SUBVENTION:
                this.subvention = value;
            break
            case appConstants.MARLAUX.TAUX:
                this.taux = !this.taux;
                if (this.taux && this.zppaup) {
                    this.taux_reduction = 27;
                }else if (this.taux && this.secteur_sauvage) {
                    this.taux_reduction = 36;
                }if(!this.taux) {
                    this.taux_reduction = 22;
                }
            break
            case appConstants.MARLAUX.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.MARLAUX.LMNP:
                this.lmnp = true;
                this.regime = false;
            break
            case appConstants.PINELS.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.PINELS.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.PINELS.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.PINELS.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.PINELS.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.PINELS.PENDANT:
                this.pendant = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.MARLAUX.DATE_REPARTITION:
            return this.date_repatition; 
            case appConstants.MARLAUX.LMNP:
            return this.lmnp; 
            case appConstants.MARLAUX.PASSAGE:
            return this.passage; 
            case appConstants.MARLAUX.REGIME:
            return this.regime; 
            case appConstants.MARLAUX.SECTEUR_SAUVEGARDE:
            return this.secteur_sauvage; 
            case appConstants.MARLAUX.SUBVENTION:
            return this.subvention;
            case appConstants.MARLAUX.TAUX:
            return this.taux;
            case appConstants.MARLAUX.ZPPAUP:
            return this.zppaup;
            case appConstants.MARLAUX.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.MARLAUX.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.MARLAUX.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.MARLAUX.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.MARLAUX.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.MARLAUX.PENDANT:
            return this.pendant; 
            case appConstants.MARLAUX.TAUX_REDUCTION:
            return this.taux_reduction; 
        }
      return false;
    }
}
export {Marlaux}