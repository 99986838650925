import React, { Component } from "react";
import Declarant from "./Declarant";
import { connect } from "react-redux";
import { appActions } from "../../_actions";
import { QuotientFamilial } from "../QuotientFamilial/QuotientFamilial";
import { ResultatFiscal } from "../ResultatFiscal/ResultatFiscal";

class DeclarantGroup extends Component<any, {}> {
  componentDidMount(){
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 500);
  }
  render() {
    const { model } = this.props;
    return (
      <div>
        <div className="row bg-white mx-0 light-shadow content-row py-3">
          <Declarant declarant={model?.situationFiscale?.revenu?.declarant1} other={model?.situationFiscale?.revenu?.declarant2} />
          <Declarant declarant={model?.situationFiscale?.revenu?.declarant2} other={model?.situationFiscale?.revenu?.declarant1} />
        <QuotientFamilial />
        <ResultatFiscal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

const connected = connect(mapStateToProps, dispatchToProps)(DeclarantGroup);

export { connected as DeclarantGroup };
