import React, { Component } from "react";
import { connect } from "react-redux";
import { appActions } from "../../_actions";
import { utils } from "../../_helpers";
import swal from "sweetalert";
import { appConstants } from '../../_constants';

class QuotientFamilial extends Component<any, {}> {
  render() {
    const { model } = this.props;
    const { quotientFamilial } = model.situationFiscale;
    return (
      <div className="justify-content-around o-h w-100 pt-3 col-lg-12">
        <div className="d-flex flex-column declarant-wrapper1 justify-content-start px-2 pb-2 " style={{ minHeight: "auto !important", height: "auto ! important" }}>
          <div className="mb-0">
            <h1 style={{ textAlign: "left" }}>
              <span className="px-3 big-text font-weight-bold">Quotient familial</span>
            </h1>
            <div className="d-flex row">
              <div className="d-flex justify-content-left flex-row align-items-start pt-0 col-md-12">
                <div className="col-md-2 custom-control custom-radio ml-3">
                  <input
                    type="radio"
                    name="quotienFamilliale"
                    id={appConstants.MARIEOUPACSE}
                    checked={this.getValue(appConstants.MARIEOUPACSE)}
                    className="custom-control-input"
                    onClick={(e) => this.setValue(e)}
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={appConstants.MARIEOUPACSE}
                  >
                    Marié ou pacsé
                  </label>
                </div>
                <div className="col-md-2 custom-control custom-radio mr-0">
                  <input
                    type="radio"
                    name="quotienFamilliale"
                    id={appConstants.PARENTISOLE}
                    checked={this.getValue(appConstants.PARENTISOLE)}
                    className="custom-control-input"
                    onClick={(e) => this.setValue(e)}
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={appConstants.PARENTISOLE}
                  >
                    Parent Isolé
                  </label>
                </div>
                <div className="col-md-2 custom-control custom-radio mr-4">
                  <input
                    type="radio"
                    name="quotienFamilliale"
                    id={appConstants.CELIBATAIRE}
                    checked={this.getValue(appConstants.CELIBATAIRE)}
                    className="custom-control-input"
                    onClick={(e) => this.setValue(e)}
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={appConstants.CELIBATAIRE}
                  >
                    Célibataire
                  </label>
                </div>
              </div>
              <div className="d-flex pl-2 flex-row align-items-center justify-content-center mt-0 pt-0 col-md-2">
              </div>

            </div>

            <div className="d-flex row mt-2 pt-0 mb-2 px-2 pl-3">
            <div className="row pl-0 mt-0 pt-0 px-1 col col-lg-6">
            <div className="col col-lg-5">
                <label
                  className="text-dark-blue font-weight-light my-0 px-2"
                //style={{ minWidth: "180px" }}
                >
                  Nombre de parts fiscales
               </label>
               </div>

                <div className="d-flex border-light-gray col col-lg-3 align-items-center" style={{ border: quotientFamilial.validate ? "1px solid red" : "" }}>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    max="100"
                    className="form-control2 flex-1 mr-1"
                    inputMode="decimal"
                    onChange={(e) => this.setPartFiscale(e)}
                    value={quotientFamilial?.nombrePartFiscale?.valeur}
                    onBlur={(e) => this.setCalcutaxe()}
                  />
                </div>

                {quotientFamilial.validate && <small className="text-danger">*</small>}

                <div className="custom-control custom-checkbox col col-lg-4 pl-5">
                  <input
                    type="checkbox"
                    disabled={quotientFamilial?.nombrePartFiscale?.valeur === 0 || quotientFamilial?.nombrePartFiscale?.valeur === null}
                    checked={quotientFamilial?.hasAnneNombrePartFiscales}
                    onChange={(e) => this.handleCheckAnneeFutur(e)}
                    className="custom-control-input"
                    id={"checkAnneFutur_partFiscale"}
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light small-radio"
                    htmlFor={"checkAnneFutur_partFiscale"}
                  >
                    Saisir années futures
               </label>
                </div>
              </div>
            </div>







          </div>
        </div>
        {/* <div className="d-flex flex-column justify-content-center mt-0 pt-0">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="customRadio5"
            name="customRadio5"
            className="custom-control-input"
          />
          <label
            className="custom-control-label text-dark-blue font-weight-light"
            htmlFor="customRadio5"
          >
            Valeurs modifiées
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="customRadio6"
            name="customRadio6"
            className="custom-control-input"
          />
          <label
            className="custom-control-label text-dark-blue font-weight-light"
            htmlFor="customRadio6"
          >
            Valeurs calculées
          </label>
        </div>
      </div> */}
      </div>
    );
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      //console.log("replace");
    }
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.quotientFamilial.getCheck(key);
  }

  setValue(e: any): any {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.quotientFamilial.onChangeCheck(e);
    model.situationFiscale.quotientFamilial.newCalcule = true;
    this.setCalcutaxe();
    if (res) {
      replaceModel(model);
    }
  }

  setPartFiscale(e: any): void {
    const { model } = this.props;
    const { quotientFamilial } = model.situationFiscale;
    quotientFamilial.newCalcule = true;
    // e.target.value = e.target.value.replace(".",",");
    quotientFamilial.setNombrePartFiscaleValeur(utils().getNumberInputValue(e));
    this.props.replaceModel(model);
  }

  handleCheckAnneeFutur(e: React.ChangeEvent<HTMLInputElement>): void {
    const { model } = this.props;
    const { quotientFamilial } = model.situationFiscale;
    const { checked } = e.target;
    if (checked) {
      if (quotientFamilial?.nombrePartFiscale?.montant === 0) {
        swal(
          "Erreur!",
          "Le nombre de part fiscale doit être superieur à 0",
          "info"
        );
        return;
      }
      quotientFamilial.hasAnneNombrePartFiscales = true;
      quotientFamilial.newCalcule = true;
      this.props.initAnneFuturPartFiscale(model, quotientFamilial);
    } else {
      if (quotientFamilial.hasAnneNombrePartFiscales) {
        this.props.initAnneFuturPartFiscale(model, quotientFamilial);
      }
    }
  }
}

const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

const connected = connect(mapStateToProps, dispatchToProps)(QuotientFamilial);

export { connected as QuotientFamilial };
