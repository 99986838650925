import React, { Component } from "react";
import { CustomInput } from "../CustomInput/CustomInput";
import { connector, PropsFromRedux } from "../../../_reducers";
import { Loader } from "../Loader/Loader"

class AnneesFuturs extends Component<PropsFromRedux, any> {
  render() {
    const { modal } = this.props.model;
    return (
      <div className="w-100 px-2 row m-0">
        <Loader />
        <div className="col-lg-6">
          <div className="col-lg-12">
            <div className="d-flex px-0">
              <div className="col-lg-7">
                <CustomInput config={modal?.provider?.getLabelConfig(modal.mode)} />
              </div>
              <div className="col-lg-5 p-0 d-flex justify-content-center">
                <small>Revalorisation annuelle</small>
              </div>
            </div>
          </div>
          {this.getAnneeFuturGroup(0, 10)}
        </div>
        <div className="col-lg-6">
          <div className="col-lg-12">
            <div className="d-flex px-0">
              <div className="col-lg-7"></div>
              <div className="col-lg-5 p-0 d-flex justify-content-center d-xm-none d-sm-none d-md-none">
                <small>Revalorisation annuelle</small>
              </div>
            </div>
          </div>
          {this.getAnneeFuturGroup(10, 20)}
        </div>
      </div>
    );
  }

  getAnneeFuturGroup(offset: number, limit: number): React.ReactNode {
    const { modal } = this.props.model;
    const items = modal?.provider?.getItems(modal.mode);
    return items.slice(offset, limit).map((item: any, i: number) => {
      const options1 = {
        type: "year",
        id: item.id,
      };
      const options2 = {
        type: "revalorisation",
        id: item.id,
      };
      return (
        <div className="col-lg-12" key={i}>
          <div className="d-flex px-0">
            <div className="col-lg-7 mt-2">
              <CustomInput
                config={modal.provider.getInputConfig(modal.mode, item, options1)}
              />
            </div>
            <div className="col-lg-5 mt-2">
              <CustomInput
                config={modal.provider.getInputConfig(modal.mode, item, options2)}
              />
            </div>
          </div>
        </div>
      );
    });
  }
}

const connected = connector(AnneesFuturs);
export { connected as AnneesFuturs };
