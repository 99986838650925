import { appConstants } from "../_constants";

class Monument {
    espace_vert_oui: boolean;
    espace_vert_non: boolean;
    date_repatition:number;
    passage:number | null;
    subvention:number | null;
    regime:boolean;
    lmnp: boolean;
    taux: boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    taux_reevaluation: number;
    taux_deduction: number;
    abatement_forfaitaire: number;
    pendant: number | null;
    taux_reduction: number;
    public: boolean;
    location: boolean;
    tva_oui:boolean;
    tva_non:boolean;
    charge_publicite: number | null;
    id: number| null;
    loi : String ;

   
    constructor(){
        this.date_repatition = 1;
        this.taux = false;
        this.passage = 9;
        this.subvention = null ;
        this.regime = true;
        this.lmnp = false;
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.taux_reduction = 22;
        this.espace_vert_oui =false;
        this.espace_vert_non= true;
        this.taux_reevaluation = 0;
        this.abatement_forfaitaire = 1524.4;
        this.taux_deduction = 50;
        this.public = false;
        this.location =  true;
        this.tva_oui = true;
        this.tva_non = false;
        this.charge_publicite = null;
        this.id = null;
        this.loi = "MONUMENT_HISTORIQUE"
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.MARLAUX.DATE_REPARTITION:
                this.date_repatition = value;
            break
            case appConstants.MARLAUX.PASSAGE:
                this.passage = value;
            break
            case appConstants.MARLAUX.SUBVENTION:
                this.subvention = value;
            break
            case appConstants.MARLAUX.TAUX:
                this.taux = !this.taux;
            break
            case appConstants.MARLAUX.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.MARLAUX.LMNP:
                this.lmnp = true;
                this.regime = false;
            break
            case appConstants.MARLAUX.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.MARLAUX.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.MARLAUX.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.MARLAUX.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.MARLAUX.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.MARLAUX.PENDANT:
                this.pendant = value;
            break
            case appConstants.MARLAUX.TAUX_REEVALUATION:
                this.taux_reevaluation = value;
            break
            case appConstants.MARLAUX.TAUX_DEDUCTION:
                this.taux_deduction = value;
            break
            case appConstants.MARLAUX.ABATTEMENT_FORFAITAIRE:
                this.abatement_forfaitaire = value;
            break
            case appConstants.MARLAUX.PUBLIC:
                this.public = true;
                this.location = false;
                this.taux_deduction = 100;
            break
            case appConstants.MARLAUX.LOCATION:
                this.public = false;
                this.location = true;
                this.taux_deduction = 50;
            break
            case appConstants.MARLAUX.ESPACE_VERT_NON:
                this.espace_vert_non = true;
                this.espace_vert_oui = false;
                this.abatement_forfaitaire = 1524.40;
            break
            case appConstants.MARLAUX.ESPACE_VERT_OUI:
                this.espace_vert_non = false;
                this.espace_vert_oui = true;
                this.abatement_forfaitaire = 2286.74;
            break
            case appConstants.LMNP.TVA_OUI:
                this.tva_oui = true;
                this.tva_non = false;
            break
            case appConstants.LMNP.TVA_NON:
                this.tva_non = true;
                this.tva_oui = false;
            break
            case appConstants.MARLAUX.CHARGE_PUBLICITAIRE:
                this.charge_publicite = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.MARLAUX.DATE_REPARTITION:
            return this.date_repatition; 
            case appConstants.MARLAUX.LMNP:
            return this.lmnp; 
            case appConstants.MARLAUX.PASSAGE:
            return this.passage; 
            case appConstants.MARLAUX.REGIME:
            return this.regime; 
            case appConstants.MARLAUX.PUBLIC:
            return this.public; 
            case appConstants.MARLAUX.SUBVENTION:
            return this.subvention;
            case appConstants.MARLAUX.TAUX:
            return this.taux;
            case appConstants.MARLAUX.LOCATION:
            return this.location;
            case appConstants.MARLAUX.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.MARLAUX.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.MARLAUX.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.MARLAUX.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.MARLAUX.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.MARLAUX.PENDANT:
            return this.pendant; 
            case appConstants.MARLAUX.TAUX_REDUCTION:
            return this.taux_reduction; 
            case appConstants.MARLAUX.ABATTEMENT_FORFAITAIRE:
            return this.abatement_forfaitaire;
            case appConstants.MARLAUX.ESPACE_VERT_NON:
            return this.espace_vert_non;
            case appConstants.MARLAUX.ESPACE_VERT_OUI:
            return this.espace_vert_oui;
            case appConstants.MARLAUX.TAUX_DEDUCTION:
            return this.taux_deduction;
            case appConstants.MARLAUX.TAUX_REEVALUATION:
            return this.taux_reevaluation;
            case appConstants.LMNP.TVA_OUI:
            return this.tva_oui; 
            case appConstants.LMNP.TVA_NON:
            return this.tva_non;
            case appConstants.MARLAUX.CHARGE_PUBLICITAIRE:
            return this.charge_publicite;
        }
      return false;
    }
}
export {Monument}