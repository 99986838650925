class GestionClient{
    key: number | null;
    nom: String | null;
    prenom: String | null;
    revenu_fiscal: any | null;
    nbr_part: number | null;
    resultat_foncier: number | 0;
    tache_marginale: String | null;
    impot: String | null;
    evolution: boolean | true;
    tauxEndettement: number | null;
    dateSimulation : Date | null;

    constructor(data?: any) {
        this.key = data?.id;
        this.nom = data?.nom;
        this.prenom = data?.prenom;
        this.tauxEndettement = data?.tauxEndettement
        this.revenu_fiscal = data?.revenuFiscaleReference || null;
        this.nbr_part = data?.nbPartFiscale;
        this.resultat_foncier = data?.resultatFoncier|| 0;
        this.tache_marginale = data?.trancheMarginalImposition || null;
        this.impot = data?.impotReference || null;
        this.evolution = data?.evolutionActuelle;
        this.dateSimulation = data?.dateSimulation;
    }
}

export { GestionClient }