import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import { Autre } from "../../_models/Autre";
import { LieuResidence as LieuResidence2 } from "../../_entities";

class LieuResidence extends Component<PropsFromRedux, any> {
  render() {
    const { model } = this.props;
    const lieu = model.situationFiscale.autre.lieuResidence;
    return (
      <div className="justify-content-around o-h w-100 pt-3 col-lg-12">
        <div className="d-flex flex-column declarant-wrapper1 justify-content-start px-2 pb-3 " style={{ minHeight: "auto !important", height: "auto ! important" }}>
          <h1 style={{ textAlign: "left" }}>
            <span className="px-3 big-text font-weight-bold">Lieu de résidence</span>
          </h1>
          <div className="row pl-2 ml-2 justify-content-left mt-1 pt-0">
            {Autre.lieuResidences.map((item: LieuResidence2, i: number) => {
              return (
                <div className="col-lg-3 custom-control custom-radio" key={i}>
                  <input
                    type="radio"
                    name="lieu_residence"
                    id={`lieu_residence_${i}`}
                    checked={lieu != null && lieu.id !== null? item.id === lieu.id : (i === 0 ? true : false)}
                    onChange={(e) => this.onChange(e, item)}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={`lieu_residence_${i}`}
                  >
                    {item.nom}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }


  onChange(e: any, item: LieuResidence2) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.autre.seLieuResidence(item);
    if (res) {
      model.eventUpdateDossier = true;
      model.situationFiscale.revenu.newCalcule = true;
      this.setCalcutaxe();
      replaceModel(model);
    }
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      //console.log("replace");
    }
  }
}

export default connector(LieuResidence);
