import { appConstants } from "../_constants";
import { Dossier, ObjectifRechercher } from "../_entities";
import { Declarant } from "./../_entities";

class Client {
  declarant1: Declarant;
  declarant2: Declarant;
  declarant3: Declarant;
  declarant4: Declarant;
  objectifRechercher: ObjectifRechercher;
  dossier: Dossier
  dossier1: Dossier;
  dossier2: Dossier;
  id: number | null;
  errTel1: boolean;
  errTel2: boolean;
  validate?: boolean;

  constructor(
    declarant1: Declarant = new Declarant(1, 'Dossier'),
    declarant2: Declarant = new Declarant(2, 'Déclarant 1'),
    declarant3: Declarant = new Declarant(3, 'Déclarant 2'),
    declarant4: Declarant = new Declarant(4, 'Objectif(s) recherché(s)'),
    objectifRechercher: ObjectifRechercher = new ObjectifRechercher(),
    dossier: Dossier = new Dossier(),
    dossier1: Dossier = new Dossier(),
    dossier2: Dossier = new Dossier(),
  ) {
    this.declarant1 = declarant1;
    this.declarant2 = declarant2;
    this.declarant3 = declarant3;
    this.declarant4 = declarant4;
    this.objectifRechercher = objectifRechercher;
    this.dossier = dossier;
    this.dossier1 = dossier1;
    this.dossier2 = dossier2;
    this.id = null;
    this.errTel1 = false;
    this.errTel2 = false;
    this.validate = false;
  }

  serialize() {
    return {
      id: this.id,
      nom: this.dossier.nom,
      adresse: (this.dossier.address1 === "") ? null : this.dossier.address1,
      codePostal: (this.dossier.codePostal === 0) ? null : this.dossier.codePostal,
      ville: this.dossier.address2,
      declarant1: {
        id: this.dossier1.id,
        dateNaisance: this.dossier1.date_naissance,
        profession: (this.dossier1.profession === "") ? null : this.dossier1.profession,
        telephone: this.dossier1.telephone,
      },
      declarant2: {
        id: this.dossier2.id,
        dateNaisance: this.dossier2.date_naissance,
        profession: this.dossier2.profession,
        telephone: this.dossier2.telephone,
      },
      entre5et9ans: this.objectifRechercher.dureeInvestissement.entre5et9ans,
      moins5ans: this.objectifRechercher.dureeInvestissement.moins5ans,
      neufAnsEtPlus: this.objectifRechercher.dureeInvestissement.plusde9ans,
      protectionDejaEnPlace: (this.objectifRechercher.protection_en_place === "") ? null : this.objectifRechercher.protection_en_place,
      protegeFamille: this.objectifRechercher.protegerFamille,
      protegeInvestissment: this.objectifRechercher.profiterInvertissement,
      reduireImpot: this.objectifRechercher.reduireImpots,
      constituePatrimoine: this.objectifRechercher.constituerPatrimoine,
      completeRevenu: this.objectifRechercher.completerRevenue,
      completeRetraite: this.objectifRechercher.completerRetraite
    }
  }

  JsonFromMap(data: any) {
    this.id = data?.id;
    this.dossier.nom = data?.nom;
    this.dossier.address1 = data?.adresse;
    this.dossier.codePostal = data?.codePostal;
    this.dossier.address2 = data?.ville;
    //console.log("date naisse :::::" + data?.declarant1?.dateNaisance);
    this.dossier1.date_naissance = data?.declarant1?.dateNaisance;
    this.dossier1.profession = data?.declarant1?.profession;
    this.dossier1.telephone = data?.declarant1?.telephone;
    this.dossier1.id = data?.declarant1?.id;
    this.dossier2.date_naissance = data?.declarant2?.dateNaisance;
    this.dossier2.profession = data?.declarant2?.profession;
    this.dossier2.telephone = data?.declarant2?.telephone;
    this.dossier2.id = data?.declarant2?.id;
    this.objectifRechercher.dureeInvestissement.entre5et9ans = data?.entre5et9ans;
    this.objectifRechercher.dureeInvestissement.moins5ans = data?.moins5ans;
    this.objectifRechercher.dureeInvestissement.plusde9ans = data?.neufAnsEtPlus;
    this.objectifRechercher.protection_en_place = data?.protectionDejaEnPlace;
    this.objectifRechercher.protegerFamille = data?.protegeFamille;
    this.objectifRechercher.reduireImpots = data?.reduireImpot;
    this.objectifRechercher.constituerPatrimoine = data?.constituePatrimoine;
    this.objectifRechercher.completerRevenue = data?.completeRevenu;
    this.objectifRechercher.completerRetraite = data?.completeRetraite;
    this.objectifRechercher.profiterInvertissement = data?.protegeInvestissment;
  }

  getDeclarant(id: string = '') {
    if (id === 'Dossier') {
      return this.declarant1;
    } else if (id === 'declarant1') {
      return this.declarant2;
    } else if (id === 'declarant2') {
      return this.declarant3;
    } else if (id === 'Objectif(s) recherché(s)') {
      return this.declarant3;
    } else {
      throw new Error("Unknown declarant key");

    }
  }

  onChange(e: any) {
    const { id, value } = e.target;
    let res = true;
    switch (id) {
      case appConstants.DOSSIER_KEY.TELEPHONE2:
        this.dossier1.telephone = value;
        this.errTel1 = ((value.trim()).length < 10) ? true : false;
        break;
      case appConstants.DOSSIER_KEY.PROFESSION2:
        this.dossier1.profession = value;
        break;
      case appConstants.DOSSIER_KEY.DATE_NAISSANCE2:
        this.dossier1.date_naissance = value;
        break;
      case appConstants.DOSSIER_KEY.TELEPHONE3:
        this.dossier2.telephone = value;
        this.errTel1 = ((value.trim()).length < 10) ? true : false;
        break;
      case appConstants.DOSSIER_KEY.PROFESSION3:
        this.dossier2.profession = value;
        break;
      case appConstants.DOSSIER_KEY.DATE_NAISSANCE3:
        this.dossier2.date_naissance = value;
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  getValue(key: string) {
    switch (key) {
      case appConstants.DOSSIER_KEY.TELEPHONE2:
        return this.dossier1.telephone;
      case appConstants.DOSSIER_KEY.PROFESSION2:
        return this.dossier1.profession;
      case appConstants.DOSSIER_KEY.DATE_NAISSANCE2:
        return this.dossier1.date_naissance;
      case appConstants.DOSSIER_KEY.TELEPHONE3:
        return this.dossier2.telephone;
      case appConstants.DOSSIER_KEY.PROFESSION3:
        return this.dossier2.profession;
      case appConstants.DOSSIER_KEY.DATE_NAISSANCE3:
        return this.dossier2.date_naissance;
      //case "ERR TEL1":
      //  return this.errTel1;
      //case "ERR TEL2":
      //  return this.errTel1; 
      default:
        break;
    }
    return false;
  }

  createFormatTel(tel: number | null) {
    if (tel != null) {
      let val: any = tel?.toString();

      if ((0 < val.length) && (val.length < 3)) {
        val = val + " ";
      }
      if ((0 < val.length) && (val.length < 5)) {
        val = val.slice(1, 2) + " " + val.slice(3, 4);
      }
      if ((0 < val.length) && (val.length < 7)) {
        val = val.slice(1, 2) + " " + val.slice(3, 4) + " " + val.slice(5, 6);
      }
      if ((0 < val.length) && (val.length < 9)) {
        val = val.slice(1, 2) + " " + val.slice(3, 4) + " " + val.slice(5, 6) + " " + val.slice(7, 8);
      }
      if ((0 < val.length) && (val.length < 11)) {
        val = val.slice(1, 2) + " " + val.slice(3, 4) + " " + val.slice(5, 6) + " " + val.slice(7, 8) + " " + val.slice(9, 10);
      }
      return val;
    } else {
      return null;
    }

  }
}
export { Client };
