import React, { Component } from 'react';
import { appConstants } from "../../../_constants";
import { PropsFromRedux,connector } from '../../../_reducers';

class Dossier extends Component<PropsFromRedux> {
    render() {
        const { model } = this.props;
        const isNom = model.situationFiscale.client.dossier.isNom;
        return (
            <div>
                <div className="d-flex justify-content-start w-80 mb-2 px-3">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }} >
                        Nom
                        <small style={{ color: "red" }}>*</small>
                  </label>

                    <div className="d-flex border-light-gray w-100  align-items-center px-1" style={{ border: isNom === false ? "1px solid red" : ""}}>
                        <input 
                            type="text" 
                            className="form-control2 flex-1 mr-1"
                            id={appConstants.DOSSIER_KEY.NOM_CLIENT}
                            value={this.getValue(appConstants.DOSSIER_KEY.NOM_CLIENT)}
                            onChange={(e) => this.setValue(e)}
                            />
                    </div>
                </div>

                <div className="d-flex justify-content-start w-80 mb-2 px-3">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }}>
                        Adresse
                  </label>

                    <div className="d-flex border-light-gray w-100  align-items-center px-1">
                        <input 
                            type="text" 
                            className="form-control2 flex-1 mr-1" 
                            id={appConstants.DOSSIER_KEY.ADDRESS1}
                            value={this.getValue(appConstants.DOSSIER_KEY.ADDRESS1)}
                            onChange={(e) => this.setValue(e)}
                            />
                    </div>
                </div>
                <div className="d-flex justify-content-start w-80 mb-2 px-3">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }}>
                    </label>
                    <div className="d-flex border-light-gray mr-2  align-items-center px-1" style={{borderColor: "red !important"}}>
                        <input 
                            type="number" 
                            min="0" 
                            step="0.01"
                            className="form-control2 flex-1 mr-1"
                            id={appConstants.DOSSIER_KEY.CODE_POSTAL}
                            value={this.getValue(appConstants.DOSSIER_KEY.CODE_POSTAL)===0?"":this.getValue(appConstants.DOSSIER_KEY.CODE_POSTAL)}
                            onChange={(e) => this.setValue(e)}    
                            />
                    </div>
                    <div className="d-flex border-light-gray w-100  align-items-center px-1">
                        <input 
                            type="text" 
                            className="form-control2 flex-1 mr-1"
                            id={appConstants.DOSSIER_KEY.ADDRESS2}
                            value={this.getValue(appConstants.DOSSIER_KEY.ADDRESS2)}
                            onChange={(e) => this.setValue(e)}
                            />
                    </div>
                </div>
            </div>
        );
    }

    getValue(key: string): any {
        const { model } = this.props;
        return model.situationFiscale.client.dossier.getValue(key);
    }

    setValue(e: any): any {
        const { model, replaceModel } = this.props;
        const res = model.situationFiscale.client.dossier.onChange(e);
        if (res) {
            model.eventUpdateDossier = true;
            replaceModel(model);
        }
    }
}

const connected = connector(Dossier)
export {connected as Dossier}