import React, { Component } from 'react';
import { connector, PropsFromRedux } from '../../../_reducers';
class Declarant extends Component<any, PropsFromRedux> {
    render() {
        const { name } = this.props;
        const { model } = this.props;
        const isTel = model.situationFiscale.client.dossier1.isTelephone;
        return (
            <div>
                <div className="d-flex justify-content-start w-80 mb-2 px-3">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }} >
                        Téléphone
                  </label>


                    <div className="d-flex border-light-gray w-100  align-items-center px-1" style={{ border: ((isTel === false && name === 2) || this.getValue("ERR TEL" + name)) ? "1px solid red" : "" }}>
                        <input
                            type="tel"
                            size={10}
                            minLength={10}
                            step="0.01"
                            required
                            className="form-control2 flex-1 mr-1"
                            id={'TELEPHONE' + name}
                            value={this.getValue('TELEPHONE' + name)}
                            onChange={(e) => this.setValue(e)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-start w-80 mb-2 px-3">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }} >
                        Profession
                  </label>

                    <div className="d-flex border-light-gray w-100  align-items-center px-1">
                        <input
                            type="text"
                            className="form-control2 flex-1 mr-1"
                            id={'PROFESSION' + name}
                            value={this.getValue('PROFESSION' + name)}
                            onChange={(e) => this.setValue(e)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-start w-80 mb-2 px-3 ">
                    <label className="text-dark-blue font-weight-light mr-2" style={{ minWidth: "80px" }} >
                        Né(e) le
                  </label>

                    <div className="d-flex border-light-gray w-100  align-items-center px-1">
                        <input
                            type="date"
                            className="form-control2 flex-1 mr-1"
                            id={'DATE_NAISSANCE' + name}
                            value={this.getValue('DATE_NAISSANCE' + name)}
                            onChange={(e) => this.setValue(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
    getValue(key: string): any {
        const { model, name } = this.props;
        let val;
        if (key === 'TELEPHONE' + name) {
            val = model.situationFiscale.client.getValue(key);
            if (val !== null && val !== undefined) {
                // val = val.replaceAll("-","");
                //console.log(val)
                if (val.length > 2 && val.length <= 4)
                    val = val.slice(0, 2) + " " + val.slice(2);
                else if (val.length > 4 && val.length <= 6)
                    val = val.slice(0, 2) + " " + val.slice(2, 4) + " " + val.slice(4);
                else if (val.length > 6 && val.length <= 8)
                    val = val.slice(0, 2) + " " + val.slice(2, 4) + " " + val.slice(4, 6) + " " + val.slice(6);
                else if (val.length > 8 && val.length <= 10)
                    val = val.slice(0, 2) + " " + val.slice(2, 4) + " " + val.slice(4, 6) + " " + val.slice(6, 8) + " " + val.slice(8);
                else if (val.length > 10)
                    val = val.slice(0, 2) + " " + val.slice(2, 4) + " " + val.slice(4, 6) + " " + val.slice(6, 8) + " " + val.slice(8, 10) + " " + val.slice(10);
            }
        } else {
            val = model.situationFiscale.client.getValue(key);
        }
        return val;
    }

    setValue(e: any): any {
        const { model, replaceModel, name } = this.props;
        if (e.target.id === 'TELEPHONE' + name) {
            e.target.value = e.target.value.replaceAll(" ", "");
            if (!isNaN(e.target.value.replaceAll(" ", ""))) {
                const res = model.situationFiscale.client.onChange(e);
                if (res) {
                    model.eventUpdateDossier = true;
                    replaceModel(model);
                }
            }
        } else {
            const res = model.situationFiscale.client.onChange(e);
            if (res) {
                model.eventUpdateDossier = true;
                replaceModel(model);
            }
        }
    }
}
const connected = connector(Declarant);
export { connected as Declarant };
