class TauxMarginaleImposition {
  id: number;
  pourcentage: number;

  constructor(
    id: number = 0,
    pourcentage: number = 0
  ) {
    this.id = id;
    this.pourcentage = pourcentage;
  }
}

export { TauxMarginaleImposition }