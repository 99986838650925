import { Devise } from "./Devise";
import { Annee } from "./Annee";
import { Amount } from "./Amount";

class BaseAmount extends Amount {
  montant: number | null;
  devise: Devise;
  revalorisationAnnuelle: number;
  annee: Annee;

  constructor(
    id: number = 0,
    montant: number | null,
    devise: Devise = new Devise(),
    revalorisationAnnuelle: number = 0,
    annee: Annee = new Annee()
  ) {
    super(id, montant, devise)
    this.id = id;
    this.montant = montant;
    this.devise = devise;
    this.annee = annee;
    this.revalorisationAnnuelle = revalorisationAnnuelle;
  }
}

export { BaseAmount }