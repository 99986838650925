import { Subvention } from "../_entities/subvention";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { cloneDeep } from "lodash";

class Subventions {
  montantMonumentSubvention: Subvention;
  montantMalrauxSubvention: Subvention;
  hasAnneeFuturMalrauxSubvention: boolean;
  hasAnneeFuturMonumentSubvention: boolean;
  montantMonumentSubventions: Array<Subvention>;
  montantMalrauxSubventions: Array<Subvention>;

  ancienMontantMonumentSubvention?: Array<Subvention>;
  ancienMontantMalrauxSubvention?: Array<Subvention>;

  constructor(
    montantMonumentSubvention: Subvention = new Subvention(),
    montantMalrauxSubvention: Subvention = new Subvention(),
    hasAnneeFuturMalrauxSubvention: boolean = false,
    hasAnneeFuturMonumentSubvention: boolean = false,
    montantMonumentSubventions: Array<Subvention> = new Array<Subvention>(),
    montantMalrauxSubventions: Array<Subvention> = new Array<Subvention>()
  ) {
    // this.montantSubvention = montantSubvention;
    this.montantMalrauxSubvention = montantMalrauxSubvention;
    this.montantMonumentSubvention = montantMonumentSubvention
    this.hasAnneeFuturMalrauxSubvention = hasAnneeFuturMalrauxSubvention;
    this.hasAnneeFuturMonumentSubvention = hasAnneeFuturMonumentSubvention
    this.montantMonumentSubventions = montantMonumentSubventions;
    this.montantMalrauxSubventions = montantMalrauxSubventions;
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.MONTANT_MONUMENT_SUBVENTION:
        //console.log("montant subvention monument = " + this.montantMonumentSubvention.montant);
        return this.montantMonumentSubvention.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        return this.hasAnneeFuturMonumentSubvention;
      case appConstants.INPUT_KEYS.MONTANT_MALRAUX_SUBVENTION:
        return this.montantMalrauxSubvention.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        return this.hasAnneeFuturMalrauxSubvention;
      default:
        break;
    }
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  hydrateArrayMonumentModelSubvention(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new Subvention(elt))
    }
    return this.montantMonumentSubventions;
  }

  SubventionMonumentArraySerialize() {
    //console.log(this.montantMonumentSubventions);
    if (this.montantMonumentSubventions.length > 0) {
      return this.montantMonumentSubventions.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayMalrauxModelSubvention(data: Array<any>) {
    if (data?.length > 0) {
      //console.log(data);
      return data?.map((elt?) => new Subvention(elt))
    }
    return this.montantMalrauxSubventions;
  }

  SubventionMalrauxArraySerialize() {
    if (this.montantMalrauxSubventions.length > 0) {
      return this.montantMalrauxSubventions.map((elt) => this.serialize(elt))
    }
    return []
  }

  setSubventionMonumentDonneMontant(montant: number) {
    while (this.montantMonumentSubventions.length) {
      this.montantMonumentSubventions.pop();
    }
    if (this.ancienMontantMonumentSubvention?.length) {
      while (this.ancienMontantMonumentSubvention.length) {
        this.ancienMontantMonumentSubvention.pop();
      }
    }
    this.hasAnneeFuturMonumentSubvention = false;
    this.montantMonumentSubvention.montant = montant;
  }

  setSubventionMalrauxDonneMontant(montant: number) {
    while (this.montantMalrauxSubventions.length) {
      this.montantMalrauxSubventions.pop();
    }
    if (this.ancienMontantMalrauxSubvention?.length) {
      while (this.ancienMontantMalrauxSubvention.length) {
        this.ancienMontantMalrauxSubvention.pop();
      }
    }
    this.hasAnneeFuturMalrauxSubvention = false;
    this.montantMalrauxSubvention.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        return {
          leading: this.montantMonumentSubvention.annee.value,
          trailling: this.montantMonumentSubvention.devise.symbole,
          label: true,
          value: this.montantMonumentSubvention.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        return {
          leading: this.montantMalrauxSubvention.annee.value,
          trailling: this.montantMalrauxSubvention.devise.symbole,
          label: true,
          value: this.montantMalrauxSubvention.montant,
        };
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        return this.getMontantMonumentSubvention();
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        return this.getMontantMalrauxSubvention();
      default:
        throw new Error("Invalid label key");
    }
  }

  getMontantMonumentSubvention() {
    if (this.ancienMontantMonumentSubvention?.length && !this.montantMonumentSubventions.length) {
      this.montantMonumentSubventions = cloneDeep(this.ancienMontantMonumentSubvention);
    } else {
      if (this.montantMonumentSubventions.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantMonumentSubvention);
          item.annee.value += i;
          item.id = i;
          this.montantMonumentSubventions.push(item);
        }
      }
    }
    return this.montantMonumentSubventions;
  }
  getMontantMalrauxSubvention() {
    if (this.ancienMontantMalrauxSubvention?.length && !this.montantMalrauxSubventions.length) {
      this.montantMalrauxSubventions = cloneDeep(this.ancienMontantMalrauxSubvention);
    } else {
      if (this.montantMalrauxSubventions.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantMalrauxSubvention);
          item.annee.value += i;
          item.id = i;
          this.montantMalrauxSubventions.push(item);
        }
      }
    }
    return this.montantMalrauxSubventions;
  }

  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        return "Subvention de l'état pour les 20 prochaines années";
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        return "Subvention de l'état pour les 20 prochaines années";
      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
          this.ancienMontantMalrauxSubvention = cloneDeep(this.montantMalrauxSubventions);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
          this.ancienMontantMonumentSubvention = cloneDeep(this.montantMonumentSubventions);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
          if (!this.ancienMontantMalrauxSubvention) {
            this.hasAnneeFuturMalrauxSubvention = false;
          }
          while (this.montantMalrauxSubventions.length) {
            this.montantMalrauxSubventions.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
          if (!this.ancienMontantMonumentSubvention) {
            this.hasAnneeFuturMonumentSubvention = false;
          }
          while (this.montantMonumentSubventions.length) {
            this.montantMonumentSubventions.pop();
          }
          break;
        default:
          break;
      }
    }
  }

  updateMontantMonumentSubvention(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.montantMonumentSubventions.length;
    const index = this.montantMonumentSubventions.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.montantMonumentSubventions[j].montant || 0 : this.montantMonumentSubvention.montant || 0;
        if (value === 0) {
          this.montantMonumentSubventions[i].montant = montant;
        } else {
          this.montantMonumentSubventions[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.montantMonumentSubventions[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.montantMonumentSubventions[i].montant = Math.round(value);
        this.montantMonumentSubventions[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateMontantMalrauxSubvention(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.montantMalrauxSubventions.length;
    const index = this.montantMalrauxSubventions.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.montantMalrauxSubventions[j].montant || 0 : this.montantMalrauxSubvention.montant || 0;
        if (value === 0) {
          this.montantMalrauxSubventions[i].montant = montant;
        } else {
          this.montantMalrauxSubventions[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.montantMalrauxSubventions[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.montantMalrauxSubventions[i].montant = Math.round(value);
        this.montantMalrauxSubventions[i].revalorisationAnnuelle = 0;
      }
    }
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        this.updateMontantMalrauxSubvention(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        this.updateMontantMonumentSubvention(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.MONTANT_MALRAUX_SUBVENTION:
        this.setSubventionMalrauxDonneMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION:
        this.hasAnneeFuturMalrauxSubvention = checked;
        break;
      case appConstants.INPUT_KEYS.MONTANT_MONUMENT_SUBVENTION:
        this.setSubventionMonumentDonneMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION:
        this.hasAnneeFuturMonumentSubvention = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }
}

export { Subventions }