export const SUPPORTS = {
    DATE_PREVISIONNELLE: "DATE_PREVISIONNELLE",
    NOM_SUPPORT: "NOM_SUPPORT",
    SURFACE_TOTALE: "SURFACE_TOTALE",
    TYPE: "TYPE",
    MONTANT_PRODUIT: " MONTANT_PRODUIT",
    TAUX_REVALORISATION: "TAUX_REVALORISATION",
    NOMBRE_PART: "NOMBRE_PART",
    VALEUR_PART: "VALEUR_PART",
    DONT_MONTANT_REDUCTIBLE: "DONT_MONTANT_REDUCTIBLE",
    REVENU_LOCATIF_MENSUEL: "REVENU_LOCATIF_MENSUEL",
    REVENU_LOCATIF_POURCENT: "REVENU_LOCATIF_POURCENT",
    REVENU_LOCATIF_TOUS_LES: "REVENU_LOCATIF_TOUS_LES",
    DIFFERE_AVANT_PREMIER_LOYER: "DIFFERE_AVANT_PREMIER_LOYER",
    FRAIS_SUPLEMENTAIRE: "FRAIS_SUPLEMENTAIRE",
    DONT_LOYER_DROIT_COMUN: "DONT_LOYER_DROIT_COMUN",
    DUREE_SCPI: "DUREE_SCPI",
    FRAIS_GESTION : "FRAIS_GESTION",
    FRAIS_ENTREE : "FRAIS_ENTREE",
    FRAIS_FINANCIER : "FRAIS_FINANCIER",
    FRAIS_SORTIE : "FRAIS_SORTIE",
    GARANTIE_LOCATIVE: "GARANTIE_LOCATIVE",
    CHARGE_COPROPRIETE: "CHARGE_COPROPRIETE",
    FRANCHISE_SANS_LOYER_FIN_SCPI: "FRANCHISE_SANS_LOYER_FIN_SCPI",
    MONTANT_TAXE_FISCAL: "MONTANT_TAXE_FISCAL",
    EXONEREE_A: "EXONEREE_A",
    EXONEREE_PENDANT: "EXONEREE_PENDANT",
    TMI:"TMI",
    REVENUE_FISCALE: "REVENUE_FISCALE",
    LOCATIF_MAX: "LOCATIF_MAX",
    ADHESION_AFUL: "ADHESION_AFUL",
    ZONE: "ZONE",
    ZONE_SCPI: "ZONE_SCPI",
    LOYERMAXI: "LOYERMAXI",
    MOIS: "MOIS",
    ANNEE: "ANNEE",
    
    MONTANT_REVENUE_LOCATIF: "MONTANT_REVENUE_LOCATIF",
    HAS_ANNEE_FUTUR_REVENUE_LOCATIF: "HAS_ANNEE_FUTUR_REVENUE_LOCATIF",

    FRAIS_RESTAURATION: "FRAIS_RESTAURATION",
    HAS_ANNEE_FUTUR_FRAIS_RESTAURATION: "HAS_ANNEE_FUTUR_FRAIS_RESTAURATION",

    MONTANT_TVA_RECUPERE: "MONTANT_TVA_RECUPERE",
    HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE: "HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE",

    HAS_ANNEE_FUTUR_DUREE_SCPI: "HAS_ANNEE_FUTUR_DUREE_SCPI",
}