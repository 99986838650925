export const LMNP= {
    REDUCTION_OUI: "REDUCTION_OUI",
    REDUCTION_NON: "REDUCTION_NON",
    DECOMPOSITION_OUI: "DECOMPOSITION_OUI",
    DECOMPOSITION_NON: "DECOMPOSITION_NON",
    PARTIE_MOBILIERE:"PARTIE_MOBILIERE",
    PARTIE_IMMOBILIERE:"PARTIE_IMMOBILIERE",
    TVA_OUI: "TVA_OUI",
    TVA_NON: "TVA_NON",
    COMPTABILITE_ANNUEL:"COMPTABILITE_ANNUEL",
    REGIME_SOUMIS_OUI: "REGIME_SOUMIS_OUI",
    REGIME_SOUMIS_NON: "REGIME_SOUMIS_NON",
    OEUVRE_AN:"OEUVRE_AN",
    OEUVRE_POURCENT:"OEUVRE_POURCENT",
    FACADE_AN:"FACADE_AN",
    FACADE_POURCENT:"FACADE_POURCENT",
    EQUIPEMENT_AN:"EQUIPEMENT_AN",
    EQUIPEMENT_POURCENT:"EQUIPEMENT_POURCENT",
    AGENCEMENT_AN:"AGENCEMENT_AN",
    AGENCEMENT_POURCENT:"AGENCEMENT_POURCENT",
    TAUX_REDUCTION: "TAUX_REDUCTION",
    PLAFOND_INVESTISSEMENT: "TAUX_REDUCPLAFOND_INVESTISSEMENTTION",
    TAUX_COTISATION:"TAUX_COTISATION",
    BASE_FORFAITAIRE:"BASE_FORFAITAIRE",
    TAUX_TAXE_PROFESSIONNELLE:"TAUX_TAXE_PROFESSIONNELLE"
}