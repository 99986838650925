import React,{Component} from 'react';
import { ClientItem } from './clientItem';
import { connect } from "react-redux";
import { appActions } from "../../_actions";
import { Objectif } from './objectifs/objectif';

class Clients extends Component<any> {
    componentDidMount(){
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 500);
    }
    render(){
        const { model } = this.props;
        return(
            <div className="bg-white mx-0 pt-4 pb-3 h-100 light-shadow content-row">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                            <ClientItem declarant={model?.situationFiscale?.client?.declarant1} />
                        </div>
                        <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-6 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                                        <ClientItem declarant={model?.situationFiscale?.client?.declarant2}/>
                                    </div>
                                    <div className="col-lg-6 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                                        <ClientItem declarant={model?.situationFiscale?.client?.declarant3} />
                                    </div>
                                </div>                        
                        </div>
                        <div className="col-lg-12 mt-3 pb-3 pb-xm-3 pb-sm-3 pb-md-3 pb-lg-0">
                            <Objectif declarant={model?.situationFiscale?.client?.declarant4}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    model: state.app.model,
  });
  
const dispatchToProps = {
...appActions,
};
const connected = connect(mapStateToProps, dispatchToProps)(Clients);

export { connected as Clients }
