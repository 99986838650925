import { Devise } from "./Devise";

class FraisReel {
  id: number | null;
  montant: number | null;
  devise: Devise;

  constructor(
    devise: Devise = new Devise()
  ) {
    this.id = null;
    this.montant = null;
    this.devise = devise;
  }
}

export { FraisReel }