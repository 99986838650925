import { appConstants } from '../_constants/app.constant';
import { Utilisateur } from "../_entities";
import { restClient } from './../_helpers';
const internalIp = require('public-ip');


class LoginAuth {
    utilisateur: Utilisateur;
    errEmail: boolean | null;
    errPassword: boolean | null;
    message: any | null ;

    constructor() {
        this.utilisateur = new Utilisateur();
        this.errEmail = null;
        this.errPassword = null;
        this.message = null;
        this.currentUser();
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.AUTH_KEY.EMAIL:
                let email = {
                    email: this.utilisateur.email,
                    isEmail: this.errEmail
                }
                return email;
            case appConstants.AUTH_KEY.PASSWORD:
                let password = {
                    password: this.utilisateur.password,
                    isPassword: this.errPassword
                }
                return password;
            default:
                break;
        }
        return false;
    }

    onchange(e: any) {
        const { id, value } = e.target;
        let res = true;
        switch (id) {
            case appConstants.AUTH_KEY.EMAIL:
                this.checkEmail(value);
                break;
            case appConstants.AUTH_KEY.PASSWORD:
                this.checkPassword(value);
                break;

            default:
                res = false;
                break;
        }
        return res;
    }

    checkEmail(val: any) {
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
            this.errEmail = true;
            this.utilisateur.email = val;
        } else {
            this.errEmail = false;
            this.utilisateur.email = val;
        }
    }

    checkPassword(val: any) {
        if (val.length >= 6) {
            //console.log(val);
            this.errPassword = true;
            this.utilisateur.password = val;
        } else {
            this.errPassword = false;
            this.utilisateur.password = val;
        }
    }
    async getIp(){
        return await internalIp.v4();
    }

    async login() {
        let dataAuth = {
            ...this.utilisateur.serialize(),
            adresseIp: await this.getIp(),
        }
        //console.log(dataAuth)
        const res = await restClient.post("loginByToken", dataAuth);
        //console.log(res.status);
        if (res.status === 400) {
            return this.message = { faildid:  false, authUse: true };
        }
        if (res.status === 401){
            return this.message = { faildid:  true, authUse: false };
        }
        if (res.status === 403){
            return this.message = { userBloked: true };
        }
        if (res.status === 200) {
            localStorage.setItem("userData", JSON.stringify(res.data)); 
            return this.message = { faildid:  true, authUse: true };
        }
        if (res.status !== 200 && res.status !== 400 && res.status !== 401) {
            return this.message = { faildid:  false, authUse: false };
        }
    }
    reinitialisationAuth() {
        this.utilisateur = new Utilisateur();
    }
    currentUser() {
        let data: any = localStorage.getItem("userData");
        this.utilisateur = new Utilisateur(JSON.parse(data))
    }

}

export { LoginAuth };
