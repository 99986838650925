import { ChoixImmobilier,ChargeIntegre } from '../_entities';
import { appConstants } from '../_constants/app.constant';


class SupportSCPI{
    choixImmobilier: ChoixImmobilier;
    chargeIntegre: ChargeIntegre;
    tmi:number | null;
    revenue_fiscale: number | null;
    locatif_max: number | null;
    errDate: boolean|null;
    errMontant: boolean|null;
    errTaux: boolean|null;
    errRevenueLocatifMenssuel: boolean|null;
    errRevenueLocatifPourcent: boolean|null;
    nLot: number;
    loi: string;
    epargneMoyen: number;
    id: number| null;
    


    constructor(){
        this.choixImmobilier = new ChoixImmobilier();
        this.chargeIntegre = new ChargeIntegre();
        this.tmi = null;
        this.revenue_fiscale = null;
        this.locatif_max = null;
        this.errDate = false;
        this.errMontant = true;
        this.errTaux = true;
        this.errRevenueLocatifMenssuel = true;
        this.errRevenueLocatifPourcent = true;
        this.nLot = 1;
        this.loi = "Pinel";
        this.epargneMoyen = 333;
        this.id = null;
    }

    getValue(key: string){
        switch (key) {
            case appConstants.SUPPORTS.DATE_PREVISIONNELLE:
              return this.errDate;
            case appConstants.INPUT_KEYS.MONTANT_REVENUE_LOCATIF:
              return this.errRevenueLocatifMenssuel; 
            case appConstants.SUPPORTS.REVENU_LOCATIF_POURCENT:
              return this.errRevenueLocatifPourcent;
            case appConstants.SUPPORTS.TAUX_REVALORISATION:
              return this.errTaux;   
            default:
              break;
          }
        return false;
    }

    getValue1(key: string){
        switch (key) {
        case appConstants.SUPPORTS.ADHESION_AFUL:
            return this.choixImmobilier.adhesion_aful;
        case appConstants.SUPPORTS.SURFACE_TOTALE:
            return this.choixImmobilier.surface_totale;
        case appConstants.SUPPORTS.DATE_PREVISIONNELLE:
            return this.choixImmobilier.date_previsionnelle;
            
        case appConstants.SUPPORTS.DIFFERE_AVANT_PREMIER_LOYER:
            return this.choixImmobilier.differe_avant_premier_loyer;
            
        case appConstants.SUPPORTS.DUREE_SCPI:
            return this.choixImmobilier.duree_scpi;
            
        case appConstants.SUPPORTS.FRAIS_ENTREE:
            return this.chargeIntegre.frais_entree;
            
        case appConstants.SUPPORTS.FRAIS_GESTION:
            return this.chargeIntegre.frais_gestion;
            
        case appConstants.SUPPORTS.FRAIS_SORTIE:
            return this.chargeIntegre.frais_sortie;
            
        case appConstants.SUPPORTS.FRAIS_SUPLEMENTAIRE:
            return this.choixImmobilier.frais_suplementaire;
            
        case appConstants.SUPPORTS.FRANCHISE_SANS_LOYER_FIN_SCPI:
            return this.chargeIntegre.franchise_sans_loyer_fin_scpi;
            
        case appConstants.SUPPORTS.MONTANT_PRODUIT:
            return this.choixImmobilier.montant_produit ;
            
        case appConstants.SUPPORTS.NOMBRE_PART:
            return this.choixImmobilier.nombre_part;
            
        case appConstants.SUPPORTS.NOM_SUPPORT:
            return this.choixImmobilier.nom_support;
            
        case appConstants.INPUT_KEYS.MONTANT_REVENUE_LOCATIF:
            return this.choixImmobilier.revenu_locatif_mensuel;
            
        case appConstants.SUPPORTS.REVENU_LOCATIF_POURCENT:
            return this.choixImmobilier.revenu_locatif_pourcent ;
            
        case appConstants.SUPPORTS.REVENU_LOCATIF_TOUS_LES:
            return this.choixImmobilier.revenu_locatif_tous_les;
            
        case appConstants.SUPPORTS.TAUX_REVALORISATION:
            return this.choixImmobilier.taux_revalorisation ;
            
        case appConstants.SUPPORTS.TYPE:
            return this.choixImmobilier.type;
            
        case appConstants.SUPPORTS.VALEUR_PART:
            return this.choixImmobilier.valeur_part;
        case appConstants.SUPPORTS.ZONE_SCPI:
            return this.choixImmobilier.zoneScpi;
        case appConstants.SUPPORTS.MOIS:
            return this.choixImmobilier.mois;
        case appConstants.SUPPORTS.ANNEE:
            return this.choixImmobilier.annee;
            default:
              break;
          }
        return false;
    }
    

    onChange(e: any): boolean {
        const { id, value } = e.target;
        let res = true;
        switch (id) {
        case appConstants.SUPPORTS.SURFACE_TOTALE:
            this.choixImmobilier.surface_totale = value
            break;  
        case appConstants.SUPPORTS.MOIS:
            this.choixImmobilier.mois = value === ""?null:value;
            if(this.choixImmobilier.annee !== null  && this.choixImmobilier.mois !== null ){
            this.checkDatePreisionnel(this.createFormatDate())
            }else{
            this.checkDatePreisionnel("")
            }
            break;
        case appConstants.SUPPORTS.ANNEE:
            this.choixImmobilier.annee = value === ""?null:value;
            if(this.choixImmobilier.annee !== null  && this.choixImmobilier.mois !== null){
            this.checkDatePreisionnel(this.createFormatDate())
            }else{
            this.checkDatePreisionnel("")
            }
            break;
        case appConstants.SUPPORTS.DATE_PREVISIONNELLE:
            this.checkDatePreisionnel(value);
            break;
        case appConstants.SUPPORTS.DIFFERE_AVANT_PREMIER_LOYER:
            this.choixImmobilier.differe_avant_premier_loyer = value
            break;
        case appConstants.SUPPORTS.DUREE_SCPI:
            this.choixImmobilier.duree_scpi = value
            break;
        case appConstants.SUPPORTS.FRAIS_ENTREE:
            this.chargeIntegre.frais_entree = value
            break;
        case appConstants.SUPPORTS.FRAIS_GESTION:
            this.chargeIntegre.frais_gestion = value
            break;
        case appConstants.SUPPORTS.FRAIS_SORTIE:
            this.chargeIntegre.frais_sortie = value
            break;
        case appConstants.SUPPORTS.FRAIS_SUPLEMENTAIRE:
            this.choixImmobilier.frais_suplementaire = value
            break;
        case appConstants.SUPPORTS.FRANCHISE_SANS_LOYER_FIN_SCPI:
            this.chargeIntegre.franchise_sans_loyer_fin_scpi = value
            break;
        case appConstants.SUPPORTS.MONTANT_PRODUIT:
            this.checkMontant(value);
            break;
        case appConstants.SUPPORTS.NOMBRE_PART:
            this.choixImmobilier.nombre_part = value
            break;
        case appConstants.SUPPORTS.NOM_SUPPORT:
            this.choixImmobilier.nom_support = value
            break;
        case appConstants.INPUT_KEYS.MONTANT_REVENUE_LOCATIF:
            this.checkRevenueLocatifMensuelle(value);
            break;
        case appConstants.SUPPORTS.REVENU_LOCATIF_POURCENT:
            this.checkRevenueLocatifPourcent(value);
            break;
        case appConstants.SUPPORTS.REVENU_LOCATIF_TOUS_LES:
            this.choixImmobilier.revenu_locatif_tous_les = value
            break;
        case appConstants.SUPPORTS.TAUX_REVALORISATION:
            this.checkTaux(value);
            break;
        case appConstants.SUPPORTS.TYPE:
            this.choixImmobilier.type = value
            break;
        case appConstants.SUPPORTS.VALEUR_PART:
            this.choixImmobilier.valeur_part = value
            break;
        case appConstants.SUPPORTS.ADHESION_AFUL:
            this.choixImmobilier.adhesion_aful = value;
            break
        case appConstants.SUPPORTS.ZONE_SCPI:
            //console.log('value ++++'+value);
            this.choixImmobilier.zoneScpi = value;

            break
          default:
            throw new Error("Invalid label key");
        }
        return res;
      }

      generatevalFromDate(val:string | null){
    
        if(val !== null){
          let value = new Date(val);
          this.choixImmobilier.mois = value.getMonth() + 1;
          this.choixImmobilier.annee = value.getFullYear();
        }
      }
    
      createFormatDate(){
        return this.choixImmobilier.annee+"-"+this.choixImmobilier.mois
      }

    checkDatePreisionnel(val: any){
        if (val === "") {
            this.errDate = true;
            this.choixImmobilier.date_previsionnelle = val;
        } else{
            this.errDate = false;
            this.choixImmobilier.date_previsionnelle = val;
        }
    }
    checkMontant(val: any){
        if (val === "" || val < 0) {
            this.errMontant = true;
            this.choixImmobilier.montant_produit = val;
        } else{
            this.errMontant = false;
            this.choixImmobilier.montant_produit = val;
        }
    }
    checkTaux(val: any){
        if (val === "" || val < 0) {
            this.errTaux = true;
            this.choixImmobilier.taux_revalorisation = val;
        } else{
            this.errTaux = false;
            this.choixImmobilier.taux_revalorisation = val;
        } 
    }

    checkRevenueLocatifMensuelle(val: any){
        if (val === "" || val < 0) {
            this.errRevenueLocatifMenssuel = true;
            this.choixImmobilier.revenu_locatif_mensuel = val;
        } else{
            this.errRevenueLocatifMenssuel = false;
            this.choixImmobilier.revenu_locatif_mensuel = val;
        } 
    }

    checkRevenueLocatifPourcent(val: any){
        if (val === "" || val < 0) {
            this.errRevenueLocatifPourcent = true;
            this.choixImmobilier.revenu_locatif_pourcent = val;
        } else{
            this.errRevenueLocatifPourcent = false;
            this.choixImmobilier.revenu_locatif_pourcent = val;
        } 
    }

}
export {SupportSCPI}