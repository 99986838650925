import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import { appConstants } from "../../_constants";
import { Modal } from "react-bootstrap";

class EmpruntRow extends Component<PropsFromRedux & { index: number }, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
      message: "Cette fonctionnalité n'est pas disponible pour le moment.",
      title: "Erreur fonctionnelle !"
    }
  }
  render() {
    const value1 = this.getValue(
      appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DATE_DEBUT
    );
    const value2 = this.getValue(
      appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE
    );
    const value3 = this.getValue(appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DUREE);
    const value4 = this.getValue(appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_TAUX);
    const value5 = this.getValue(
      appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DATE_DEBUT
    );
    const value6 = this.getValue(
      appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE
    );
    const value7 = this.getValue(appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DUREE);
    const value8 = this.getValue(appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_TAUX);
    const value9 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DATE_DEBUT
    );
    const value10 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE
    );
    const value11 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DUREE
    );
    const value12 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_TAUX
    );

    return (
      <React.Fragment>
        <div className="row px-2 flex-column justify-content-around">
          <div className="row px-2 pl-2 flex-row  mt-0 mb-0">
            <div className="d-flex col-md-2 flex-column justify-content-center mb-0">
              <p className="big-text mt-2 px-2 mb-3 d-md-block">Emprunt 1</p>
            </div>
            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Date de début</label>
              <div className="d-flex border-light-gray align-items-center px-0 mb-0">
                <input
                  type="date"
                  id={appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DATE_DEBUT}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value9}
                />
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Montant emprunté</label>
               <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  id={
                    appConstants.INPUT_KEYS
                      .RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE
                  }
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value10}
                />
                <small className="font-weight-light text-blue">€</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Durée</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="50"
                  id={appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_DUREE}
                  className="form-control2 flex-1 mr-1"
                  onBlur={(e) => this.setCalcutaxe()}
                  onChange={(e) => this.updateMontant(e)}
                  value={value11}
                />
                <small className="font-weight-light text-blue">ans</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Taux</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  id={appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_TAUX}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value12}
                />
                <small className="font-weight-light text-blue">% TEG</small>
              </div>
            </div>
          </div>
        </div>

        <div className="row px-2 flex-column justify-content-around">
          <div className="row px-2 pl-2 flex-row mt-0 mb-0">
            <div className="d-flex col-md-2 flex-column justify-content-center mb-0">
              <p className="big-text mt-2 px-2 mb-3 d-md-block">Autre emprunt</p>
            </div>
            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Date de début</label>
               <div className="d-flex border-light-gray align-items-center px-0">
                <input
                  type="date"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DATE_DEBUT}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value1}
                />
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Montant emprunté</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value2}
                />
                <small className="font-weight-light text-blue">€</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Durée</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="50"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_DUREE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value3}
                />
                <small className="font-weight-light text-blue">ans</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Taux</label>
               <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_1_TAUX}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value4}
                />
                <small className="font-weight-light text-blue">% TEG</small>
              </div>
            </div>
          </div>  
        </div>

        <div className="row px-2 flex-column justify-content-around">
          <div className="row px-2 pl-2 flex-row mt-0 mb-0">
            <div className="d-flex col-md-2 flex-column justify-content-center mb-0">
              <p className="big-text mt-2 px-2 mb-3 d-md-block">Autre emprunt</p>
            </div>
            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Date de début</label>
               <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="date"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DATE_DEBUT}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value5}
                />
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Montant emprunté</label>
               <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value6}
                />
                <small className="font-weight-light text-blue">€</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Durée</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="50"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_DUREE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value7}
                />
                <small className="font-weight-light text-blue">ans</small>
              </div>
            </div>

            <div className="d-flex col-md-2 flex-column justify-content-center">
              <label className="big-text text-left">Taux</label>
              <div className="d-flex border-light-gray align-items-center px-1">
                <input
                  type="number"
                  step="0.01"
                  id={appConstants.INPUT_KEYS.AUTRE_EMPRUNT_2_TAUX}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value8}
                />
                <small className="font-weight-light text-blue">% TEG</small>
              </div>
            </div>
          </div>
          <div className="custom-control mt-2 ml-2 d-flex justify-content-start mt-2 custom-checkbox">

            <input
              type="checkbox"
              onClick={(e) => this.showModal()}
              className="custom-control-input"
              id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES}
            />
            <label
              onClick={(e) => this.showModal()}
              className="custom-control-label text-small font-weight-light small-radio"
            >
              Saisir d'autres emprunts
            </label>
            {this.getModal()}
          </div>

        </div>
      </React.Fragment>
    );
  }

  getModal(): React.ReactNode {
    const { showModal, message, title } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={() => this.closeModal()}
        backdrop="static"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{message}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn font-weight-light text-white bg-orange text-uppercase"
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal()}
          >
            Fermer
        </button>

        </Modal.Footer>
      </Modal>
    );
  }

  closeModal() {
    this.setState({
      showModal: false
    });
  }

  showModal() {
    //console.log("okokoko")
    this.setState({
      showModal: true
    });
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      // this.props.replaceModel(model);
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      //console.log("replace");
    }
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.endettement.getValue(key);
  }

  updateMontant(e: any) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.endettement.onChange(e);
    if (res) {
      // model.situationFiscale.quotientFamilial.newCalcule = true;
      // model.situationFiscale.revenu.newCalcule = true;
      model.eventUpdateDossier = true;
      model.situationFiscale.revenu.newCalcule = true;
      replaceModel(model);
    }
  }
}

export default connector(EmpruntRow);
