import { appConstants } from "../_constants";

class Lmp {
    reduction_oui: boolean;
    reduction_non: boolean;
    decomposition_oui:boolean;
    decomposition_non:boolean;
    partie_mobiliere:number;
    partie_immobiliere: number;
    tva_oui:boolean;
    tva_non:boolean;
    comptabilite_annuel: number | null;
    regime_soumis_oui:boolean;
    regime_soumis_non:boolean;
    taux_reduction : number;
    plafond : number;
    oeuvre_pourcent: number;
    oeuvre_an: number;
    facade_pourcent: number;
    facade_an: number;
    equipement_pourcent: number;
    equipement_an: number;
    agencement_pourcent: number;
    agencement_an: number;
    taxe_cotisation: number;
    base_forfaitaire: number | null;
    taux_taxe_professionnelle: number | null;
    id: number | null;
    loi: String;


    constructor(){
        this.reduction_oui = true;
        this.reduction_non = false;
        this.decomposition_oui = false;
        this.decomposition_non = true;
        this.partie_mobiliere = 5;
        this.partie_immobiliere = 30;
        this.comptabilite_annuel = null;
        this.regime_soumis_oui = true;
        this.regime_soumis_non = false;
        this.tva_oui = true;
        this.tva_non = false;
        this.taux_reduction = 11;
        this.plafond = 100000000;
        this.oeuvre_pourcent = 40;
        this.oeuvre_an = 50;
        this.facade_an = 30;
        this.facade_pourcent = 20;
        this.equipement_an = 20;
        this.equipement_pourcent = 20;
        this.agencement_pourcent = 20;
        this.agencement_an = 15;
        this.taxe_cotisation = 44.35
        this.base_forfaitaire = null;
        this.taux_taxe_professionnelle = null;
        this.loi = "LMP";
        this.id = null;
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.LMNP.REDUCTION_OUI:
                this.reduction_oui = true;
                this.reduction_non = false;
                this.taux_reduction = 11;
                this.plafond = 100000000;
            break
            case appConstants.LMNP.REDUCTION_NON:
                this.reduction_oui = false;
                this.reduction_non = true;
                this.taux_reduction = 0;
                this.plafond = 0;
            break
            case appConstants.LMNP.DECOMPOSITION_OUI:
                this.decomposition_oui = true;
                this.decomposition_non = false;
                this.oeuvre_pourcent = 40;
                this.oeuvre_an = 50;
                this.facade_an = 30;
                this.facade_pourcent = 20;
                this.equipement_an = 20;
                this.equipement_pourcent = 20;
                this.agencement_pourcent = 20;
                this.agencement_an = 15;
            break
            case appConstants.LMNP.DECOMPOSITION_NON:
                this.decomposition_non = true;
                this.decomposition_oui = false;
                this.oeuvre_pourcent = 0;
                this.oeuvre_an = 0;
                this.facade_an = 0;
                this.facade_pourcent = 0;
                this.equipement_an = 0;
                this.equipement_pourcent = 0;
                this.agencement_pourcent = 0;
                this.agencement_an = 0;
            break
            case appConstants.LMNP.OEUVRE_POURCENT:
                this.oeuvre_pourcent = value;
            break
            case appConstants.LMNP.OEUVRE_AN:
                this.oeuvre_an = value;
            break
            case appConstants.LMNP.FACADE_AN:
                this.facade_an = value;
            break
            case appConstants.LMNP.FACADE_POURCENT:
                this.facade_pourcent = value;
            break
            case appConstants.LMNP.EQUIPEMENT_AN:
                this.equipement_an = value;
            break
            case appConstants.LMNP.EQUIPEMENT_POURCENT:
                this.equipement_pourcent = value;
            break
            case appConstants.LMNP.AGENCEMENT_POURCENT:
                this.agencement_pourcent = value;
            break
            case appConstants.LMNP.AGENCEMENT_AN:
                this.agencement_an = value;
            break
            case appConstants.LMNP.PARTIE_MOBILIERE:
                this.partie_mobiliere = value;
            break
            case appConstants.LMNP.PARTIE_IMMOBILIERE:
                this.partie_immobiliere = value;
            break
            case appConstants.LMNP.COMPTABILITE_ANNUEL:
                this.comptabilite_annuel = value;
            break
            case appConstants.LMNP.REGIME_SOUMIS_OUI:
                this.regime_soumis_oui = true;
                this.regime_soumis_non = false;
                this.taxe_cotisation = 44.35;
            break
            case appConstants.LMNP.REGIME_SOUMIS_NON:
                this.regime_soumis_non = true;
                this.regime_soumis_oui = false;
                this.taxe_cotisation = 0;
            break
            case appConstants.LMNP.TVA_OUI:
                this.tva_oui = true;
                this.tva_non = false;
            break
            case appConstants.LMNP.TVA_NON:
                this.tva_non = true;
                this.tva_oui = false;
            break
            case appConstants.LMNP.TAUX_REDUCTION:
                this.taux_reduction = value;
            break
            case appConstants.LMNP.PLAFOND_INVESTISSEMENT:
                this.plafond = value;
            break
            case appConstants.LMNP.TAUX_COTISATION:
                this.taxe_cotisation = value;
            break
            case appConstants.LMNP.TAUX_TAXE_PROFESSIONNELLE:
                this.taux_taxe_professionnelle = value;
            break
            case appConstants.LMNP.BASE_FORFAITAIRE:
                this.base_forfaitaire = value;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.LMNP.REDUCTION_OUI:
            return this.reduction_oui; 
            case appConstants.LMNP.REDUCTION_NON:
            return this.reduction_non; 
            case appConstants.LMNP.DECOMPOSITION_OUI:
            return this.decomposition_oui; 
            case appConstants.LMNP.DECOMPOSITION_NON:
            return this.decomposition_non; 
            case appConstants.LMNP.PARTIE_MOBILIERE:
            return this.partie_mobiliere; 
            case appConstants.LMNP.PARTIE_IMMOBILIERE:
            return this.partie_immobiliere;
            case appConstants.LMNP.COMPTABILITE_ANNUEL:
            return this.comptabilite_annuel;
            case appConstants.LMNP.REGIME_SOUMIS_OUI:
            return this.regime_soumis_oui;
            case appConstants.LMNP.REGIME_SOUMIS_NON:
            return this.regime_soumis_non;
            case appConstants.LMNP.TVA_OUI:
            return this.tva_oui; 
            case appConstants.LMNP.TVA_NON:
            return this.tva_non;
            case appConstants.LMNP.OEUVRE_POURCENT:
            return this.oeuvre_pourcent ;
            case appConstants.LMNP.OEUVRE_AN:
            return this.oeuvre_an ;
            case appConstants.LMNP.FACADE_AN:
            return this.facade_an ;
            case appConstants.LMNP.FACADE_POURCENT:
            return this.facade_pourcent ;
            case appConstants.LMNP.EQUIPEMENT_AN:
            return this.equipement_an ;
            case appConstants.LMNP.EQUIPEMENT_POURCENT:
            return this.equipement_pourcent ;
            case appConstants.LMNP.AGENCEMENT_POURCENT:
            return this.agencement_pourcent ;
            case appConstants.LMNP.AGENCEMENT_AN:
            return this.agencement_an ;
            case appConstants.LMNP.TAUX_REDUCTION:
            return this.taux_reduction ;
            case appConstants.LMNP.PLAFOND_INVESTISSEMENT:
            return this.plafond ;
            case appConstants.LMNP.TAUX_COTISATION:
            return this.taxe_cotisation ;
            case appConstants.LMNP.TAUX_TAXE_PROFESSIONNELLE:
            return this.taux_taxe_professionnelle ;
            case appConstants.LMNP.BASE_FORFAITAIRE:
            return this.base_forfaitaire ;
            default:
            break;
        }
      return false;
    }
}
export {Lmp}