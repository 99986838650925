
class Accueil {
    key: number | null;
    client: String | null;
    date_simulation: any | null;
    impots: number | null;
    part: number | null;
    loi: String | null;
    lot: String | null;
    scpi: String | null;
    prix: number | null;
    credit: number | null;
    taux: number | null;
    resulta_fiscal: number | null;
    ismulti: boolean | null;


    constructor(data?: any) {
        this.key = data?.id;
        this.client = data?.client.nom;
        this.date_simulation = data?.dateSimulation;
        this.impots = data?.declarationRevenus?.impot?.impot;
        this.part = data?.declarationRevenus?.quotientFamilial?.nbPart || null;
        this.loi = data?.etude?.fiscalite?.loi || null;
        this.scpi = data?.etude?.scpi ? "/ SCPI" : "" ;
        this.lot = data?.etude?.supportImmobilier?.nomProdImoSaisie || null;
        this.prix = data?.etude?.supportImmobilier?.produitFiscalMontant || null;

        this.credit = !data?.etude?.financement?.multiFinancement ? data?.etude?.financement?.credit?.duree || null :
            {
                duree1: { duree: data?.etude?.financement?.creditM1?.duree === undefined || data?.etude?.financement?.creditM1?.duree === 0 ? null : data?.etude?.financement?.creditM1?.duree, periode: data?.etude?.financement?.creditM1?.dureeAn ? 'ans' : 'mois' },
                duree2: { duree: data?.etude?.financement?.creditM2?.duree === undefined || data?.etude?.financement?.creditM2?.duree === 0 ? null : data?.etude?.financement?.creditM2?.duree, periode: data?.etude?.financement?.creditM2?.dureeAn ? 'ans' : 'mois' },
                duree3: { duree: data?.etude?.financement?.creditM3?.duree === undefined || data?.etude?.financement?.creditM3?.duree === 0 ? null : data?.etude?.financement?.creditM3?.duree, periode: data?.etude?.financement?.creditM3?.dureeAn ? 'ans' : 'mois' },
                duree4: { duree: data?.etude?.financement?.creditM4?.duree === undefined || data?.etude?.financement?.creditM4?.duree === 0 ? null : data?.etude?.financement?.creditM4?.duree, periode: data?.etude?.financement?.creditM4?.dureeAn ? 'ans' : 'mois' }
            };
        this.ismulti = !data?.etude?.financement?.multiFinancement;
        this.taux = !data?.etude?.financement?.multiFinancement ? data?.etude?.financement?.credit?.txInteret || null : {
            taux1: data?.etude?.financement?.creditM1?.duree === undefined ? null : data?.etude?.financement?.creditM1?.txInteret,
            taux2: data?.etude?.financement?.creditM2?.duree === undefined ? null : data?.etude?.financement?.creditM2?.txInteret,
            taux3: data?.etude?.financement?.creditM3?.duree === undefined ? null : data?.etude?.financement?.creditM3?.txInteret,
            taux4: data?.etude?.financement?.creditM4?.duree === undefined ? null : data?.etude?.financement?.creditM4?.txInteret
        };
        this.resulta_fiscal = data?.resulta_fiscal || null;
    }


}

export { Accueil };
