export const SORT= {
    SORTBYCLIENTASC : "SORTBYCLIENTASC",
    SORTBYDATEASC : "SORTBYDATEASC",
    SORTBYIMPOTSASC : "SORTBYIMPOTSASC",
    SORTBYPARTASC : "SORTBYPARTASC",
    SORTBYLOIASC : "SORTBYLOIASC",
    SORTBYLOTASC : "SORTBYLOTASC",
    SORTBYPRIXASC : "SORTBYPRIXASC",
    SORTBYCREDITASC : "SORTBYCREDITASC",
    SORTBYTAUXASC : "SORTBYTAUXASC",
    SORTBYRESULTATASC : "SORTBYRESULTATASC",
    SORTBYCLIENTDESC : "SORTBYCLIENTDESC",
    SORTBYDATEDESC : "SORTBYDATEDESC",
    SORTBYIMPOTSDESC : "SORTBYIMPOTSDESC",
    SORTBYPARTDESC : "SORTBYPARTDESC",
    SORTBYLOIDESC : "SORTBYLOIDESC",
    SORTBYLOTDESC : "SORTBYLOTDESC",
    SORTBYPRIXDESC : "SORTBYPRIXDESC",
    SORTBYCREDITDESC : "SORTBYCREDITDESC",
    SORTBYTAUXDESC : "SORTBYTAUXDESC",
    SORTBYRESULTATDESC : "SORTBYRESULTATDESC",
};

export const SORT_FISCALE = {
    NOM_ASC: "NOM_ASC",
    NOM_DESC: "NOM_DESC",
    TYPEEMPRUNT_ASC: "TYPEEMPRUNT_ASC",
    TYPEEMPRUNT_DESC: "TYPEEMPRUNT_DESC",
    DUREE_ASC: "DUREE_ASC",
    DUREE_DESC: "DUREE_DESC",
    TAUXEMPRUNT_ASC: "TAUXEMPRUNT_ASC",
    TAUXEMPRUNT_DESC: "TAUXEMPRUNT_DESC",
    ASSURANCE_ASC: "ASSURANCE_ASC",
    ASSURANCE_DESC: "ASSURANCE_DESC",
    DIFFERECAPITAL_ASC: "DIFFERECAPITAL_ASC",
    DIFFERECAPITAL_DESC: "DIFFERECAPITAL_DESC",
    DIFFERECAPITALMOIS_ASC: "DIFFERECAPITALMOIS_ASC",
    DIFFERECAPITALMOIS_DESC: "DIFFERECAPITALMOIS_DESC",
    DIFFEREINTERET_ASC: "DIFFEREINTERET_ASC",
    DIFFEREINTERET_DESC: "DIFFEREINTERET_DESC",
    DIFFERETINTERETMOIS_ASC: "DIFFERETINTERETMOIS_ASC",
    DIFFERETINTERETMOIS_DESC: "DIFFERETINTERETMOIS_DESC",
    TAUXCAPITAL_ASC: "TAUXCAPITAL_ASC",
    TAUXCAPITAL_DESC: "TAUXCAPITAL_DESC",
};

export const SORT_CLIENT= {
    NOM_ASC : "NOM_ASC",
    NOM_DESC: "NOM_DESC",
    DATE_SIMULATION_ASC: "DATE_SIMULATION_ASC",
    DATE_SIMULATION_DESC: "DATE_SIMULATION_DESC",
    REVENUE_FISCAL_ASC: "REVENUE_FISCAL_ASC",
    REVENUE_FISCAL_DESC: "REVENUE_FISCAL_DESC",
    NB_PARTFISCALE_ASC: "NB_PARTFISCALE_ASC",
    NB_PARTFISCALE_DESC: "NB_PARTFISCALE_DESC",
    RESULTAT_FONCIER_ASC: "RESULTAT_FONCIER_ASC",
    RESULTAT_FONCIER_DESC: "RESULTAT_FONCIER_DESC",
    TRANCHE_MARGINALE_IMPOT_ASC: "TRANCHE_MARGINALE_IMPOT_ASC",
    TRANCHE_MARGINALE_IMPOT_DESC: "TRANCHE_MARGINALE_IMPOT_DESC",
    IMPOT_REFERENCE_ASC: "IMPOT_REFERENCE_ASC",
    IMPOT_REFERENCE_DESC: "IMPOT_REFERENCE_DESC",
    SUTUATION_ACTUELLE_ASC: "SUTUATION_ACTUELLE_ASC",
    SUTUATION_ACTUELLE_DESC: "SUTUATION_ACTUELLE_DESC",
}

export const SORT_IMMOBILIER = {
    NOM_ASC: "NOM_ASC",
    NOM_DESC: "NOM_DESC",
    LOI_ASC: "LOI_ASC",
    LOI_DESC: "LOI_DESC",
    ANNELIVRAISON_ASC: "ANNELIVRAISON_ASC",
    ANNELIVRAISON_DESC: "ANNELIVRAISON_DESC",
    SURFACEFISCALE_ASC: "SURFACEFISCALE_ASC",
    SURFACEFISCALE_DESC: "SURFACEFISCALE_DESC",
    ZONE_ASC: "ZONE_ASC",
    ZONE_DESC: "ZONE_DESC",
    MONTANTTOTAL_ASC: "MONTANTTOTAL_ASC",
    MONTANTTOTAL_DESC: "MONTANTTOTAL_DESC",
    LOYERMENSUEL_ASC: "LOYERMENSUEL_ASC",
    LOYERMENSUEL_DESC: "LOYERMENSUEL_DESC",
    FRAISGESTION_ASC: "FRAISGESTION_ASC",
    FRAISGESTION_DESC: "FRAISGESTION_DESC",
    FRAISGARANTI_ASC: "FRAISGARANTI_ASC",
    FRAISGARANTI_DESC: "FRAISGARANTI_DESC",
    CHARGECOPROPRIETE_ASC: "CHARGECOPROPRIETE_ASC",
    CHARGECOPROPRIETE_DESC: "CHARGECOPROPRIETE_DESC",
    MONTANT_TAXE_ASC: "MONTANT_TAXE_ASC",
    MONTANT_TAXE_DESC: "MONTANT_TAXE_DESC",
    EXOTAXE_ASC: "EXOTAXE_ASC",
    EXOTAXE_DESC: "EXOTAXE_DESC",
    EXOTAXEPENDANT_ASC: "EXOTAXEPENDANT_ASC",
    EXOTAXEPENDANT_DESC: "EXOTAXEPENDANT_DESC",
    TAUXCHARGE_ASC: "TAUXCHARGE_ASC",
    TAUXCHARGE_DESC: "TAUXCHARGE_DESC",
}