class TabProduitAdossement{
    versement_initial:number | null;
    capitale_aterme: number | null;
    versement_mensuel: number | null;
    versementMensuelMinim :number;

    constructor(){
        this.capitale_aterme = null;
        this.versement_initial = null;
        this.versement_mensuel = null;
        this.versementMensuelMinim = 0;
    }

    resetInput(){
        this.capitale_aterme = null;
        this.versement_initial = null;
        this.versement_mensuel = null;
        this.versementMensuelMinim = 0;
    }
}
export { TabProduitAdossement}