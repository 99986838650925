import React, { Component } from 'react';
import { TableHeader } from "./tableBibliothequeFinanciere/tableHeader";
import { TableBody } from "./tableBibliothequeFinanciere/tableBody";
import { FaRegFileExcel} from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { PropsFromRedux, connector } from "../../_reducers";
import { Loader } from "../../_components";

class BibliothequeFinanciere extends Component<PropsFromRedux,any> {

    constructor(props: any){
        super(props);
        this.onSelectedFile = this.onSelectedFile.bind(this)
        this.state={
            fileSelected: null,
            showModal: false,
            data: null,
            showOuiNon: false,
            showOk: true,
            size: "",
            title: "Easy Defisc",
        }
    }

    

    onSelectedFile(e: any){
        var typeFile  = e.target.files[0]?.type;
        //console.log(typeFile);
        if(e.target.files[0]?.size > 1048576){
            return this.setState({
                showModal: true,   
                title: "Erreur",
                size: "sm",
                showOuiNon: false,
                showOk: true,
                data: <p>La taille de votre fichier est tres grand,<br/> 
                Taille max valide est de <strong>1,04 Mbyte</strong></p> 
            });
        }else{
            if("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" === typeFile){
                this.setState({ fileSelected: e.target.files[0] });
                return this.setState({
                    showModal: true,   
                    title: "Import de la Bibliothèque Financiere",
                    size: "sm",
                    showOuiNon: true,
                    showOk: false,
                    data: <p>Voulez-vous importer votre bibliothèque financière, <br/> 
                    <strong>{e.target.files[0].name}</strong> </p>
                });
            }else{
                return this.setState({
                    showModal: true,   
                    title: "Erreur",
                    size: "sm",
                    showOuiNon: false,
                    showOk: true,
                    data: <p>Le format du fichier utilisé ne peut être pris en compte. <br/> 
                    Veuillez sélectionnez un fichier de type <strong> xlsx</strong> </p> 
                  });
            }
        }
        
    }

    async onFileUpload(){
        const { model, replaceModel } = this.props
        model.loading = true;
        model.pagesBibliotheque.page= 0;
        model.produitfiscArray = [];
        replaceModel(model);
        const formData = new FormData();
        formData.append(
          "file",
          this.state.fileSelected,
          this.state.fileSelected.name
        );
        let res = await model.addFile(formData,"/financement/ProduitFinanciers/upload/");
        model.loading = false;
        replaceModel(model);
        if(!res){
            return this.setState({
                showModal: true,   
                title: "Erreur d' importation!",
                size: "sm",
                showOuiNon: false,
                showOk: true,
                data: <p>Une erreur s'est produite lors de l'importation du fichier </p> 
              });
        }else{
            model.loading = true;
            replaceModel(model);
            let user = model.auth.loginAuth.utilisateur;
            let resProduitMono: any = [];
            resProduitMono = await model.getListProduitFinancementBypartenaire(user.id);
            if(resProduitMono !== null ){
                if (resProduitMono?.length > 0) {
                    model.solutionFiscale.financement.monofinancement.listProduitFinancier = resProduitMono
                    replaceModel(model);
                }
            }

            let resProduitMulti: any = [];
            resProduitMulti = await model.getListProduitFinancement1Bypartenaire(user.id);
            //console.log(resProduitMulti);
            if(resProduitMulti !== null){
                if (resProduitMulti?.length > 0) {
                    //console.log(resProduitMulti.length);
                    model.solutionFiscale.financement.amortissement1.listProduitFinancier = resProduitMulti;
                    model.solutionFiscale.financement.amortissement2.listProduitFinancier = resProduitMulti;
                    model.solutionFiscale.financement.amortissement3.listProduitFinancier = resProduitMulti;
                    model.solutionFiscale.financement.inFine.listProduitFinancier = resProduitMulti;
                    replaceModel(model);
                }
            }
            this.getMaxlength()
            const config = {
                page:model.pagesBibliotheque.page,
                size:model.pagesBibliotheque.size
            };

            let data = await model.getProduitFiscaleByPartenaire(config);
            model.produitfiscArray = data;
            model.loading = false;
            replaceModel(model);

            return this.setState({
                showModal: true,   
                title: " Importe Bibliothèque Financiere",
                size: "sm",
                showOuiNon: false,
                showOk: true,
                fileSelected: null,
                data: <p>Votre bibliothèque financier a été importée </p> 
              });

        }
        
      };

      clearFils(e:any){
          e.target.value = null;
      }

      async getMaxlength() {
        const { model,replaceModel } = this.props;
        let res = await model.getNbProdFiscByPartenaire();
        if (res !== null) {
            model.pagesBibliotheque.dataMaxLength = res;
            replaceModel(model);
        }
    }

    render() {

        return (
            <div className="container-fluid ">
                <Loader />
                <div className="d-flex justify-content-end align-items-center px-3">
                    <input type="file" name="file" id="file" className="inputfile" onClick={(e)=>this.clearFils(e)} onChange={(e) => this.onSelectedFile(e)}/>
                    <label htmlFor="file"> 
                        <FaRegFileExcel size="30"/>
                        {(this.state.fileSelected === null|| this.state.fileSelected === undefined )? "Importez votre Bibliothèque Financière":this.state.fileSelected?.name}
                    </label>
                </div>
                <div className="table-responsive">
                    <div className="table-box mx-3 mt-4 ">
                        <table className="table table-striped ">
                            <TableHeader />
                            <TableBody/>
                        </table>
                    </div>
                </div>
                {this.getModal()}
            </div>
        )
    }

    getModal(): React.ReactNode {
        const { showModal, data, title , size} = this.state;
        return (
            <Modal
              show={showModal}
              onHide={() => this.closeModal()}
              backdrop={true}
              data= {data}
              title = {title}
              dialogClassName={size}
              >
              <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center p-2">{data}</Modal.Body>
              <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
            </Modal>
        );
    }
  
    closeModal( ) {
        this.setState({
            showModal: false,
            showOk: true,
            showOuiNon: false,
            title: "Easy Defisc",
        });
    //     const { model } = this.props.Section;
    //    return model.solutionFiscale.support.estimateurFrais;
    }
  
    getFooter() {
        
        return (
          <>
              {
                  this.state.showOk &&
                  <div className="w-100 d-flex justify-content-end">
              
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => this.closeModal()}
                      >
                          ok
                      </button>
                  </div>
              }
  
              {
                  this.state.showOuiNon &&
                  <div className="w-100 d-flex justify-content-between">
              
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => this.closeModal()}
                      >
                          Non
                      </button>
                      <button
                          className="btn font-weight-light text-white bg-orange text-uppercase"
                          style={{ fontSize: "80%" }}
                          onClick={() => {this.closeModal(); this.onFileUpload()}}
                      >
                          oui
                      </button>
                  </div>
              }
          </>
        );
      }
}
const connected = connector(BibliothequeFinanciere);
export { connected as BibliothequeFinanciere };


