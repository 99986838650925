import React, { Component } from "react";
import FoyerFiscaleAutre from "../FoyerFiscal/FoyerFiscaleAutre";
import { PropsFromRedux, connector } from "../../_reducers";
import LieuResidence from "../FoyerFiscal/LieuResidence";

class Autre extends Component<PropsFromRedux, any> {
  render() {
    return (
      <div className="row bg-white mx-3 h-100 light-shadow content-row">
        <div className="col-md-2 o-h h-95 pt-3 justify-content-around text-center d-flex flex-column">
          <div>
            <p className="big-text my-0">Revenus mobiliers de capitaux</p>
          </div>
          <div>
            <span>
              <p className="big-text m-0">Pension</p>
              <small className="text-danger d-flex">
                Saisir le signe "-" si c'est une pension versée
              </small>
            </span>
          </div>
          <div>
            <p className="big-text my-0">Déduction fiscale</p>
            <div className="sep" style={{ minHeight: "10px"}}> </div>
          </div>
          <div>
            <p className="big-text my-0">Réduction fiscale</p>
            <div className="sep" style={{ minHeight: "10px"}}> </div>
          </div>
        </div>
        <FoyerFiscaleAutre />
        <LieuResidence />
      </div>
    );
  }
}

const connected = connector(Autre);
export { connected as Autre };
