class SidebarSolution{
    label: string;
    key: string;
    alt: string;
    img: string;
    activeImg: string;

    constructor(
        label: string,
        key: string,
        alt: string,
        img: string,
        activeImg: string
    ){
        this.label = label;
        this.key = key;
        this.alt = alt;
        this.img = img;
        this.activeImg = activeImg
    }
}
export {SidebarSolution}