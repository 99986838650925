import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import EmpruntRow from "./EmpruntRow";

class Emprunt extends Component<PropsFromRedux, any> {
  render() {
    return (
      <div className="d-flex flex-column declarant-wrapper3 px-2 mt-3 pb-3">
        <h1 className="title-section" style={{ textAlign: "left" }}>
          <span className="px-3 big-text font-weight-bold">Emprunt(s)</span>
        </h1>
        <EmpruntRow index={1}/>
        {/* <EmpruntRow index={2}/>
        <EmpruntRow index={3}/> */}
      </div>
    );
  }
}

export default connector(Emprunt);
