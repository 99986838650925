import { appConstants } from "../_constants";
import { MonoFinancement } from "./mono_financement";
import { MultiFinancement } from "./multi_financement";

class Financement {
  monofinancement: MonoFinancement;
  monoFinancementArray: Array<MonoFinancement>;
  indexArray: number | null;
  isAdd: boolean;
  amortissement1: MultiFinancement;
  amortissement1Array: Array<MultiFinancement>;
  amortissement2: MultiFinancement;
  amortissement2Array: Array<MultiFinancement>;
  amortissement3: MultiFinancement;
  amortissement3Array: Array<MultiFinancement>;
  inFine: MultiFinancement;
  infineArray: Array<MultiFinancement>;
  isAmmort1: boolean;
  isAmmort2: boolean;
  isAmmort3: boolean;
  isinfine: boolean;
  apportPersonnel: number | null;
  apportPersonnelSav: number | null;
  apportPersonnelSavStatus: boolean;

  montantAfinancer: number | 0;
  assurance_dece_invalid: number | null;
  frais_deductible: boolean;
  errassuranceDece: boolean;
  errMontantFinencent: boolean;
  errdureefinancement: boolean;
  errtauxInteret: boolean;
  poucentage: boolean;
  euro: boolean;
  sommeMontantFinanc: number | 0;
  checkAmmort1: boolean;
  checkAmmort2: boolean;
  checkAmmort3: boolean;
  checkinfine: boolean;
  placementTva: boolean;
  financementravaux: boolean;
  pasFinancement: boolean;
  soit: number | null;
  id: number | null;

  constructor(
    monoFinancementArray: Array<MonoFinancement> = new Array<MonoFinancement>(),
    amortissement1Array: Array<MultiFinancement> = new Array<MultiFinancement>(),
    amortissement2Array: Array<MultiFinancement> = new Array<MultiFinancement>(),
    amortissement3Array: Array<MultiFinancement> = new Array<MultiFinancement>(),
    infineArray: Array<MultiFinancement> = new Array<MultiFinancement>()
  ) {
    this.monofinancement = new MonoFinancement();
    this.amortissement1 = new MultiFinancement();
    this.amortissement2 = new MultiFinancement();
    this.amortissement3 = new MultiFinancement();
    this.inFine = new MultiFinancement();
    this.monoFinancementArray = monoFinancementArray;
    this.amortissement1Array = amortissement1Array;
    this.amortissement2Array = amortissement2Array;
    this.amortissement3Array = amortissement3Array;
    this.infineArray = infineArray;
    this.indexArray = null;
    this.isAdd = false;
    this.isAmmort1 = false;
    this.isAmmort2 = false;
    this.isAmmort3 = false;
    this.isinfine = false;
    this.montantAfinancer = 0;
    this.sommeMontantFinanc = 0;
    this.apportPersonnel = null;
    this.apportPersonnelSav = null;
    this.apportPersonnelSavStatus = false;


    this.assurance_dece_invalid = null;
    this.frais_deductible = false;
    this.errassuranceDece = true;
    this.errMontantFinencent = true;
    this.errdureefinancement = true;
    this.errtauxInteret = true;
    this.euro = true;
    this.poucentage = false;
    this.checkAmmort1 = false;
    this.checkAmmort2 = false;
    this.checkAmmort3 = false;
    this.checkinfine = false;
    this.placementTva = false;
    this.financementravaux = true;
    this.pasFinancement = false;
    this.soit = 0;
    this.id = null;
  }
  reInitCollapse() {
    this.isAmmort1 = false;
    this.isAmmort2 = false;
    this.isAmmort3 = false;
    this.isinfine = false;
  }

  collapse(val: string): boolean {
    let res = true;
    switch (val) {
      case "ammortissement 1":
        this.isAmmort1 = !this.isAmmort1;
        this.isAmmort2 = false;
        this.isAmmort3 = false;
        this.isinfine = false;
        break;
      case "ammortissement 2":
        this.isAmmort1 = false;
        this.isAmmort2 = !this.isAmmort2;
        this.isAmmort3 = false;
        this.isinfine = false;
        break;
      case "ammortissement 3":
        this.isAmmort1 = false;
        this.isAmmort2 = false;
        this.isAmmort3 = !this.isAmmort3;
        this.isinfine = false;
        break;
      case "infine":
        this.isAmmort1 = false;
        this.isAmmort2 = false;
        this.isAmmort3 = false;
        this.isinfine = !this.isinfine;
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  onChange(e: any): boolean {
    const { id, value } = e.target;
    let res = true;
    switch (id) {
      case "ammortissement 1":
        this.isAmmort1 = !this.isAmmort1;
        this.isAmmort2 = false;
        this.isAmmort3 = false;
        this.isinfine = false;
        break;
      case "ammortissement 2":
        this.isAmmort1 = false;
        this.isAmmort2 = !this.isAmmort2;
        this.isAmmort3 = false;
        this.isinfine = false;
        break;
      case "ammortissement 3":
        this.isAmmort1 = false;
        this.isAmmort2 = false;
        this.isAmmort3 = !this.isAmmort3;
        this.isinfine = false;
        break;
      case "infine":
        this.isAmmort1 = false;
        this.isAmmort2 = false;
        this.isAmmort3 = false;
        this.isinfine = !this.isinfine;
        break;
      case "ammortissement1":
        this.checkAmmort1 = !this.checkAmmort1;
        if (!this.checkAmmort1) {
          this.amortissement1.caracteristique_financement.resetInput();
          this.getActiveMultiFinancement();
          this.isAmmort1 = false;
          this.sommeMontFinance()
        }
        break;
      case "ammortissement2":
        this.checkAmmort2 = !this.checkAmmort2;
        if (!this.checkAmmort2) {
          this.amortissement2.caracteristique_financement.resetInput();
          this.getActiveMultiFinancement();
          this.isAmmort2 = false;
          this.sommeMontFinance()
        }
        break;
      case "ammortissement3":
        this.checkAmmort3 = !this.checkAmmort3;
        if (!this.checkAmmort3) {
          this.amortissement3.caracteristique_financement.resetInput();
          this.getActiveMultiFinancement();
          this.isAmmort3 = false;
          this.sommeMontFinance()
        }
        break;
      case "infini":
        this.checkinfine = !this.checkinfine;
        if (!this.checkinfine) {
          this.inFine.caracteristique_financement.resetInput();
          this.inFine.produit_adossement.resetInput();
          this.inFine.capital_terme.resetInput();
          this.inFine.apport_initial.versement_initial = 0;
          this.inFine.apport_initial.versement_mensuel = 0;
          this.inFine.trouve_mensualite.versement_initial = 0;
          this.inFine.trouve_mensualite.versement_mensuel = 0;
          this.getActiveMultiFinancement();
          this.isinfine = false;
          this.sommeMontFinance()
        }
        break;
      case appConstants.FINANCEMENT.APPORTPERSONNEL:
        this.apportPersonnel = value;
        break;
      case appConstants.FINANCEMENT.ASSURANCEDECEINVALIDE:
        this.checkAssurance(value);
        break;
      case appConstants.FINANCEMENT.RELAIS_TVA:
        this.placementTva = !this.placementTva;
        break;
      case appConstants.FINANCEMENT.FINANCEMENTFRAISDEDUCTIBLE:
        this.frais_deductible = this
          .pasFinancement
          ? false
          : !this.frais_deductible;
        break;
      case appConstants.FINANCEMENT.EURO:
        this.euro = true;
        this.poucentage = false;
        break;
      case appConstants.FINANCEMENT.FINANCEMENTRAVAUX:
        this.financementravaux = !this.financementravaux;
        break;
      case appConstants.FINANCEMENT.POURCENTAGE:
        this.euro = false;
        this.poucentage = true;
        break;
      case appConstants.FINANCEMENT.SOIT:
        this.soit = value;
        break;
      case 'MULTI_FIANCEMENT':
        this.pasFinancement = false;
        break;
      case appConstants.FINANCEMENT.PASFINANCEMENT:
        if (this.pasFinancement) {
          this.apportPersonnel = 0;
          this.frais_deductible = true;
        }
        this.pasFinancement = !this.pasFinancement;
        if (this.pasFinancement) {
          this.frais_deductible = !this
            .pasFinancement;
        }
        this.resetFinancement();
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  checkAssurance(val: any) {
    this.assurance_dece_invalid = val;
    if (val === "" || val <= 0) {
      this.errassuranceDece = true;
    } else {
      this.errassuranceDece = false;
    }
  }

  getValue(key: String) {
    switch (key) {
      case "ammortissement 1":
        return this.isAmmort1;
      case "ammortissement 2":
        return this.isAmmort2;
      case "ammortissement 3":
        return this.isAmmort3;
      case "infine":
        return this.isinfine;
      case "ammortissement1":
        return this.checkAmmort1;
      case "ammortissement2":
        return this.checkAmmort2;
      case "ammortissement3":
        return this.checkAmmort3;
      case "infini":
        return this.checkinfine;
      case appConstants.FINANCEMENT.APPORTPERSONNEL:
        return this.apportPersonnel;
      case appConstants.FINANCEMENT.ASSURANCEDECEINVALIDE:
        return this.assurance_dece_invalid;
      case appConstants.FINANCEMENT.FINANCEMENTFRAISDEDUCTIBLE:
        return this.frais_deductible;
      case appConstants.FINANCEMENT.EURO:
        return this.euro;
      case appConstants.FINANCEMENT.RELAIS_TVA:
        return this.placementTva;
      case appConstants.FINANCEMENT.POURCENTAGE:
        return this.poucentage;
      case appConstants.FINANCEMENT.FINANCEMENTRAVAUX:
        return this.financementravaux;
      case appConstants.FINANCEMENT.SOIT:
        return this.soit != null ? this.soit : 0;
      case appConstants.FINANCEMENT.PASFINANCEMENT:
        return this.pasFinancement;
      default:
        break;
    }
  }

  getActiveAddMonoFinancement() {
    this.calculateValidation();

    if (this.monofinancement.caracteristique_financement.amortissable) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.monofinancement.caracteristique_financement.inFine &&
      !this.monofinancement.choix_financement.pasFinancement
    ) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxCapitalisation ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.monofinancement.choix_financement.pasFinancement) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  getActiveMultiFinancement() {
    this.errassuranceDece = this.checkValue(this.assurance_dece_invalid);

    if (this.checkAmmort1) {
      this.amortissement1.errMontantFinencent = this.checkValue(this.amortissement1.caracteristique_financement.montant_financement);
      this.amortissement1.errdureefinancement = this.checkValue(this.amortissement1.caracteristique_financement.duree);
      this.amortissement1.errtauxInteret = this.checkValue(this.amortissement1.caracteristique_financement.taux_interet);
    }
    else {
      this.amortissement1.errMontantFinencent = false;
      this.amortissement1.errdureefinancement = false;
      this.amortissement1.errtauxInteret = false;
    }
    if (this.checkAmmort2) {
      this.amortissement2.errMontantFinencent = this.checkValue(this.amortissement2.caracteristique_financement.montant_financement);
      this.amortissement2.errdureefinancement = this.checkValue(this.amortissement2.caracteristique_financement.duree);
      this.amortissement2.errtauxInteret = this.checkValue(this.amortissement2.caracteristique_financement.taux_interet);
    } else {
      this.amortissement2.errMontantFinencent = false;
      this.amortissement2.errdureefinancement = false;
      this.amortissement2.errtauxInteret = false;
    }
    if (this.checkAmmort3) {
      this.amortissement3.errMontantFinencent = this.checkValue(this.amortissement3.caracteristique_financement.montant_financement);
      this.amortissement3.errdureefinancement = this.checkValue(this.amortissement3.caracteristique_financement.duree);
      this.amortissement3.errtauxInteret = this.checkValue(this.amortissement3.caracteristique_financement.taux_interet);
    } else {
      this.amortissement3.errMontantFinencent = false;
      this.amortissement3.errdureefinancement = false;
      this.amortissement3.errtauxInteret = false;
    }

    if (this.checkinfine) {
      this.inFine.errMontantFinencent = this.checkValue(this.inFine.caracteristique_financement.montant_financement);
      this.inFine.errdureefinancement = this.checkValue(this.inFine.caracteristique_financement.duree);
      this.inFine.errtauxInteret = this.checkValue(this.inFine.caracteristique_financement.taux_interet);
      this.inFine.errtauxCapitalisation = this.checkValue(this.inFine.produit_adossement.taux_capitalisation);
    } else {
      this.inFine.errMontantFinencent = false;
      this.inFine.errdureefinancement = false;
      this.inFine.errtauxInteret = false;
      this.inFine.errtauxCapitalisation = false;
    }

    if (this.errassuranceDece) {
      return false;
    }
    if (this.checkAmmort1) {
      if (
        this.amortissement1.errMontantFinencent ||
        this.amortissement1.errdureefinancement ||
        this.amortissement1.errtauxInteret
      ) {
        return false;
      }
    }
    if (this.checkAmmort2) {
      if (
        this.amortissement2.errMontantFinencent ||
        this.amortissement2.errdureefinancement ||
        this.amortissement2.errtauxInteret
      ) {
        return false;
      }
    }
    if (this.checkAmmort3) {
      if (
        this.amortissement3.errMontantFinencent ||
        this.amortissement3.errdureefinancement ||
        this.amortissement3.errtauxInteret
      ) {
        //console.log("test validate");
        return false;
      }
    }
    if (this.checkinfine) {
      if (
        this.inFine.errMontantFinencent ||
        this.inFine.errdureefinancement ||
        this.inFine.errtauxCapitalisation ||
        this.inFine.errtauxInteret
      ) {
        return false;
      }
    }
    return true;
  }

  getActiveCalculeLoyerMensuel() {
    this.calculateValidation();
    if (
      this.monofinancement.errMontantFinencent ||
      this.monofinancement.errassuranceDece ||
      this.monofinancement.errdureefinancement ||
      this.monofinancement.errtauxInteret
    ) {
      return false;
    } else {
      return true;
    }
  }

  getActiveCalculeLoyerMensuelM1() {
    if (this.checkAmmort1) {
      if (
        this.amortissement1.errMontantFinencent ||
        this.amortissement1.errdureefinancement ||
        this.amortissement1.errtauxInteret ||
        this.errassuranceDece
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getActiveCalculeLoyerMensuelM2() {
    if (this.checkAmmort2) {
      if (
        this.amortissement2.errMontantFinencent ||
        this.amortissement2.errdureefinancement ||
        this.amortissement2.errtauxInteret ||
        this.errassuranceDece
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getActiveCalculeLoyerMensuelM3() {
    if (this.checkAmmort3) {
      //console.log("lorem upsum");
      if (
        this.amortissement3.errMontantFinencent ||
        this.amortissement3.errdureefinancement ||
        this.amortissement3.errtauxInteret ||
        this.errassuranceDece
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  getActiveCalculeLoyerMensuelM4() {
    if (this.checkinfine) {
      if (
        this.inFine.errMontantFinencent ||
        this.inFine.errdureefinancement ||
        this.inFine.errtauxInteret ||
        this.errassuranceDece
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getActiveCalculeInfineMultiFinancement() {
    if (this.checkinfine) {
      return this.getActiveMultiFinancement()
    } else {
      return true;
    }
  }

  verifyValueRequiere() {
    this.calculateValidation();
    if (this.monofinancement.caracteristique_financement.amortissable) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.monofinancement.caracteristique_financement.inFine &&
      !this.monofinancement.choix_financement.pasFinancement
    ) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxCapitalisation ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.monofinancement.choix_financement.pasFinancement) {
      if (
        this.monofinancement.errMontantFinencent ||
        this.monofinancement.errassuranceDece ||
        this.monofinancement.errdureefinancement ||
        this.monofinancement.errtauxInteret
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  hydrateMultiplefinancemet(data: any) {
    this.poucentage = data?.adi100;
    this.euro = data?.adi10000;
    this.frais_deductible = (data === null) ? true : data?.financementFrais;
    this.apportPersonnel = data?.appPers;
    this.soit = data?.appPersAvecFrais;
    this.assurance_dece_invalid = data?.assurance;
    this.amortissement1.hydrateMultifinanCaracterisque(data?.creditM1);
    this.amortissement2.hydrateMultifinanCaracterisque(data?.creditM2);
    this.amortissement3.hydrateMultifinanCaracterisque(data?.creditM3);
    this.inFine.hydrateMultifinanCaracterisque(data?.creditM4);
    this.inFine.hydrateProduitFinancier(data?.produitAdossement);
    this.checkinfine = data?.infine4;
    this.placementTva = data?.placementTva;
    this.pasFinancement = data?.pasFinancement;
    this.checkAmmort1 = data?.amortissable1;
    this.checkAmmort2 = data?.amortissable2;
    this.checkAmmort3 = data?.amortissable3;
    this.financementravaux = data?.financementTravaux;
    this.montantAfinancer = data?.montantAFinancer;
    this.sommeMontantFinanc = data?.sommeMontantFinanc;
    //console.log(data?.amortissable1);
    this.errassuranceDece =
      data !== null || data?.assurance !== null ? false : true;
  }

  serializeMultiplefinancement() {
    return {
      adi100: this.poucentage,
      adi10000: this.euro,
      adiFuture: false,
      amortissable1: this.checkAmmort1,
      amortissable2: this.checkAmmort2,
      amortissable3: this.checkAmmort3,
      appPers: this.apportPersonnel,
      appPersAvecFrais: this.soit,
      financementFrais: this.frais_deductible,
      pasFinancement: this.pasFinancement,
      assurance: this.assurance_dece_invalid,
      creditM1:
        this.checkAmmort1 === true
          ? this.amortissement1.serializeMultifinanCaracterisque()
          : null,
      creditM2:
        this.checkAmmort2 === true
          ? this.amortissement2.serializeMultifinanCaracterisque()
          : null,
      creditM3:
        this.checkAmmort3 === true
          ? this.amortissement3.serializeMultifinanCaracterisque()
          : null,
      creditM4:
        this.checkinfine === true
          ? this.inFine.serializeMultifinanCaracterisque()
          : null,
      produitAdossement:
        this.checkinfine === true
          ? this.inFine.serializeProduitAdossement()
          : null,
      multiFinancement: true,
      montantAFinancer: this.montantAfinancer,
      sommeMontantFinanc: this.sommeMontantFinanc,
      infine4: this.checkinfine,
      placementTva: this.placementTva,
      financementTravaux: this.financementravaux,
    };
  }

  saveMultiFinancement(key: string) {
    switch (key) {
      case "ammortissement 1":
        return this.saveAmortissement1();
      case "ammortissement 2":
        return this.saveAmortissement2();
      case "ammortissement 3":
        return this.saveAmortissement3();
      case "infine":
        return this.saveInfine();
      default:
        break;
    }
  }

  saveAmortissement1() {
    if (
      this.amortissement1.errMontantFinencent ||
      this.amortissement1.errdureefinancement ||
      this.amortissement1.errtauxInteret
    ) {
      return false;
    } else {
      return true;
    }
  }

  saveAmortissement2() {
    if (
      this.amortissement2.errMontantFinencent ||
      this.amortissement2.errdureefinancement ||
      this.amortissement2.errtauxInteret
    ) {
      return false;
    } else {
      return true;
    }
  }

  sommeMontFinance() {
    this.sommeMontantFinanc =
      Math.round(
        this.amortissement2.caracteristique_financement.montant_financement ===
          undefined
          ? 0
          : this.amortissement2.caracteristique_financement.montant_financement
      ) +
      Math.round(
        this.amortissement1.caracteristique_financement.montant_financement ===
          undefined
          ? 0
          : this.amortissement1.caracteristique_financement.montant_financement
      ) +
      Math.round(
        this.amortissement3.caracteristique_financement.montant_financement ===
          undefined
          ? 0
          : this.amortissement3.caracteristique_financement.montant_financement
      ) +
      Math.round(
        this.inFine.caracteristique_financement.montant_financement ===
          undefined
          ? 0
          : this.inFine.caracteristique_financement.montant_financement
      );
  }
  saveAmortissement3(): boolean {
    if (
      this.amortissement3.errMontantFinencent ||
      this.amortissement3.errdureefinancement ||
      this.amortissement3.errtauxInteret
    ) {
      return false;
    } else {
      return true;
    }
  }

  saveInfine() {
    if (
      this.inFine.errMontantFinencent ||
      this.inFine.errtauxInteret ||
      this.inFine.errdureefinancement ||
      this.inFine.errtauxCapitalisation
    ) {
      return false;
    } else {
      return true;
    }
  }

  selectSupport(e: any) {
    const { value } = e.target;
    this.monofinancement = this.monoFinancementArray[value];
    this.indexArray = value;
    //console.log(this.indexArray);
  }

  selectMultifianacement(e: any, key: string): boolean {
    let res = true;
    switch (key) {
      case "ammortissement 1":
        this.selectAmmort1(e);
        break;
      case "ammortissement 2":
        this.selectAmmort2(e);
        break;
      case "ammortissement 3":
        this.selectAmmort3(e);
        break;
      case "infine":
        this.selectInfine(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  selectAmmort1(e: any) {
    const { value } = e.target;
    this.amortissement1 = this.amortissement1Array[value];
    this.indexArray = value;
  }

  selectAmmort2(e: any) {
    const { value } = e.target;
    this.amortissement2 = this.amortissement2Array[value];
    this.indexArray = value;
  }
  selectAmmort3(e: any) {
    const { value } = e.target;
    this.amortissement3 = this.amortissement3Array[value];
    this.indexArray = value;
  }
  selectInfine(e: any) {
    const { value } = e.target;
    this.inFine = this.infineArray[value];
    this.indexArray = value;
  }

  getMonoFinancement() {
    return this.monoFinancementArray;
  }

  getMultiFinancement(key: string) {
    switch (key) {
      case "ammortissement 1":
        return this.amortissement1Array;
      case "ammortissement 2":
        return this.amortissement2Array;
      case "ammortissement 3":
        return this.amortissement3Array;
      case "infine":
        return this.infineArray;
      default:
        break;
    }
  }

  deleteMonoFinancent(id: any) {
    //console.log(id);
    this.monoFinancementArray.splice(id, id);
    this.monofinancement = new MonoFinancement();
    this.indexArray = null;
  }

  deletemultifinancement(e: any, key: any): boolean {
    const { id } = e.target;
    //console.log(key);
    let res = true;
    switch (id) {
      case "ammortissement 1":
        this.amortissement1Array.splice(key);
        this.amortissement1 = new MultiFinancement();
        this.indexArray = null;
        break;
      case "ammortissement 2":
        this.amortissement2Array.splice(key);
        this.amortissement2 = new MultiFinancement();
        this.indexArray = null;
        break;
      case "ammortissement 3":
        this.amortissement3Array.splice(key);
        this.amortissement3 = new MultiFinancement();
        this.indexArray = null;
        break;
      case "infine":
        this.infineArray.splice(key);
        this.inFine = new MultiFinancement();
        this.indexArray = null;
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  serializeCaluclAdossementMultiFinace() {
    return {
      adi100: this.poucentage,
      adi10000: this.euro,
      adiFuture: false,
      adiFutureList: [],
      assurance: this.assurance_dece_invalid,
      differeCapital: this.inFine.caracteristique_financement
        .rembourssement_capital,
      differeCapitalMois: this.inFine.caracteristique_financement
        .mois_rembourssement_capital,
      differeInteret: this.inFine.caracteristique_financement
        .rembourssement_interet,
      differeInteretMois: this.inFine.caracteristique_financement
        .mois_rembourssement_interet,
      duree: this.inFine.caracteristique_financement.duree,
      txInteret: this.inFine.caracteristique_financement.taux_interet,
      id: this.id,
      infine4: this.checkinfine,
      pasFinancement: this.pasFinancement,
      credit: this.inFine.caracteristique_financement.montant_financement,
      appPers: this.apportPersonnel,
      appPersAvecFrais: this.soit,
      //financementMultiTravaux: this.inFine.choix_financement.financementPremierAnnee,
      placementTva: this.placementTva,
      amortissable1: this.checkAmmort1,
      amortissable2: this.checkAmmort2,
      amortissable3: this.checkAmmort3,
      mensualiteCreditTotal: this.inFine.caracteristique_financement
        .mensualiteCreditTotal,
      montantInteretDiffere: this.inFine.caracteristique_financement
        .montantInteretDiffere,
      produitAdossement: this.inFine.serializeProduitAdossement(),
    };
  }

  resetFinancement() {
    this.monofinancement = new MonoFinancement();
    this.amortissement1 = new MultiFinancement();
    this.amortissement2 = new MultiFinancement();
    this.amortissement3 = new MultiFinancement();
    this.inFine = new MultiFinancement();
    this.errassuranceDece = true;
    this.errMontantFinencent = true;
    this.errdureefinancement = true;
    this.errtauxInteret = true;
    this.checkAmmort1 = false;
    this.checkAmmort2 = false;
    this.checkAmmort3 = false;
    this.checkinfine = false;
    this.isAmmort1 = false;
    this.isAmmort2 = false;
    this.isAmmort3 = false;
    this.isinfine = false;
    this.montantAfinancer = 0;
    this.sommeMontantFinanc = 0;
  }

  calculateValidation() {
    this.monofinancement.errMontantFinencent = this.checkValue(this.monofinancement.caracteristique_financement.montant_financement);
    this.monofinancement.errassuranceDece = this.checkValue(this.monofinancement.caracteristique_financement.assurance_dece_invalid);
    this.monofinancement.errdureefinancement = this.checkValue(this.monofinancement.caracteristique_financement.duree);
    this.monofinancement.errtauxInteret = this.checkValue(this.monofinancement.caracteristique_financement.taux_interet);

    if (this.monofinancement.caracteristique_financement.inFine) {
      this.monofinancement.errtauxCapitalisation = this.checkValue(this.monofinancement.produit_adossement.taux_capitalisation);
    } else {
      this.monofinancement.errtauxCapitalisation = false;
    }
  }

  checkValue(value: any) {
    return value == null || value === 0 || value === "" ? true : false;
  }

}
export { Financement };

