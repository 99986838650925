import { INPUT_KEYS } from "./input-keys.constant";
import { OBJECTIF_KEY } from "./objectif-key";
import { DOSSIER_KEY } from "./dossier_key";
import { AUTH_KEY } from "./auth_keys";
import { SORT, SORT_CLIENT, SORT_FISCALE, SORT_IMMOBILIER} from "./sort_array";
import { PINELS } from "./pinel";
import { LMNP } from "./lmnp";
import { MARLAUX } from "./marlaux";
import { DUFLOT } from "./duflot";
import { SCELLIERINTERM } from "./scellierInterm";
import { ROBIEN } from "./robien";
import { GIRARDIN } from "./giradin";
import { SUPPORTS } from "./support";
import { ESTIMATION } from "./estimation";
import { FINANCEMENT } from "./financement";
import { PLACEMENT } from "./placement";


export const appConstants = {
  API_PATH: '',
  EASY_DEFISC_VERSION: "1.0.7",
  REPLACE_MODEL_REQUEST: 'REPLACE_MODEL_REQUEST',
  ANNEE_FUTURE_REVENU_NET_IMPOSABLE: 'ANNEE_FUTURE_REVENU_NET_IMPOSABLE',
  ANNEE_FUTURE_BENEFICE: 'ANNEE_FUTURE_BENEFICE',
  ANNEE_FUTURE_NOMBRE_PART_FISCALE: 'ANNEE_FUTURE_NOMBRE_PART_FISCALE',
  REVENU_NET_IMPOSABLE: 'REVENU_NET_IMPOSABLE',
  BENEFICE: 'BENEFICE',
  FRAIS_REEL: 'FRAIS_REEL',
  RESULTAT_FISCALE: 'RESULTAT_FISCALE',
  MARIEOUPACSE: 'MARIEOUPACSE',
  PARENTISOLE: 'PARENTISOLE',
  CELIBATAIRE: 'CELIBATAIRE',
  INPUT_KEYS,
  OBJECTIF_KEY,
  DOSSIER_KEY,
  AUTH_KEY,
  SORT,
  SORT_CLIENT,
  SORT_FISCALE,
  SORT_IMMOBILIER,
  PINELS,
  LMNP,
  MARLAUX,
  DUFLOT,
  SCELLIERINTERM,
  ROBIEN,
  GIRARDIN,
  SUPPORTS,
  ESTIMATION,
  FINANCEMENT,
  PLACEMENT
};