import { NombrePartFiscale } from "./NombrePartFiscale";
import { cloneDeep } from "lodash";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";

class QuotientFamilial {
  id: number | null;
  validate?: boolean;
  isMarieOuPacse: boolean;
  isParentIsole: boolean;
  isCelibataire: boolean;
  hasAnneNombrePartFiscales: boolean;
  nombrePartFiscale: NombrePartFiscale;
  nombrePartFiscales: Array<NombrePartFiscale>;
  newCalcule: boolean;

  ancienNombrePartFiscales?: Array<NombrePartFiscale>;

  constructor(
    id = null,
    isMarieOuPacse: boolean = false,
    isParentIsole: boolean = false,
    isCelibataire: boolean = true,
    hasAnneNombrePartFiscales: boolean = false,
    nombrePartFiscale: NombrePartFiscale = new NombrePartFiscale(),
    nombrePartFiscales: Array<NombrePartFiscale> = new Array<NombrePartFiscale>()
  ) {
    this.id = id;
    this.isMarieOuPacse = isMarieOuPacse;
    this.isParentIsole = isParentIsole;
    this.isCelibataire = isCelibataire;
    this.hasAnneNombrePartFiscales = hasAnneNombrePartFiscales;
    this.nombrePartFiscale = nombrePartFiscale;
    this.nombrePartFiscales = nombrePartFiscales;
    this.validate = false;
    this.newCalcule = true;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.valeur,
    };
  }

  nombrePartFiscaleSerialize() {
    if (this.nombrePartFiscales.length > 0) {
      return this.nombrePartFiscales.map((elt) => this.serialize(elt));
    }
    return [];
  }

  hydrateArrayModelQuotient(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new NombrePartFiscale(elt));
    }
    return this.nombrePartFiscales;
  }

  async initNombrePartFiscales() {
    if (this.ancienNombrePartFiscales?.length) {
      this.nombrePartFiscales = cloneDeep(this.ancienNombrePartFiscales);
    } else {
      if (this.nombrePartFiscales.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.nombrePartFiscale);
          item.annee.value += i;
          item.id = i;
          this.nombrePartFiscales.push(item);
        }
      }
    }
  }

  setNombrePartFiscaleValeur(montant: number) {
    while (this.nombrePartFiscales.length) {
      this.nombrePartFiscales.pop();
    }
    if (this.ancienNombrePartFiscales?.length) {
      while (this.ancienNombrePartFiscales.length) {
        this.ancienNombrePartFiscales.pop();
      }
    }
    this.hasAnneNombrePartFiscales = false;
    this.nombrePartFiscale.valeur = montant;
    this.validate = false;
  }

  onChangeCheck(e: any) {
    let res = true;
    const { id } = e.target;
    switch (id) {
      case appConstants.PARENTISOLE:
        this.isParentIsole = true;
        this.isMarieOuPacse = false;
        this.isCelibataire = false;
        break;
      case appConstants.MARIEOUPACSE:
        this.isParentIsole = false;
        this.isMarieOuPacse = true;
        this.isCelibataire = false;
        break;
      case appConstants.CELIBATAIRE:
        this.isParentIsole = false;
        this.isMarieOuPacse = false;
        this.isCelibataire = true;
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  getCheck(key: string) {
    switch (key) {
      case appConstants.PARENTISOLE:
        return this.isParentIsole;
      case appConstants.MARIEOUPACSE:
        return this.isMarieOuPacse;
      case appConstants.CELIBATAIRE:
        return this.isCelibataire;
      default:
        break;
    }
    return false;
  }

  onChange(e: any) {
    let res = true;
    const key = e?.target?.dataset?.key;
    switch (key) {
      case appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE:
        this.updateNombrePartFiscalesItem(e);
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  updateNombrePartFiscalesItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id } = options;
    const count = this.nombrePartFiscales.length;
    const index = this.nombrePartFiscales.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    const value = utils().getNumberInputValue(e);
    for (let i = index; i < count; i++) {
      this.nombrePartFiscales[i].valeur = value;
    }
  }

  onModalClose(save: boolean, key?: string) {
    //console.log(save);
    //console.log(key);
    if (save) {
      switch (key) {
        case appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE:
          this.ancienNombrePartFiscales = cloneDeep(this.nombrePartFiscales);
          break;

        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE:
          if (!this.ancienNombrePartFiscales) {
            this.hasAnneNombrePartFiscales = false;
          }
          while (this.nombrePartFiscales.length) {
            this.nombrePartFiscales.pop();
          }
          break;

        default:
          break;
      }
    }
  }
}

export { QuotientFamilial };
