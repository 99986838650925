import { RealiseOutreMer } from "./RealiseOutreMer";
import { RealiseMetropole } from "./RealiseMetropole";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { cloneDeep } from "lodash";

class MontantAvantage {
  hasAnneeFuturRealiseOutreMer: boolean;
  hasAnneeFuturRealiseMetropole: boolean;
  realiseOutreMer: RealiseOutreMer;
  realiseMetropole: RealiseMetropole;
  realiseOutreMers: Array<RealiseOutreMer>;
  realiseMetropoles: Array<RealiseMetropole>;

  ancienRealiseMetropoles?: Array<RealiseMetropole>;
  ancienRealiseOutreMers?: Array<RealiseOutreMer>;

  constructor(
    hasAnneeFuturRealiseOutreMer: boolean = false,
    hasAnneeFuturRealiseMetropole: boolean = false,
    realiseOutreMer: RealiseOutreMer = new RealiseOutreMer(),
    realiseMetropole: RealiseMetropole = new RealiseMetropole(),
    realiseOutreMers: Array<RealiseOutreMer> = new Array<RealiseOutreMer>(),
    realiseMetropoles: Array<RealiseMetropole> = new Array<RealiseMetropole>()
  ) {
    this.hasAnneeFuturRealiseOutreMer = hasAnneeFuturRealiseOutreMer;
    this.hasAnneeFuturRealiseMetropole = hasAnneeFuturRealiseMetropole;
    this.realiseOutreMer = realiseOutreMer;
    this.realiseMetropole = realiseMetropole;
    this.realiseOutreMers = realiseOutreMers;
    this.realiseMetropoles = realiseMetropoles;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  realiseOutreMersArraySerialize() {
    if (this.realiseOutreMers.length > 0) {
      return this.realiseOutreMers.map((elt) => this.serialize(elt))
    }
    return []
  }

  realiseMetropolesArraySerialize() {
    if (this.realiseMetropoles.length > 0) {
      return this.realiseMetropoles.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelRealiseOutreMer(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RealiseOutreMer(elt))
    }
    return this.realiseOutreMers;
  }

  hydrateArrayModelRealiseMetropoles(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RealiseMetropole(elt))
    }
    return this.realiseMetropoles
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.REALISES_METROPOLE:
        return this.realiseMetropole.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        return this.hasAnneeFuturRealiseMetropole;
      case appConstants.INPUT_KEYS.REALISES_OUTRE_MER:
        return this.realiseOutreMer.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        return this.hasAnneeFuturRealiseOutreMer;
      default:
        break;
    }
  }

  setRealiseMetropoleMontant(montant: number) {
    while (this.realiseMetropoles.length) {
      this.realiseMetropoles.pop();
    }
    if (this.ancienRealiseMetropoles?.length) {
      while (this.ancienRealiseMetropoles.length) {
        this.ancienRealiseMetropoles.pop();
      }
    }
    this.hasAnneeFuturRealiseMetropole = false;
    this.realiseMetropole.montant = montant;
  }

  setRealiseOutreMerMontant(montant: number) {
    while (this.realiseOutreMers.length) {
      this.realiseOutreMers.pop();
    }
    if (this.ancienRealiseOutreMers?.length) {
      while (this.ancienRealiseOutreMers.length) {
        this.ancienRealiseOutreMers.pop();
      }
    }
    this.hasAnneeFuturRealiseOutreMer = false;
    this.realiseOutreMer.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.REALISES_METROPOLE:
        this.setRealiseMetropoleMontant(val);
        break;
      case appConstants.INPUT_KEYS.REALISES_OUTRE_MER:
        this.setRealiseOutreMerMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        this.hasAnneeFuturRealiseOutreMer = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        this.hasAnneeFuturRealiseMetropole = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        this.updateRealiseOutreMerItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        this.updateRealiseMetropoleItem(e);
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  updateRealiseOutreMerItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.realiseOutreMers.length;
    const index = this.realiseOutreMers.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.realiseOutreMers[j].montant || 0 : this.realiseOutreMer.montant || 0;
        if (value === 0) {
          this.realiseOutreMers[i].montant = montant;
        } else {
          this.realiseOutreMers[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.realiseOutreMers[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.realiseOutreMers[i].montant = Math.round(value);
        this.realiseOutreMers[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateRealiseMetropoleItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.realiseMetropoles.length;
    const index = this.realiseMetropoles.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.realiseMetropoles[j].montant || 0 : this.realiseMetropole.montant || 0;
        if (value === 0) {
          this.realiseMetropoles[i].montant = montant;
        } else {
          this.realiseMetropoles[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.realiseMetropoles[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.realiseMetropoles[i].montant = Math.round(value);
        this.realiseMetropoles[i].revalorisationAnnuelle = 0;
      }
    }
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        return {
          leading: this.realiseMetropole.annee.value,
          trailling: this.realiseMetropole.devise.symbole,
          label: true,
          value: this.realiseMetropole.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        return {
          leading: this.realiseOutreMer.annee.value,
          trailling: this.realiseOutreMer.devise.symbole,
          label: true,
          value: this.realiseOutreMer.montant,
        };

      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        return this.getRealiseMetropoles();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        return this.getRealiseOutreMers();

      default:
        throw new Error("Invalid label key");
    }
  }

  getRealiseMetropoles() {
    if (this.ancienRealiseMetropoles?.length && !this.realiseMetropoles.length) {
      this.realiseMetropoles = cloneDeep(this.ancienRealiseMetropoles);
    } else {
      if (this.realiseMetropoles.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.realiseMetropole);
          item.annee.value += i;
          item.id = i;
          this.realiseMetropoles.push(item);
        }
      }
    }
    return this.realiseMetropoles;
  }

  getRealiseOutreMers() {
    if (this.ancienRealiseOutreMers?.length && !this.realiseOutreMers.length) {
      this.realiseOutreMers = cloneDeep(this.ancienRealiseOutreMers);
    } else {
      if (this.realiseOutreMers.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.realiseOutreMer);
          item.annee.value += i;
          item.id = i;
          this.realiseOutreMers.push(item);
        }
      }
    }
    return this.realiseOutreMers;
  }


  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
        return "Réalisés outre mer sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
        return "Réalisés en métropole sur les 20 prochaines années";

      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
          this.ancienRealiseOutreMers = cloneDeep(this.realiseMetropoles);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
          this.ancienRealiseMetropoles = cloneDeep(this.realiseMetropoles);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER:
          if (!this.ancienRealiseOutreMers) {
            this.hasAnneeFuturRealiseOutreMer = false;
          }
          while (this.realiseOutreMers.length) {
            this.realiseOutreMers.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE:
          if (!this.ancienRealiseMetropoles) {
            this.hasAnneeFuturRealiseMetropole = false;
          }
          while (this.realiseMetropoles.length) {
            this.realiseMetropoles.pop();
          }
          break;

        default:
          break;
      }
    }
  }
}
export { MontantAvantage }