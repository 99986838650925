import React, { Component } from 'react';
import "../../../_assets/scss/array.scss";
import { connector, PropsFromRedux } from "../../../_reducers";

class TableBody extends Component<PropsFromRedux, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            dataLength: 0,
            dataMaxLength: 0,
            pages: {
                page: 0,
                size: 13,
            },
        };

    }

    componentDidMount() {
        this.getMaxlength();
        this.getAllData();
    }

    componentWillUnmount() {
        let { model, replaceModel } = this.props;
        model.produitImmobilierArray = [];
        model.pagesBibliotheque.dataMaxLength = 0;
        model.pagesBibliotheque.page = 0;
        replaceModel(model);
        replaceModel(model);
       
    }

    async getMaxlength() {
        const { model } = this.props;
        let res = await model.getNbProdImoByPartenaire();
        if (res !== null) {
            model.pagesBibliotheque.dataMaxLength = res;
        }
    }

    handleScroll(e: any) {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        const { model } = this.props;
        if (scrollHeight - scrollTop === clientHeight) {
            //console.log(model.produitImmobilierArray.length, " === ", model.pagesBibliotheque.dataMaxLength);
            if (model.produitImmobilierArray.length < model.pagesBibliotheque.dataMaxLength) {
                this.getAllData();
            }
        }
    }

    async getAllData() {
        const { model, replaceModel } = this.props;
        model.loading = true;
        replaceModel(model);
        const config = {
            page:model.pagesBibliotheque.page,
            size:model.pagesBibliotheque.size
        };
        if (model.produitImmobilierArray.length === 0) {
            let data = await model.getProduitImmobilierByPartenaireId(config);
            model.produitImmobilierArray = data;
            model.loading = false;
            replaceModel(model);
        } else {
            model.pagesBibliotheque.page = model.pagesBibliotheque.page + 1;
            replaceModel(model);
            const config = {
                page:model.pagesBibliotheque.page,
                size:model.pagesBibliotheque.size
            };
            let data = await model.getProduitImmobilierByPartenaireId(config);
            for (let dataone of data) {
                model.produitImmobilierArray.push(dataone);
            }
            model.loading = false;
            replaceModel(model);
        }
    }

    render() {
        const { model } = this.props;
        return (
            <tbody onScroll={(e) => { this.handleScroll(e) }}>
                { (model?.produitImmobilierArray.length === 0) ?
                    <tr>
                        <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">Aucune donnée</td>
                    </tr> :
                    model?.produitImmobilierArray?.map((item, i: number) => {
                        //console.log(model?.produitImmobilierArray);
                        return (
                            <tr className="finance">
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.nom}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.loi}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.anneLivraison}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.surfacefiscale}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.zone}</td>
                                <td style={{ fontSize: "11px", width: "150px", verticalAlign: "middle" }} className="text-center" >{item.montantTotal}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.loyerMensuel}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.fraisGestion}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.fraisGarantie}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.chargeCopropriete}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.montantTaxe}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.exoTaxe}</td>
                                <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.exoTaxePendant}</td>
                                {/* <td style={{ fontSize: "11px", width: "100px", verticalAlign: "middle" }} className="text-center" >{item.tauxCharge}</td> */}
                            </tr>
                        )
                    })}
            </tbody>
        );
    }
}
const connected = connector(TableBody);
export { connected as TableBody }