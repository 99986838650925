import { Demenbrement, TypeFiscalite } from '../_entities'
import { Pinel,Lmnp,Marlaux,Duflot,ScellierInterm,Robien,ScellierNeufAns,Regime,Denormandie,Lmp,Monument,Giradin,Zrr } from '../_entities'
import { Subventions } from './subventions'

class TypeFiscaliteModel {
    typeFiscalite: TypeFiscalite[];
    pinel : Pinel;
    lmnp : Lmnp;
    marlaux : Marlaux;
    duflot: Duflot;
    scellierInterm: ScellierInterm;
    robienZrr: Robien;
    robienRecentre: Robien;
    borloo: Robien;
    scellier: ScellierNeufAns;
    regime: Regime;
    denormandie: Denormandie;
    lmp : Lmp;
    monument : Monument;
    giradin: Giradin;
    zrr : Zrr;
    demembrement: Demenbrement;
    subvention: Subventions;
    id: number| null;
    loiCourante: String;
    

    constructor(
        subvention_malraux: Subventions = new Subventions(),
        subvention_monument: Subventions = new Subventions(),
    ){
        this.typeFiscalite = [
            new TypeFiscalite(1, "Pinel", "PINEL", true, false),
            new TypeFiscalite(2, "LMNP","LMNP", false, false),
            new TypeFiscalite(3, "Malraux","MALRAUX", false, false),
            new TypeFiscalite(4, "Régime général","REGIME_GENERAL", false, false),
            new TypeFiscalite(5, "Denormandie","DENORMANDIE", false, false),
            new TypeFiscalite(6, "LMP","LMP", false, false),
            new TypeFiscalite(7, "Monument histo","MONUMENT_HISTORIQUE", false, false),
            new TypeFiscalite(8, "Demembrement","DEMEMBREMENT", false, false),
            new TypeFiscalite(9, "Girardin","GIRARDIN", false, false),
            new TypeFiscalite(10, "Duflot","DUFLOT", false, true),
            new TypeFiscalite(11, "Scellier Interm","SCELLIER_INTERM", false, true),
            new TypeFiscalite(12, "Scellier 9 ans","SCELLIER_9ANS", false, true),
            new TypeFiscalite(13, "Robien recentré","ROBIEN_RECENTRE", false, true),
            new TypeFiscalite(14, "Robien en ZRR","ROBIEN_EN_ZRR", false, true),
            new TypeFiscalite(15, "Borloo populaire","BORLOO_POPULAIRE", false, true),
            new TypeFiscalite(16, "ZRR","ZRR", false, true),
        ];
        this.pinel = new Pinel();
        this.lmnp = new Lmnp();
        this.marlaux = new Marlaux();
        this.duflot = new Duflot();
        this.scellierInterm = new ScellierInterm();
        this.robienZrr = new Robien();
        this.robienRecentre = new Robien();
        this.borloo = new Robien();
        this.scellier = new ScellierNeufAns();
        this.regime = new Regime();
        this.denormandie = new Denormandie();
        this.lmp = new Lmp();
        this.monument = new Monument();
        this.giradin = new Giradin();
        this.zrr = new Zrr();
        this.demembrement = new Demenbrement();
        this.subvention = subvention_malraux;
        this.id = null;
        this.loiCourante = ""
        
    }

    initModel(){
        this.pinel = new Pinel();
        this.lmnp = new Lmnp();
        this.marlaux = new Marlaux();
        this.duflot = new Duflot();
        this.scellierInterm = new ScellierInterm();
        this.robienZrr = new Robien();
        this.robienRecentre = new Robien();
        this.borloo = new Robien();
        this.scellier = new ScellierNeufAns();
        this.regime = new Regime();
        this.denormandie = new Denormandie();
        this.lmp = new Lmp();
        this.monument = new Monument();
        this.giradin = new Giradin();
        this.zrr = new Zrr();
        this.demembrement = new Demenbrement();
    }
    
    onChange(e:any){

        this.initModel();
        const {id} = e.target;
        this.typeFiscalite.forEach(elt => {
            if (elt.constant === id) {
                elt.oncheck(true);
                return;
            }else{
                elt.oncheck(false);
            }
        });
    }

    checkActiveFiscalite( fiscalite : String){
        this.typeFiscalite.forEach(elt => {
            if (elt.constant === fiscalite) {
                //console.log(elt.nom);
                elt.oncheck(true);
                // this.editeLoi(elt.nom);
            }else{
                elt.oncheck(false);
            }
        });
    }
    
    getValue(){
        let data:any ;
        this.typeFiscalite.forEach(elt => {
            if (elt.ischeck) {
                this.loiCourante = elt.nom;
                data = elt;
                return data;
            }  
        });
        return data;
    }

}

export { TypeFiscaliteModel }