import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { utils } from "../../_helpers";
import { connector, PropsFromRedux } from "../../_reducers";
// import { Annee, ImpotPaye, TauxEndettement } from "../../_entities";

class ResultatFiscal extends Component<PropsFromRedux, any, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
      showOk: true,
      showOuiNon: false,
      nav: false,
      route: "",
      message: "",
      title: "Easy Defisc",
    }
  }
  render() {

    return (
      <div className="justify-content-around o-h w-100 pt-3 col-lg-12 px-2">
        <div className="declarant-wrapper   mb-0 mx-2 px-2">
          <h1 style={{ textAlign: "left" }}>
            <span className="px-3 big-text font-weight-bold">Situation du foyer</span>
          </h1>
          <div className="row pb-3 justify-content-between ml-2 px-1 mr-2">

            <div className="px-0 col-lg-auto col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <span className="text-dark-blue text-center mb-2">
                Impôt payés sur revenus n-1
           </span>
              <div className="d-flex justify-left-center align-items-center w-100">
                <small className="text-dark-blue  text-blue pr-2">{this.getValue("ANNEE IMPOT IMPAYE")}</small>
                <div className="d-flex border-white w-100 mt-0 align-items-center px-1 backrounGris" >
                  <input
                    type="number"
                    id="IMPOT IMPAYE"
                    readOnly={true}
                    step="0.01"
                    className="form-control2 flex-1 mr-1"
                    value={this.getValue("IMPOT IMPAYE")}
                    onChange={(e) => this.setValue(e)}
                  />
                  <small className="text-dark-blue  text-blue">€</small>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <span className="text-dark-blue text-center mb-2">
                Tranche marginale d'imposition
        </span>
              <div className="d-flex justify-content-center align-items-center w-100">
                <small className="text-dark-blue  text-blue pr-2">{this.getValue("ANNEE TRANCHE MARGINALE")}</small>
                <div className="d-flex border-white w-100 mt-0 align-items-center px-1 backrounGris">
                  <input
                    type="number"
                    readOnly={true}
                    step="0.01"
                    className="form-control2 flex-1 mr-1"
                    value={this.props.model.situationFiscale.resultatFiscale.tauxMarginaleImposition.pourcentage}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <small className="text-dark-blue  text-blue">%</small>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <span className="text-dark-blue text-center mb-2">
                Taux d'endettement
        </span>
              <div className="d-flex justify-content-center align-items-center w-100">
                <small className="text-dark-blue  text-blue pr-2">{this.getValue("ANNEE TAUX D'ENDETTEMENT")}</small>
                <div className="d-flex border-white w-100 mt-0 align-items-center px-1 backrounGris">
                  <input
                    type="number"
                    id="TAUX D'ENDETTEMENT"
                    readOnly={true}
                    step="0.01"
                    className="form-control2 flex-1 mr-1"
                    value={this.getValue("TAUX D'ENDETTEMENT")}
                    onChange={(e) => this.setValue(e)}
                  />
                  <small className="text-dark-blue  text-blue">%</small>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <span className="text-dark-blue text-center mb-2">
                IFI
              </span>
              <div className="d-flex justify-content-center align-items-center w-100">
                <small className="text-dark-blue  text-blue pr-2">{this.getValue("ANNEE ISF")}</small>
                <div className="d-flex border-white w-100 mt-0 align-items-center px-1 backrounGris">
                  <input
                    type="number"
                    id="ISF"
                    readOnly={true}
                    step="0.01"
                    className="form-control2 flex-1 mr-1"
                    value={this.getValue("ISF")}
                    onChange={(e) => this.setValue(e)}
                  />
                  <small className="text-dark-blue  text-blue">€</small>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-sm-12 d-flex flex-column justify-content-center align-items-center">


              <div className="d-flex justify-content-center align-items-center flex-column">
                <span className="text-dark-blue text-center mb-2">
                  Évolutions
              </span>
                <button
                  className="btn text-dark-blue  text-white bg-orange"
                  style={{ fontSize: "80%" }}
                  onClick={(e) => this.initAfficheAnne()}
                >
                  Afficher les 10 années
              </button>
              </div>
            </div>
          </div>
        </div>
        {this.getModal()}
      </div>
    );
  }

  getModal(): React.ReactNode {
    const { showModal, message, title } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={() => this.closeModal("", false, false)}
        backdrop="true"
      // size="md"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{message}</Modal.Body>
        <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
      </Modal>
    );
  }

  getFooter() {
    const { showOuiNon, showOk, nav, route } = this.state;
    return (
      <div
        className={
          "w-100 d-flex " +
          (!showOk ? "justify-content-between" : "justify-content-end")
        }
      >
        {showOuiNon && (
          <button
            className="btn text-dark-blue  text-white bg-orange text-uppercase"
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal(route, false, false)}
          >
            Non
          </button>
        )}
        {showOuiNon && !nav && <button
          className="btn text-dark-blue  text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, true, false)}
        >
          Oui
        </button>}
        {showOuiNon && nav && <button
          className="btn text-dark-blue  text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, true, true)}
        >
          Oui
        </button>}
        {showOk && <button
          className="btn text-dark-blue  text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, false, false)}
        >
          Ok
        </button>}
      </div>
    );
  }

  closeModal(data: string, oui?: boolean, ok?: boolean) {
    this.setState({
      showModal: false,
      showOk: true,
      showOuiNon: false,
      nav: false,
      route: "",
      message: "",
      title: "Easy Defisc",
    });
    if (oui && !ok) {
      const { model, changeSection } = this.props;
      const section = model.situationFiscale.getNextSection();
      window.scrollTo(0, 0)
      changeSection(model, section);
    }
  }
  async initAfficheAnne() {
    const { model, replaceModel, initResultatFiscale } = this.props;
    const { revenu } = model.situationFiscale;
    model.loading = true;
    replaceModel(model);
    const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;
    const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;
    if (revenuNet1 === 0 || revenuNet1 == null) {
      revenu.declarant1.validate = true;
      model.loading = false;
      replaceModel(model);
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, un revenu net imposable"
      });
    }
    if (part === 0 || part == null) {
      model.situationFiscale.quotientFamilial.validate = true;
      model.loading = false;
      replaceModel(model);
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, le nombre de parts fiscales."
      });
    }
    if (model.situationFiscale.revenu.newCalcule || model.situationFiscale.quotientFamilial.newCalcule) {
      let res = model.calculTax();
      if (await res === true) {
        model.situationFiscale.revenu.newCalcule = false;
        model.situationFiscale.quotientFamilial.newCalcule = false;
        model.loading = false;
        replaceModel(model);
        initResultatFiscale(model);
      } else {
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Une erreur s'est produite lors du calcul des impôts"
        });
      }
    } else {
      model.loading = false;
      replaceModel(model);
      initResultatFiscale(model);
    }

  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { model, replaceModel } = this.props;
    model.situationFiscale.resultatFiscale.tauxMarginaleImposition.pourcentage = utils().getNumberInputValue(e);
    replaceModel(model);
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.resultatFiscale.getValue(key);
  }

  setValue(e: any): any {
    const { model, replaceModel } = this.props;
    //console.log(e.target);
    const res = model.situationFiscale.resultatFiscale.onChange(e);
    if (res) {
      replaceModel(model);
    }
  }
}


const connected = connector(ResultatFiscal);

export { connected as ResultatFiscal };

