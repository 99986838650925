import { cloneDeep } from "lodash";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { DeductionFiscale } from "./DeductionFiscale";
import { Pension } from "./Pension";
import { ReductionFiscale } from "./ReductionFiscale";
import { RevenusMobilierCapitaux } from "./RevenusMobilierCapitaux";

class FoyerFiscalAutre {
  id: number;
  nom: string;


  hasAnneeFuturRevenusMobilierCapitaux: boolean;
  hasAnneeFuturPension: boolean;
  hasAnneeFuturDeductionFiscale: boolean;
  hasAnneeFuturReductionFiscale: boolean;

  revenusMobilierCapitaux: RevenusMobilierCapitaux;
  pension: Pension;
  deductionFiscale: DeductionFiscale;
  reductionFiscale: ReductionFiscale;

  revenusMobilierCapitauxs: Array<RevenusMobilierCapitaux>;
  pensions: Array<Pension>;
  deductionFiscales: Array<DeductionFiscale>;
  reductionFiscales: Array<ReductionFiscale>;

  ancienRevenusMobilierCapitauxs?: Array<RevenusMobilierCapitaux>;
  ancienPensions?: Array<Pension>;
  ancienDeductionFiscales?: Array<DeductionFiscale>;
  ancienReductionFiscales?: Array<ReductionFiscale>;

  constructor(
    id: number = 0,
    nom: string = "Foyer Fiscale Autre",
    hasAnneeFuturRevenusMobilierCapitaux: boolean = false,
    hasAnneeFuturPension: boolean = false,
    hasAnneeFuturDeductionFiscale: boolean = false,
    hasAnneeFuturReductionFiscale: boolean = false,

    revenusMobilierCapitaux: RevenusMobilierCapitaux = new RevenusMobilierCapitaux(),
    pension: Pension = new Pension(),
    deductionFiscale: DeductionFiscale = new DeductionFiscale(),
    reductionFiscale: ReductionFiscale = new ReductionFiscale(),

    revenusMobilierCapitauxs: Array<RevenusMobilierCapitaux> = new Array<RevenusMobilierCapitaux>(),
    pensions: Array<Pension> = new Array<Pension>(),
    deductionFiscales: Array<DeductionFiscale> = new Array<DeductionFiscale>(),
    reductionFiscales: Array<ReductionFiscale> = new Array<ReductionFiscale>()
  ) {
    this.id = id;
    this.nom = nom;
    
    this.hasAnneeFuturRevenusMobilierCapitaux = hasAnneeFuturRevenusMobilierCapitaux;
    this.hasAnneeFuturPension = hasAnneeFuturPension;
    this.hasAnneeFuturDeductionFiscale = hasAnneeFuturDeductionFiscale;
    this.hasAnneeFuturReductionFiscale = hasAnneeFuturReductionFiscale;

    this.revenusMobilierCapitaux = revenusMobilierCapitaux;
    this.pension = pension;
    this.deductionFiscale = deductionFiscale;
    this.reductionFiscale = reductionFiscale;

    this.revenusMobilierCapitauxs = revenusMobilierCapitauxs;
    this.pensions = pensions;
    this.deductionFiscales = deductionFiscales;
    this.reductionFiscales = reductionFiscales;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  revenusMobilierCapitauxsArraySerialize() {
    if (this.revenusMobilierCapitauxs.length > 0) {
      return this.revenusMobilierCapitauxs.map((elt) => this.serialize(elt))
    }
    return []
  }

  pensionsArraySerialize() {
    if (this.pensions.length > 0) {
      return this.pensions.map((elt) => this.serialize(elt))
    }
    return []
  }

  deductionFiscalesArraySerialize() {
    if (this.deductionFiscales.length > 0) {
      return this.deductionFiscales.map((elt) => this.serialize(elt))
    }
    return []
  }

  reductionFiscalesArraySerialize() {
    if (this.reductionFiscales.length > 0) {
      return this.reductionFiscales.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelDeductionFiscale(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new DeductionFiscale(elt))
    }
    return this.deductionFiscales;
  }

  hydrateArrayModelReductionFiscale(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new ReductionFiscale(elt))
    }
    return this.reductionFiscales;
  }

  hydrateArrayModelRevenusMobilierCapitaux(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RevenusMobilierCapitaux(elt))
    }
    return this.revenusMobilierCapitauxs;
  }

  hydrateArrayModelPension(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new Pension(elt))
    }
    return this.pensions;
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.REVENU_MOBILIER_CAPITAUX:
        return this.revenusMobilierCapitaux.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        return this.hasAnneeFuturRevenusMobilierCapitaux;
      case appConstants.INPUT_KEYS.PENSION:
        return this.pension.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        return this.hasAnneeFuturPension;
      case appConstants.INPUT_KEYS.DEDUCTION_FISCALE:
        return this.deductionFiscale.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        return this.hasAnneeFuturDeductionFiscale;
      case appConstants.INPUT_KEYS.REDUCTION_FISCALE:
        return this.reductionFiscale.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        return this.hasAnneeFuturReductionFiscale;

      default:
        break;
    }
  }

  setRevenusMobilierCapitauxMontant(montant: number) {
    while (this.revenusMobilierCapitauxs.length) {
      this.revenusMobilierCapitauxs.pop();
    }
    if (this.ancienRevenusMobilierCapitauxs?.length) {
      while (this.ancienRevenusMobilierCapitauxs.length) {
        this.ancienRevenusMobilierCapitauxs.pop();
      }
    }
    this.hasAnneeFuturRevenusMobilierCapitaux = false;
    this.revenusMobilierCapitaux.montant = montant;
  }

  setPensionMontant(montant: number) {
    while (this.pensions.length) {
      this.pensions.pop();
    }
    if (this.ancienPensions?.length) {
      while (this.ancienPensions.length) {
        this.ancienPensions.pop();
      }
    }
    this.hasAnneeFuturPension = false;
    this.pension.montant = montant;
  }

  setDeductionFiscaleMontant(montant: number) {
    while (this.deductionFiscales.length) {
      this.deductionFiscales.pop();
    }
    if (this.ancienDeductionFiscales?.length) {
      while (this.ancienDeductionFiscales.length) {
        this.ancienDeductionFiscales.pop();
      }
    }
    this.hasAnneeFuturDeductionFiscale = false;
    this.deductionFiscale.montant = montant;
  }

  setReductionFiscaleMontant(montant: number) {
    while (this.reductionFiscales.length) {
      this.reductionFiscales.pop();
    }
    if (this.ancienReductionFiscales?.length) {
      while (this.ancienReductionFiscales.length) {
        this.ancienReductionFiscales.pop();
      }
    }
    this.hasAnneeFuturReductionFiscale = false;
    this.reductionFiscale.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.REVENU_MOBILIER_CAPITAUX:
        this.setRevenusMobilierCapitauxMontant(val);
        break;
      case appConstants.INPUT_KEYS.PENSION:
        this.setPensionMontant(val);
        break;
      case appConstants.INPUT_KEYS.DEDUCTION_FISCALE:
        this.setDeductionFiscaleMontant(val);
        break;
      case appConstants.INPUT_KEYS.REDUCTION_FISCALE:
        this.setReductionFiscaleMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        this.hasAnneeFuturRevenusMobilierCapitaux = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        this.hasAnneeFuturPension = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        this.hasAnneeFuturDeductionFiscale = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        this.hasAnneeFuturReductionFiscale = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        this.updateRevenusMobilierCapitauxItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        this.updatePensionItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        this.updateDeductionFiscaleItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        this.updateReductionFiscaleItem(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  updateRevenusMobilierCapitauxItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.revenusMobilierCapitauxs.length;
    const index = this.revenusMobilierCapitauxs.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant =
          j > -1 ? this.revenusMobilierCapitauxs[j].montant || 0 : this.revenusMobilierCapitaux.montant || 0;
        if (value === 0) {
          this.revenusMobilierCapitauxs[i].montant = montant;
        } else {
          this.revenusMobilierCapitauxs[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.revenusMobilierCapitauxs[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.revenusMobilierCapitauxs[i].montant = Math.round(value);
        this.revenusMobilierCapitauxs[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateDeductionFiscaleItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.deductionFiscales.length;
    const index = this.deductionFiscales.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant =
          j > -1
            ? this.deductionFiscales[j].montant || 0
            : this.deductionFiscale.montant || 0;
        if (value === 0) {
          this.deductionFiscales[i].montant = montant;
        } else {
          this.deductionFiscales[i].montant =
            Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.deductionFiscales[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.deductionFiscales[i].montant = Math.round(value);
        this.deductionFiscales[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateReductionFiscaleItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.reductionFiscales.length;
    const index = this.reductionFiscales.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant =
          j > -1
            ? this.reductionFiscales[j].montant || 0
            : this.reductionFiscale.montant || 0;
        if (value === 0) {
          this.reductionFiscales[i].montant = montant;
        } else {
          this.reductionFiscales[i].montant =
            Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.reductionFiscales[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.reductionFiscales[i].montant = Math.round(value);
        this.reductionFiscales[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updatePensionItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.pensions.length;
    const index = this.pensions.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.pensions[j].montant || 0 : this.pension.montant || 0;
        if (value === 0) {
          this.pensions[i].montant = montant;
        } else {
          this.pensions[i].montant =
            Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.pensions[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.pensions[i].montant = Math.round(value);
        this.pensions[i].revalorisationAnnuelle = 0;
      }
    }
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        return {
          leading: this.revenusMobilierCapitaux.annee.value,
          trailling: this.revenusMobilierCapitaux.devise.symbole,
          label: true,
          value: this.revenusMobilierCapitaux.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        return {
          leading: this.pension.annee.value,
          trailling: this.pension.devise.symbole,
          label: true,
          value: this.pension.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        return {
          leading: this.deductionFiscale.annee.value,
          trailling: this.deductionFiscale.devise.symbole,
          label: true,
          value: this.deductionFiscale.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        return {
          leading: this.reductionFiscale.annee.value,
          trailling: this.reductionFiscale.devise.symbole,
          label: true,
          value: this.reductionFiscale.montant,
        };
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        return this.getRevenueMobileCapitaux();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        return this.getPensions();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        return this.getDeductionFiscales();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        return this.getReductionFiscales();
      default:
        throw new Error("Invalid label key");
    }
  }

  getRevenueMobileCapitaux() {
    if (this.ancienRevenusMobilierCapitauxs?.length && !this.revenusMobilierCapitauxs.length) {
      this.revenusMobilierCapitauxs = cloneDeep(this.ancienRevenusMobilierCapitauxs);
    } else {
      if (this.revenusMobilierCapitauxs.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.revenusMobilierCapitaux);
          item.annee.value += i;
          item.id = i;
          this.revenusMobilierCapitauxs.push(item);
        }
      }
    }
    return this.revenusMobilierCapitauxs;
  }

  getDeductionFiscales() {
    if (this.ancienDeductionFiscales?.length && !this.deductionFiscales.length) {
      this.deductionFiscales = cloneDeep(this.ancienDeductionFiscales);
    } else {
      if (this.deductionFiscales.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.deductionFiscale);
          item.annee.value += i;
          item.id = i;
          this.deductionFiscales.push(item);
        }
      }
    }
    return this.deductionFiscales;
  }

  getReductionFiscales() {
    if (this.ancienReductionFiscales?.length && !this.reductionFiscales.length) {
      this.reductionFiscales = cloneDeep(this.ancienReductionFiscales);
    } else {
      if (this.reductionFiscales.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.reductionFiscale);
          item.annee.value += i;
          item.id = i;
          this.reductionFiscales.push(item);
        }
      }
    }
    return this.reductionFiscales;
  }

  getPensions() {
    if (this.ancienPensions?.length && !this.pensions.length) {
      this.pensions = cloneDeep(this.ancienPensions);
    } else {
      if (this.pensions.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.pension);
          item.annee.value += i;
          item.id = i;
          this.pensions.push(item);
        }
      }
    }
    return this.pensions;
  }

  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
        return "Revenus mobiliers capitaux sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
        return "Pension sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
        return "Déduction fiscale sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
        return "Réduction fiscale sur les 20 prochaines années";
      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
          this.ancienRevenusMobilierCapitauxs = cloneDeep(
            this.revenusMobilierCapitauxs
          );
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
          this.ancienPensions = cloneDeep(this.pensions);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
          this.ancienDeductionFiscales = cloneDeep(this.deductionFiscales);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
          this.ancienReductionFiscales = cloneDeep(this.reductionFiscales);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX:
          if (!this.ancienRevenusMobilierCapitauxs) {
            this.hasAnneeFuturRevenusMobilierCapitaux = false;
          }
          while (this.revenusMobilierCapitauxs.length) {
            this.revenusMobilierCapitauxs.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PENSION:
          if (!this.ancienPensions) {
            this.hasAnneeFuturPension = false;
          }
          while (this.pensions.length) {
            this.pensions.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEDUCTION_FISCALE:
          if (!this.ancienDeductionFiscales) {
            this.hasAnneeFuturDeductionFiscale = false;
          }
          while (this.deductionFiscales.length) {
            this.deductionFiscales.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REDUCTION_FISCALE:
          if (!this.ancienReductionFiscales) {
            this.hasAnneeFuturReductionFiscale = false;
          }
          while (this.reductionFiscales.length) {
            this.reductionFiscales.pop();
          }
          break;
        default:
          break;
      }
    }
  }
}

export { FoyerFiscalAutre };

