class GestionBibliothequeImmobilier {
    key: number | null;
    nom: String | null;
    loi: any | null;
    anneLivraison: number | null;
    surfacefiscale: boolean | null;
    zone: any | null;
    montantTotal: any | null;
    loyerMensuel: any | null;
    fraisGestion: any | null;
    fraisGarantie: any | null;
    chargeCopropriete: any | null;
    montantTaxe : any | null;
    exoTaxe: any | null;
    exoTaxePendant: any | null;
    tauxCharge: any | null;

    constructor(data?: any) {
        this.key = data?.id;
        this.nom = data?.nomProdImoSaisie;
        this.loi = data.loi;
        this.anneLivraison = data?.dateLivraison;
        this.surfacefiscale = data?.surface;
        this.zone = data?.nomZone;
        this.montantTotal = data?.produitFiscalMontant
        this.loyerMensuel = data?.loyerMensuel;
        this.fraisGestion = data?.fraisGestion || null;
        this.fraisGarantie = data?.chargeGarantie;
        this.chargeCopropriete = data?.chargeCopropriete;
        this.montantTaxe = data?.taxeFonciere || null;
        this.exoTaxe = data?.pourcExoneration;
        this.exoTaxePendant = data?.dureeExoneration;
        this.tauxCharge = data?.valueZone;
    }
}

export { GestionBibliothequeImmobilier }