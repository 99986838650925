import React, { Component } from "react";
import { connect } from "react-redux";
import { appActions } from "../../../_actions";

class InputNumber extends Component<any, {}> {

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    if(model.isModalOpen === false){
      model.loading = true;
      replaceModel(model);
      let res = await model.calculTax();
      model.loading = false;
      replaceModel(model);
      if (res) {
        // this.props.replaceModel(model);
        model.situationFiscale.revenu.newCalcule = false;
        this.props.replaceModel(model);
        //console.log("replace");
      }
    }
  }

  render() {
    const { config, onInputChange,  model } = this.props;
    return (
      <div className=" inputs align-items-center py-1 px-1">
        <input
          type="number"
          name={config?.name}
          id={config?.id}
          min={config?.min}
          step="0.01"
          max={config?.max}
          data-key={config?.key}
          data-options={config?.options}
          className="form-control2 flex-1 mr-1"
          style={config?.style}
          onChange={(e) => onInputChange(model, config?.onChange, e)}
          value={config?.value || null}
          // onBlur={(e) => onInputBlur(model, config?.onBlur, e)}
          onBlur={() => this.setCalcutaxe()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};


const connected = connect(mapStateToProps, dispatchToProps)(InputNumber);

export { connected as InputNumber };

