import { LoginAuth } from "./loginAuth";

class Auth {
    loginAuth: LoginAuth;
    
    constructor(){
        this.loginAuth = new LoginAuth();

    }

}
export {Auth}