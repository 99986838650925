class ChoixFinancement {
    pasFinancement: boolean;
    apportPersonnel: number | null;
    apportPersonnelSav: number | null;
    apportPersonnelSavStatus: boolean;
    financementFraisDeductible: boolean;
    financementPremierAnnee: boolean;
    soit: number;

    constructor(
        pasFinancement: boolean = false
    ) {
        this.pasFinancement = pasFinancement;
        this.apportPersonnel = 0;
        this.apportPersonnelSav = 0;
        this.apportPersonnelSavStatus = false;
        this.financementFraisDeductible = true;
        this.financementPremierAnnee = true;
        this.soit = 0;
    }
}
export { ChoixFinancement };
