import { Annee } from "./Annee";

class NombrePartFiscale {
  id: number;
  idkey: number | null;
  valeur: number;
  annee: Annee;

  constructor(data?:any) {
    this.id = data?.id || 0;
    this.idkey = data?.id || null;
    this.valeur = data?.montant || null;
    this.annee = new Annee();
  }
}
 
export { NombrePartFiscale }