import { MontantAvantage } from "../_entities";

class PlafonnementAvantagesFiscaux {
  montantAvantage: MontantAvantage;

  constructor(
    montantAvantage: MontantAvantage = new MontantAvantage()
  ) {
   this.montantAvantage = montantAvantage;
  }
}

export { PlafonnementAvantagesFiscaux }