import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { appActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { utils } from "../../_helpers";
import { CustomInput } from "../common/CustomInput/CustomInput";
import { InputNumber } from "../common/CustomInput/InputNumber";

class Declarant extends Component<any, {}> {
  render() {
    const { declarant, other, model } = this.props;
    const rnt = declarant?.revenuNetImposable?.montant;
    const ben = declarant?.benefice?.montant;
    return (
      <div className="col-lg-6 o-h pt-2">
        <div className="declarant-wrapper px-2">
          <h1 style={{ textAlign: "left" }}>
            <span className="px-3 big-text font-weight-bold">{declarant?.nom}</span>
          </h1>

          <div className="d-flex flex-column justify-content-around">
            <div className="row mt-1 mt-0 pt-0 mb-2 px-2">
              {/* <div className="row px-4 p-2 justify-content-between"> */}
              <div className="col col-lg-5">
                <p className="big-text my-0 px-2">Revenu net imposable</p>
                <p className="text-dark-blue mb-0 small-radio pt-0 px-2">Traitements et salaires</p>
              </div>
              <div className="col col-lg-3 px-0">
                <CustomInput

                  config={utils().getAmountInputConfig(
                    rnt,
                    declarant,
                    appConstants.REVENU_NET_IMPOSABLE,
                    "rni_" + declarant?.id,
                    "rni_" + declarant?.id,
                    {},
                    //  { minWidth: "10 0%", Width: "100%"},
                    { border: declarant.validate ? "1px solid red" : "" },
                  )}
                />
              </div>

              <div className="col col-lg-4 ml-0 pt-2 pl-5 mb-2 custom-control custom-checkbox">

                <input
                  type="checkbox"
                  disabled={rnt === 0 || rnt === null}
                  checked={declarant?.hasAnneFuturRevenuNetImposable}
                  onChange={(e) => this.handleCheckAnneeFutur(e)}
                  className="custom-control-input"
                  id={"checkAnneFutur_RNI_declarant" + declarant?.id}
                />
                <label
                  className="custom-control-label text-dark-blue font-weight-light small-radio "
                  htmlFor={"checkAnneFutur_RNI_declarant" + declarant?.id}
                >
                  Saisir années futures
                  </label>
              </div>

              {/* </div> */}
            </div>


            <div className="d-flex flex-column justify-content-center mb-2">
              <div className="row mt-1 pt-0 ml-2 px-2">
                <div className="custom-control col col-lg-5 custom-radio">
                  <input
                    type="radio"
                    name={"radioAbattementFraisReel_" + declarant?.id}
                    id={"radioAbattement_" + declarant?.id}
                    checked={declarant?.hasAbattement}
                    onChange={(e) => this.handleRadioAbattement(e)}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={"radioAbattement_" + declarant?.id}
                  >
                    Abattement 10%
                  </label>
                </div>

                <div className="custom-control col col-lg-7 pl-3 custom-radio">
                  <input
                    type="radio"
                    checked={declarant?.hasFraisReel}
                    onChange={(e) => this.handleRadioFraisReel(e)}
                    id={"radioFraisReel_" + declarant?.id}
                    name={"radioAbattementFraisReel_" + declarant?.id}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label text-dark-blue font-weight-light"
                    htmlFor={"radioFraisReel_" + declarant?.id}
                  >
                    Frais réels
                  </label>
                </div>
              </div>
              {(other?.hasFraisReel || declarant?.hasFraisReel) && (
                <div className="row pt-3 pb-1 px-3">
                  {declarant?.hasFraisReel && (
                    <div className="col col-lg-5">
                      <p className="big-text mr-0">Montant des frais réels</p>
                    </div>
                  )}

                  {declarant?.hasFraisReel && (
                    <div className="d-flex col col-lg-3 border-light-gray px-0">
                      {/* <div>
                          {
                            (fraisReel1 || fraisReel2) &&
                            <p className="big-text fees-reel py-2 mb-0">Montant des frais réels</p>
                          }
                        
                        </div> */}


                      <InputNumber
                        config={utils().getAmountInputConfig(

                          declarant?.fraisReel?.montant,
                          declarant,
                          appConstants.FRAIS_REEL,
                          "fraisreel_" + declarant?.id,
                          "fraisreel_" + declarant?.id
                        )}
                      />
                      <div className="align-items-end training py-1 text-center">
                        <small className="font-weight-light text-blue">€/an</small>
                      </div>


                    </div>

                  )}
                  {!declarant?.hasFraisReel && (model?.situationFiscale?.revenu?.declarant1?.hasFraisReel || model?.situationFiscale?.revenu?.declarant2?.hasFraisReel) && (
                    <div className="col col-lg-5">
                      <p className="big-text mr-0">&nbsp;</p>
                    </div>
                  )}
                  {!declarant?.hasFraisReel && (model?.situationFiscale?.revenu?.declarant1?.hasFraisReel || model?.situationFiscale?.revenu?.declarant2?.hasFraisReel) && (
                    <div className="d-flex col col-lg-3 w-40 align-items-center px-1 ">
                    </div>
                  )}

                  <div className="col col-lg-4"></div>
                </div>
              )}
            </div>


            {/* <div className="px-2 d-lg-none d-md-block">
                <p className="big-text my-0">Bénéfice</p>
                <p className="text-dark-blue mb-0">BIC / BNC / BA</p>
              </div> */}
            <div className="row pl-0 mt-0 pt-0 px-3">
              <div className="col col-lg-5">
                {/* {
                    (fraisReel1 || fraisReel2) &&
                    <p className="big-text fees-reel py-2 mb-0">Montant des frais réels</p>
                  } */}
                <div>
                  <p className="big-text my-0">Bénéfice</p>
                  <p className="text-dark-blue small-radio pt-0">BIC / BNC / BA</p>
                </div>
              </div>

              <div className="col col-lg-3 d-flex border-light-gray mt-2 px-0">
                <div className=" inputs align-items-center py-1 px-1">
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    className="form-control2 flex-1 mr-1"
                    onChange={(e) => this.setBenefice(e)}
                    onBlur={(e) => this.setCalcutaxe()}
                    value={ben}
                    id={"Benefice_declarant" + declarant?.id}
                  />
                </div>
                <div className="align-items-end training py-1 text-center">
                  <small className="font-weight-light text-blue">€/an</small>
                </div>
                
              </div>
              <div className="col col-lg-4 custom-control custom-checkbox pt-2 pl-5">
                <input
                  type="checkbox"
                  disabled={ben === 0 || ben === null}
                  checked={declarant?.hasAnneFuturBenefice}
                  onChange={(e) => this.handleCheckAnneeFutur(e)}

                  className="custom-control-input"
                  id={"checkAnneFutur_Benefice_declarant" + declarant?.id}
                />
                <label
                  className="custom-control-label text-dark-blue font-weight-light small-radio"
                  htmlFor={
                    "checkAnneFutur_Benefice_declarant" + declarant?.id
                  }
                >
                  Saisir années futures
                  </label>
              </div>
            </div>

            <div className="d-flex pl-3 justify-content-start mt-1 pt-0 mb-2 px-2">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"customCheck1" + declarant?.id}
                  checked = {declarant?.hasAdhesion}
                  onChange={(e) => this.handleCheckCentreGestionAgree(e)}
                />
                <label
                  className="pt-0 custom-control-label adh text-dark-blue font-weight-light small-radio"
                  htmlFor={"customCheck1" + declarant?.id}
                >
                  Adhésion au centre de gestion agréé
                  </label>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      // this.props.replaceModel(model);
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      //console.log("replace");
    }
  }

  async setBenefice(e: React.ChangeEvent<HTMLInputElement>) {
    const { declarant, model } = this.props;
    declarant.setBeneficeMontant(utils().getNumberInputValue(e));
    model.situationFiscale.revenu.newCalcule = true;
    this.props.replaceModel(model);
  }
  

  async handleRadioAbattement(e: React.ChangeEvent<HTMLInputElement>) {
    const { declarant, model } = this.props;
    model.situationFiscale.revenu.newCalcule = true;
    declarant.hasAbattement = e.target.checked;
    declarant.hasFraisReel = false;
    declarant.fraisReel.montant = 0;
    // model.eventUpdateDossier = true;
    this.setCalcutaxe()
    this.props.replaceModel(model);
  }

  async handleCheckCentreGestionAgree(e: React.ChangeEvent<HTMLInputElement>) {
    const { declarant, model } = this.props;
    model.situationFiscale.revenu.newCalcule = true;
    declarant.hasAdhesion = e.target.checked;
    // model.eventUpdateDossier = true;
    this.setCalcutaxe()
    this.props.replaceModel(model);
  }

  async handleRadioFraisReel(e: React.ChangeEvent<HTMLInputElement>) {
    const { declarant, model} = this.props;
    model.situationFiscale.revenu.newCalcule = true;
    declarant.hasAbattement = false;
    //console.log(e.target.checked);
    declarant.hasFraisReel = e.target.checked;
    //console.log('frais reel 2', declarant.hasFraisReel);
    //console.log("has frais", other?.hasFraisReel)
    model.eventUpdateDossier = true;
    this.setCalcutaxe()
    this.props.replaceModel(model);
  }

  async handleCheckAnneeFutur(e: React.ChangeEvent<HTMLInputElement>) {

    const { declarant, model } = this.props;
    const { checked, id } = e.target;
    if (checked) {
      if (
        id === "checkAnneFutur_RNI_declarant1" ||
        id === "checkAnneFutur_RNI_declarant2"
      ) {
        if (declarant?.revenuNetImposable?.montant === 0) {
          swal(
            "Erreur!",
            "Le revenu net imposable du " +
            declarant.nom +
            " doit être supérieur à 0 !",
            "info"
          );
          return;
        }
        declarant.hasAnneFuturRevenuNetImposable = true;
        model.situationFiscale.revenu.newCalcule = true;
        this.props.initAnneFuturRNI(model, declarant);
      } else if (
        id === "checkAnneFutur_Benefice_declarant1" ||
        id === "checkAnneFutur_Benefice_declarant2"
      ) {
        if (declarant?.benefice?.montant === 0) {
          swal(
            "Erreur!",
            "Veullez rengner le bénéfice du " + declarant.nom + ".",
            "info"
          );
          return;
        }
        declarant.hasAnneFuturBenefice = true;
        model.eventUpdateDossier = true;
        // await model.initresultat();
        model.situationFiscale.revenu.newCalcule = true;
        this.props.initAnneFuturBenefice(model, declarant);
        this.props.replaceModel(model);
      } else {
        throw new Error("Unknown declaration type");
      }
    } else {
      if (
        id === "checkAnneFutur_RNI_declarant1" ||
        id === "checkAnneFutur_RNI_declarant2"
      ) {
        if (declarant.hasAnneFuturRevenuNetImposable) {
          model.eventUpdateDossier = true;
          // await model.initresultat();
          model.situationFiscale.revenu.newCalcule = true;
          this.props.initAnneFuturRNI(model, declarant);
          this.props.replaceModel(model);
        }
      } else if (
        id === "checkAnneFutur_Benefice_declarant1" ||
        id === "checkAnneFutur_Benefice_declarant2"
      ) {
        if (declarant.hasAnneFuturBenefice) {
          model.eventUpdateDossier = true;
          // await model.initresultat();

          model.situationFiscale.revenu.newCalcule = true;

          this.props.initAnneFuturBenefice(model, declarant);
          this.props.replaceModel(model);
        }
      } else {
        throw new Error("Unknown declaration type");
      }
    }
  }
}

const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

export default connect(mapStateToProps, dispatchToProps)(Declarant);
