import React, { Component } from 'react';
import "../../../_assets/scss/array.scss";
import { connector, PropsFromRedux } from "../../../_reducers";

class TableBody extends Component<PropsFromRedux, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            dataLength: 0,
            dataMaxLength: 0,
            pages: {
                page: 0,
                size: 13,
            },
        };

    }

    componentDidMount() {
        this.getMaxlength();
        this.getAllData();
    }

    componentWillUnmount() {
        let { model, replaceModel } = this.props;
        model.produitfiscArray = [];
        model.pagesBibliotheque.dataMaxLength = 0;
        model.pagesBibliotheque.page = 0;
        replaceModel(model);
    }

    async getMaxlength() {
        const { model,replaceModel } = this.props;
        let res = await model.getNbProdFiscByPartenaire();
        if (res !== null) {
            model.pagesBibliotheque.dataMaxLength = res;
            replaceModel(model);
        }
    }

    handleScroll(e: any) {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        const { model } = this.props;
        if (scrollHeight - scrollTop === clientHeight) {
            //console.log(model.produitfiscArray.length, " === ", model.pagesBibliotheque.dataMaxLength);
            if (model.produitfiscArray.length < model.pagesBibliotheque.dataMaxLength) {
                this.getAllData();
            }
        }
    }

    async getAllData() {
        const { model, replaceModel } = this.props;
        model.loading = true;
        replaceModel(model);
        const config = {
            page:model.pagesBibliotheque.page,
            size:model.pagesBibliotheque.size
        };
        
        if (model.produitfiscArray.length === 0) {
            let data = await model.getProduitFiscaleByPartenaire(config);
            model.produitfiscArray = data;
            model.loading = false;
            replaceModel(model);
        } else {
            model.pagesBibliotheque.page = model.pagesBibliotheque.page + 1;
            replaceModel(model);
            const config = {
                page:model.pagesBibliotheque.page,
                size:model.pagesBibliotheque.size
            };
            let data = await model.getProduitFiscaleByPartenaire(config);
            for (let dataone of data) {
                model.produitfiscArray.push(dataone);
            }
            model.loading = false;
            replaceModel(model);
        }
    }

    render() {
        const { model } = this.props;
        return (
             <tbody onScroll={(e) => { this.handleScroll(e) }}>
                { (model?.produitfiscArray.length === 0)? 
                    <tr>
                        <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">Aucune donnée</td>
                    </tr> :
                    model?.produitfiscArray?.map((item, i:number) => {
                        //console.log(model?.produitfiscArray);
                        return ( 
                            <tr className="immobillier">
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.nom}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{!item?.amortissable && !item?.inFine ? '-' : item?.amortissable && !item?.inFine ? 'amortissable' : !item?.amortissable && item?.inFine ? 'inFine': '-'}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.duree}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.tauxEmprunt}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.assurance}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.differeCapital ? 'Oui' : 'Non'}</td>
                                <td style={{ fontSize: "11px", width: "150px", verticalAlign: "middle" }}>{item?.differeCapitalMois}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.differeInteret ? 'Oui' : 'Non'}</td>
                                <td style={{ fontSize: "11px", verticalAlign: "middle" }}>{item?.differeInteretMois}</td>
                                <td style={{ fontSize: "11px", width: "120px", verticalAlign: "middle" }}>{item?.txCapitalisation}</td>
                            </tr>
                        )
                    })}
            </tbody>
        );
    }
}
const connected = connector(TableBody);
export { connected as TableBody }