export const FINANCEMENT = {
    AMORTISSEMENT: "AMORTISSEMENT",
    INFINE: "INFINE",
    PASFINANCEMENT: "PASFINANCEMENT",
    APPORTPERSONNEL: "APPORTPERSONNEL",
    FINANCEMENTFRAISDEDUCTIBLE: "FINANCEMENTFRAISDEDUCTIBLE",
    FINANCEMENTPREMIEREANNEE: "FINANCEMENTPREMIEREANNEE",
    FINANCEMENTRAVAUX: "FINANCEMENTRAVAUX",

    MONTANTFINACEMENT: "MONTANTFINACEMENT",
    DUREEFINANCEMENT: "DUREEFINANCEMENT",
    TAUXINTERET: "TAUXINTERET",
    ASSURANCEDECEINVALIDE: "ASSURANCEDECEINVALIDE",
    DIFFEREREMBOURSEMENTCAPITALOUI: "DIFFEREREMBOURSEMENTCAPITALOUI",
    DIFFEREREMBOURSEMENTCAPITALNON: "DIFFEREREMBOURSEMENTCAPITALNON",
    DIFFEREREMBOURSEMENTCAPITALMOIS: "DIFFEREREMBOURSEMENTCAPITALMOIS",
    DIFFEREREMBOURSEMENTINTERETOUI: "DIFFEREREMBOURSEMENTINTERETOUI",
    DIFFEREREMBOURSEMENTINTERETNON: "DIFFEREREMBOURSEMENTINTERETNON",
    DIFFEREREMBOURSEMENTINTERETMOIS: "DIFFEREREMBOURSEMENTINTERETMOIS",
    MENSUALITETOTALCREDIT: "MENSUALITETOTALCREDIT",

    NOMPRODUIT: "NOMPRODUIT",
    DUREEPRODUIT: "DUREEPRODUIT",
    TAUXCAPITALISATION: "TAUXCAPITALISATION",
    TAUXFRAISENTREE: "TAUXFRAISENTREE",
    TAUXFRAISVERSEMENT: "TAUXFRAISVERSEMENT",
    RACHATECHELONNE: "RACHATECHELONNE",
    AXATION: "AXATION",
    COTISATION: "COTISATION",
    CAPITALISATINTVA: "CAPITALISATINTVA",

    VERSEMENT_INITIAL_MENSUALITE: "VERSEMENT_INITIAL_MENSUALITE",
    CAPITAL_TERME_MENSUALITE: "CAPITAL_TERME_MENSUALITE",
    VERSEMENT_MENSUEL_MENSUALITE: "VERSEMENT_MENSUEL_MENSUALITE",

    VERSEMENT_INITIAL_INITIAL: "VERSEMENT_INITIAL_INITIAL",
    CAPITAL_TERME_INITIAL: "CAPITAL_TERME_INITIAL",
    VERSEMENT_MENSUEL_INITIAL: "VERSEMENT_MENSUEL_INITIAL",

    VERSEMENT_INITIAL_TERME: "VERSEMENT_INITIAL_TERME",
    CAPITAL_TERME_TERME: "CAPITAL_TERME_TERME",
    VERSEMENT_MENSUEL_TERME: "VERSEMENT_MENSUEL_TERME",
    MONTANT_INTERET_DIFFERE: "MONTANT_INTERET_DIFFERE",

    POURCENTAGE: "POURCENTAGE",
    EURO: "EURO",
    ANNEE: "ANNEE",
    MOIS: "MOIS",
    SOIT: "SOIT",
    RELAIS_TVA: "RELAIS_TVA",
}