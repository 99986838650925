import React, { Component } from "react";
import { connect } from "react-redux";
import { appActions } from "../../../_actions";
import { CustomInput } from "../CustomInput/CustomInput";
import { utils } from "../../../_helpers";
import { appConstants } from "../../../_constants";
import { Loader } from "../Loader/Loader"

class AnneesFutursBenefices extends Component<any, {}> {
  render() {
    const { modal } = this.props.model;
    const {
      montant,
      annee,
      devise,
    } = modal?.data?.declarant?.benefice;
    const labelConfig = {
      leading: annee?.value,
      trailling: devise.symbole,
      label: true,
      value: montant,
    };

    return (
      <div className="w-100 d-flex row m-0">
        <Loader />
        <div className="col-lg-6">
          <div className="col-lg-12">
            <div className="d-flex px-0"> 
              <div className="col-lg-7">
                <CustomInput config={labelConfig} />
              </div>
              <div className="col-lg-5 p-0 d-flex justify-content-center">
                <small>Revalorisation annuelle</small>
              </div>
            </div>
          </div>
          {this.getAnneeFuturGroup(0, 10)}
        </div>
        <div className="col-lg-6">
          <div className="col-lg-12">
            <div className="d-flex px-0">
              <div className="col-lg-7"></div>
              <div className="col-lg-5 p-0 d-flex justify-content-center d-xm-none d-sm-none">
                <small>Revalorisation annuelle</small>
              </div>
            </div>
          </div>
          {this.getAnneeFuturGroup(10, 20)}
        </div>
      </div>
    );
  }

  getAnneeFuturGroup(offset: number, limit: number): React.ReactNode {
    const { modal } = this.props.model;
    const { benefices } = modal?.data?.declarant;
    return benefices
      .slice(offset, limit)
      .map((item: any, i: number) => {
        const options1 = {
          type: 'year',
          id: item.id 
        }
        const options2 = {
          type: 'revalorisation',
          id: item.id 
        }
        return (
          <div className="col-lg-12" key={i}>
            <div className="d-flex px-0">
              <div className="col-lg-7 mt-2">
                <CustomInput
                  config={utils().getAnneeFuturInputConfig(
                    item.annee.value,
                    item.montant,
                    modal?.data?.declarant,
                    appConstants.ANNEE_FUTURE_BENEFICE,
                    'rni_annee_item'+item.id,
                    'rni_annee_item'+item.id,
                    modal?.data?.declarant,
                    options1
                  )}
                />
              </div>
              <div className="col-lg-5 mt-2">
                <CustomInput
                  config={utils().getAnneeFuturInput2Config(
                    item.revalorisationAnnuelle,
                    modal?.data?.declarant,
                    appConstants.ANNEE_FUTURE_BENEFICE,
                    'rni_annee_item_percent'+item.id,
                    'rni_annee_item_percent'+item.id,
                    modal?.data?.declarant,
                    options2
                  )}
                />
              </div>
            </div>
          </div>
        );
      });
  }
}
const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

const connected = connect(
  mapStateToProps,
  dispatchToProps
)(AnneesFutursBenefices);

export { connected as AnneesFutursBenefices };
