class Annee {
  id: number;
  value: number;

  constructor(
    id: number = 0,
    value: number = new Date().getFullYear()
  ) {
    this.id = id;
    this.value = value;
  }
}

export { Annee }