import { FoyerFiscalResultatFoncier } from "../_entities";

class ResultatFoncier {
  foyerFiscalResultatFoncier: FoyerFiscalResultatFoncier;

  constructor(
    foyerFiscalResultatFoncier: FoyerFiscalResultatFoncier = new FoyerFiscalResultatFoncier()
  ) {
    this.foyerFiscalResultatFoncier = foyerFiscalResultatFoncier;
  }
}

export { ResultatFoncier }