import React, { Component } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCalculator, FaHome, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";
import { history } from "../../_helpers";
import { connector, PropsFromRedux } from "../../_reducers";
import { Autre } from "../Autre/Autre";
import { Clients } from "../client/clients";
import { Dialog } from "../common/Dialog/Dialog";
import { DeclarantGroup } from "../DeclarantGroup/DeclarantGroup";
import { DetailsIFI } from "../DetailsIFI/DetailsIFI";
import { Endettement } from "../Endettement/Endettement";
import { FoyerFiscale } from "../FoyerFiscal/FoyerFiscale";
import { Dossier } from "../client/dossier/dossier";


const solutionfiscalebc = require("../../_assets/images/optionIcons/solutionFiscale.png");

class DashboardContent extends Component<PropsFromRedux, any> {
  constructor(props: any) {
    super(props);
    let today = new Date();
    let currentDate = today.getTime().toString();
    this.state = {
      currentDate:currentDate,
      showModal: false,
      showOk: true,
      showOuiNon: false,
      showNon: false,
      conditionnalValide: false,
      nav: false,
      route: "",
      message: "",
      title: "Easy Defisc",
    }
    
  }

  componentDidMount() {
    this.openClient();
  }

  render() {
    const { model } = this.props;
    const { activeSection, revenu, quotientFamilial } = model.situationFiscale;
    const nomClient = model?.situationFiscale?.client?.dossier?.nom;
    return (
      <div id="dashboard-content-wrapper" className="mx-2">
        <div id="dynamic-content" className="w-100 mx-0">
          {(!activeSection || activeSection === "revenus") && (
            <DeclarantGroup />
          )}
          {activeSection === "resultats" && <FoyerFiscale />}
          {activeSection === "autres" && <Autre />}
          {activeSection === "endettements" && <Endettement />}
          {activeSection === "details" && <DetailsIFI />}
          {activeSection === "client" && <Clients />}
        </div>

        <div className="container-fluid">
          <div className="row px-2 justify-content-between ">

            <div className="ml-0 btn-footer-box col-1 col-xs-1 col-sm-3 col-md-3 col-lg-2 px-0">
              {(activeSection !== "revenus") ?
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Précédent </Tooltip>
                  }>
                  <div
                    className="bg-orange rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={(e) => this.handleBtnClick(e, "prev")}
                  >
                    <FaRegArrowAltCircleLeft size="20" />
                    <span className=" ml-1" style={{ cursor: "pointer" }}> précédent </span>
                  </div>
                </OverlayTrigger>
                :
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Accueil </Tooltip>
                  }>
                  <div
                    className="bg-orange rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={(e) => { model.isUpdateModel = false; model.reinitialiseBaseModel(); history.replace("/accueil") }}
                  >
                    <FaHome size="20" />
                    <span className=" ml-1" style={{ cursor: "pointer" }}> Accueil </span>
                  </div>
                </OverlayTrigger>
              }
            </div>
            {/* {(model.isUpdateModel || nomClient !== "") && (
              <div className="btn-footer-box col-1 col-1 col-xs-1 col-sm-5 col-md-3 col-lg-2 px-0">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Solution Fiscale </Tooltip>
                  }>
                  <div
                    className="bg-primary rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={() => (revenu.newCalcule || quotientFamilial.newCalcule) ? this.launchModele() : this.handleNavigate()}
                  >
                    <img src={solutionfiscalebc} alt="solution fiscale" style={{ maxWidth: "20px", height: "20px", color: "white" }} />
                    <span className=" ml-1" style={{ cursor: "pointer" }}> Solution Fiscale </span>
                  </div>
                </OverlayTrigger>
              </div>
            )} */}

            {(!model.isUpdateModel  && nomClient === "") && (
              <div className="btn-footer-box col-1 col-1 col-xs-1 col-sm-5 col-md-3 col-lg-2 px-0">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Saisie Client </Tooltip>
                  }>
                  <div
                    className="bg-primary rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={(e) => this.handleBtnClick(e, "client")}

                  >
                    <img src={solutionfiscalebc} alt="solution " style={{ maxWidth: "20px", height: "20px", color: "white" }} />
                    <span className=" ml-1" style={{ cursor: "pointer" }}> Saisie Client </span>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            {/* {nomClient !== "" && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top"> Solution Fiscale </Tooltip>
                }>
                <div
                  className="bg-primary rounded text-white d-flex justify-content-center p-2 my-2"
                  onClick={() => this.handleNavigate()}
                >
                  <img src={solutionfiscalebc} alt="solution fiscale" style={{ maxWidth: "20px", height: "20px", color: "white" }} />
                  <span className=" ml-1" style={{ cursor: "pointer" }}> Solution Fiscale </span>
                </div>
              </OverlayTrigger>
            )

            } */}
            {/* {
              (nomClient != null && nomClient !== "" && model.isUpdateModel) && (
                <div className="btn-footer-box col-1 col-1 col-xs-1 col-sm-5 col-md-3 col-lg-2 px-0">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top"> Synthèse Fiscale </Tooltip>
                    }>
                    <div
                      className="bg-primary rounded text-white d-flex justify-content-center p-2 my-2"
                      onClick={(e) => this.handleBtnClick(e, "synthese")}

                    >
                      <img src={visual} alt="situaltion fiscale" style={{ maxWidth: "20px", color: "white" }} />
                      <span className=" ml-1" style={{ cursor: "pointer" }}> Synthèse Fiscale </span>
                    </div>
                  </OverlayTrigger>
                </div>
              )
            } */}


            <div className="mr-0 btn-footer-box col-1 col-xs-1  col-sm-3 col-md-3 col-lg-2 px-0">
              {(!revenu.newCalcule && !quotientFamilial.newCalcule) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Suivant </Tooltip>
                  }>
                  <div
                    className="bg-orange rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={(e) => this.handleBtnClick(e, "next")}
                  >
                    <span className=" mr-1" style={{ cursor: "pointer" }}> Suivant </span>
                    <FaRegArrowAltCircleRight size="20" />
                  </div>
                </OverlayTrigger>
              )}
              {activeSection !== "client" && (revenu.newCalcule || quotientFamilial.newCalcule) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top"> Calcul Impôt </Tooltip>
                  }>
                  <div
                    className="bg-orange rounded text-white d-flex justify-content-center p-2 my-2"
                    onClick={(e) => this.calculTax()}
                  >
                    <span className=" mr-1" style={{ cursor: "pointer" }}> Calcul Impôt </span>
                    <FaCalculator size="20" />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>

        {this.getModal()}
      </div>
    );
  }

  openClient(){
    let dossierId = localStorage.getItem("keyupdate");
    if(dossierId === null){
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        title: "Renseignez votre dossier client.",
        message: this.dossieClient()
      });
    }
  }

  dossieClient(){
    return(
      <div className="declarant-wrapper1 px-2 pb-2 mx-2">
          <h1 style={{ textAlign: "left" }}>
              <span className="px-3 big-text font-weight-bold">Dossier</span>
          </h1>
          <Dossier />
      </div>
    );
  }

  getModal(): React.ReactNode {
    const { showModal, message, title } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={() => this.closeModal("", false, false)}
        backdrop="true"
      // size="md"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{message}</Modal.Body>
        <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
      </Modal>
    );
  }

  launchModele() {
    this.setState({
      showModal: true,
      showOk: true,
      showOuiNon: false,
      route: "",
      message: "Veuillez effectuer le calcul d'impôt !"
    });
  }

  getFooter() {
    const { showOuiNon, showOk, nav, route, showNon, conditionnalValide } = this.state;
    return (
      <div
        className={
          "w-100 d-flex " +
          (!showOk ? "justify-content-between" : "justify-content-end")
        }
      >
        {showOuiNon && (
          <button
            className="btn font-weight-light text-white bg-orange "
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal(route, false, false)}
          >
            Non
          </button>
        )}
        {showOuiNon && !nav && <button
          className="btn font-weight-light text-white bg-orange "
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, true, false)}
        >
          Oui
        </button>}
        {showOuiNon && nav && <button
          className="btn font-weight-light text-white bg-orange "
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, true, true)}
        >
          Oui
        </button>}
        {showOk && <button
          className="btn font-weight-light text-white bg-orange "
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(route, false, false)}
        >
          Ok
        </button>}
        {showNon && (
          <button
            className="btn font-weight-light text-white bg-orange "
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal(route, false, false, true, false)}
          >
            Non
          </button>
        )}
        {conditionnalValide && (
          <button
            className="btn font-weight-light text-white bg-orange "
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal(route, false, false, false, true)}
          >
            Oui
          </button>
        )}
      </div>
    );
  }

  closeModal(data: string, oui?: boolean, ok?: boolean, shownon?: boolean, showoui?: boolean) {
    this.setState({
      showModal: false,
      showOk: true,
      showOuiNon: false,
      nav: false,
      route: "",
      message: "",
      title: "Easy Defisc",
    });
    if (oui && !ok) {
      const { model, changeSection } = this.props;
      const section = model.situationFiscale.getNextSection();
      window.scrollTo(0, 0)
      changeSection(model, section);
      model.situationFiscale.revenu.newCalcule = false;
    }
    if (oui && ok) {
      history.push(data);
    }
    if (showoui) {
      this.navigueToShowOui("resultats");
    }
    if (shownon) {
      if (this.checkFolderDefisc(true)) {
        history.push(`/synthese-fiscal/${this.state.currentDate}`);
      }
    }
  }

  async handleNavigate() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    const { client } = model.situationFiscale;
    // model.situationFiscale.saveSituationFiscal();
    if (client.dossier.nom === "") {
      client.dossier.isNom = false;
      model.loading = false;
      replaceModel(model);
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, le nom dans dossier"
      });
    } else {
      let res = (model.isUpdateModel === false) ? model.addDossier() : (model.eventUpdateDossier) ? model.updateDossier() : true;
      if (await res === true) {
        model.loading = false;
        let pages: any = {
          page: 0,
          size: 10,
        };
        model.accueilArray = [];
        model.getDossierByPartenaire(pages);
        model.eventUpdateDossier = false;
        if (model.isUpdateModel === false) {
          model.isUpdateModel = true;
        }
        model.solutionFiscale.activeSection = "fiscalites";
        const fiscalite = model.solutionFiscale.currentFiscalite;
        model.solutionFiscale.typeFiscaliteModel.checkActiveFiscalite(fiscalite);
        replaceModel(model);
        history.push("/solution-fiscal");
      } else {
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Une erreur s'est produite lors de la creation du dossier"
        });
      }
      //}
    }


  }

  async calculTax() {
    const { model, replaceModel } = this.props;
    const { revenu } = model.situationFiscale;
    const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;
    const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;
    const benefBic = revenu.declarant1.benefice.montant;
    model.loading = true;
    replaceModel(model);

    if ((revenuNet1 === 0 || revenuNet1 === null) && (benefBic === 0 || benefBic === null)) {
      revenu.declarant1.validate = true;
      model.loading = false;
      replaceModel(model);
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, un revenu net imposable"
      });
    } else
      if (part <= 0 || part == null) {
        model.situationFiscale.quotientFamilial.validate = true;
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Attention vous n'avez pas saisie, le nombre de parts fiscales."
        });
      } else {
        let res = model.calculTax();

        if (await res === true) {
          //console.log(res);
          model.loading = false;
          replaceModel(model);

        } else {
          model.loading = false;
          replaceModel(model);
          return this.setState({
            showModal: true,
            showOk: true,
            showOuiNon: false,
            route: "",
            message: "Une erreur s'est produite lors du calcul des taxes"
          });
        }
      }
  }

  async handleBtnClick(
    e: any,
    key: string
  ) {
    window.scrollTo(0, 0);
    const { model, changeSection, replaceModel } = this.props;
    const { revenu } = model.situationFiscale;
    const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;
    const benefBic = revenu.declarant1.benefice.montant;
    const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;
    const currentSection = model.situationFiscale.activeSection;

    if ((revenuNet1 === 0 || revenuNet1 === null) && (benefBic === 0 || benefBic === null) ) {
      revenu.declarant1.validate = true;
      model.loading = false;
      replaceModel(model);
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, un revenu net imposable"
      });
    } else
      if (part <= 0 || part == null) {
        model.situationFiscale.quotientFamilial.validate = true;
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Attention vous n'avez pas saisie, le nombre de parts fiscales."
        });
      } else {

        if (key === "prev" || key === "next" || key === "synthese") {
          let section = model.situationFiscale.activeSection;
          model.loading = true;
          replaceModel(model);

          if (key === "prev") {
            section = model.situationFiscale.getPrevSection();
          }

          if (key === "next" && section !== "client") {
            section = model.situationFiscale.getNextSection();
          }

          if (key === "next" || key === "prev" || key === "synthese") {
            if (model.isUpdateModel && model.id !== null /*&& model.situationFiscale.activeSection !== "revenus"*/) {
              let res = await model.updateDossier();
              if (res) {
                model.eventUpdateDossier = false;
                model.loading = false;
                replaceModel(model);
              }
            } else if (model.eventUpdateDossier && model.id == null/*&& model.situationFiscale.activeSection !== "revenus"*/) {
              let res = model.calculTax();

              if (await res === true) {
                //console.log(res);
                model.eventUpdateDossier = false;
                model.loading = false;
                replaceModel(model);
              } else {
                model.loading = false;
                replaceModel(model);
                return this.setState({
                  showModal: true,
                  showOk: true,
                  showOuiNon: false,
                  route: "",
                  message: "Une erreur s'est produite lors du calcul des impôts"
                });
              }
            } else {
              model.eventUpdateDossier = false;
              model.loading = false;
              replaceModel(model);
            }
            //section = model.situationFiscale.getNextSection();
          }
          if (section !== currentSection || key === "synthese") {
            if (key !== "synthese") {
              changeSection(model, section);
            }
            else {
              let res = (model.isUpdateModel === false) ? model.addDossier() : (model.eventUpdateDossier) ? model.updateDossier() : true;
              if (await res === true) {
                model.loading = false;
                let pages: any = {
                  page: 0,
                  size: 10,
                };
                model.accueilArray = [];
                model.getDossierByPartenaire(pages);
                model.eventUpdateDossier = false;
                if (model.isUpdateModel === false) {
                  model.isUpdateModel = true;
                }
                model.solutionFiscale.activeSection = "fiscalites";
                const fiscalite = model.solutionFiscale.currentFiscalite;
                model.solutionFiscale.typeFiscaliteModel.checkActiveFiscalite(fiscalite);
                replaceModel(model);
                this.goToSynthese();
              } else {
                model.loading = false;
                replaceModel(model);
                return this.setState({
                  showModal: true,
                  showOk: true,
                  showOuiNon: false,
                  route: "",
                  message: "Une erreur s'est produite lors de la creation du dossier"
                });
              }
              
            }
          }
          else {
            this.handleNavigate();
          }
        }
        else if (key === "client") {
          // const { activeSection } = model.situationFiscale;
          const { revenu, quotientFamilial } = model.situationFiscale;
          const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;
          const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;

          model.calculTax();
          model.eventUpdateDossier = false;

          if (revenuNet1 === 0 || revenuNet1 === null) {
            revenu.declarant1.validate = true;
            model.loading = false;
            replaceModel(model);
            return this.setState({
              showModal: true,
              showOk: true,
              showOuiNon: false,
              route: "",
              message: "Attention vous n'avez pas saisie, un revenu net imposable"
            });
          }
          if (part <= 0 || part === null) {
            model.situationFiscale.quotientFamilial.validate = true;
            replaceModel(model);
            return this.setState({
              showModal: true,
              message: "Attention vous n'avez pas saisie le nombre de parts fiscales !"
            });
          }
          if (revenu.newCalcule || quotientFamilial.newCalcule) {
            return this.setState({
              showModal: true,
              showOk: true,
              showOuiNon: false,
              lastIndex: "",
              message: "Vos données ont été modifiées. Les impôts doivent être recalculés !"
            });
          }
          changeSection(model, key);
        }
      }
  }

  async goToSynthese() {
    const { model, replaceModel } = this.props;
    model.loading = true;


    if (this.checkFolderDefisc()) {
      let id = localStorage.getItem("keyupdate");
      if(id !== null){
        await model.getDataOneDossier(id);
      }
      let res2 = await model.getMontageFinancier(id);
      replaceModel(model);
      if (res2 === true) {
        model.loading = false;
        replaceModel(model);
        await history.push(`/synthese-fiscal/${this.state.currentDate}`);
      } else {
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          message: "Une erreur s'est produite lors de la création de la synthèse"
        });
      }
    }
  }
  // navigation page precedent et suivant 

  checkFolderDefisc(flag?: boolean) {
    let statutOk = true;
    const { model, replaceModel } = this.props;

    const { revenu } = model.situationFiscale;
    const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;
    const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;

    // Verification Situation Fiscale
    if (revenuNet1 === 0 || revenuNet1 === null) {
      revenu.declarant1.validate = true;

      model.loading = false;
      replaceModel(model);
      statutOk = false;
      //history.push("/solution-fiscal");
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, un revenu net imposable"
      });
    } else if (part <= 0 || part == null) {
      model.situationFiscale.quotientFamilial.validate = true;
      model.loading = false;
      replaceModel(model);
      statutOk = false;
      //history.push("/solution-fiscal");
      return this.setState({
        showModal: true,
        showOk: true,
        showOuiNon: false,
        route: "",
        message: "Attention vous n'avez pas saisie, le nombre de parts fiscales."
      });
    }

    if (null == model?.solutionFiscale?.currentFiscalite || "-" === model?.solutionFiscale?.currentFiscalite) {
      statutOk = false;
      this.showMsgErr("Veuillez choisir un dispositif fiscal dans votre dossier fiscal.", "fiscalites");
      return;
    }


    //activeSection === "resultats"
    // SUPPORT IMMOBILIER
    let scpi = this.getBoolean(model?.solutionFiscale?.scpi);
    let errDate = false;
    let errExonerea = false;
    let errExonerependant = false;
    let errMontant = false;
    let errMontantTaxe = false;
    let errRevenueLocatifMenssuel = false;
    let errRevenueLocatifPourcent = false;
    let errTaux = false;
    if (!scpi) {
      model?.solutionFiscale?.support?.getActiveAjoutSupportImmobilier();
      errDate = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier?.errDate);
      errExonerea = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errExonerea);
      errExonerependant = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errExonerependant);
      errMontant = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errMontant);
      errMontantTaxe = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errMontantTaxe);
      errRevenueLocatifMenssuel = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errRevenueLocatifMenssuel);
      errRevenueLocatifPourcent = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errRevenueLocatifPourcent);
      errTaux = this.getBoolean(model?.solutionFiscale?.support?.supportImmobilier.errTaux);
    }
    else {
      model?.solutionFiscale?.support?.getActiveAjoutSupportSCPI();
      errDate = this.getBoolean(model?.solutionFiscale?.support?.supportSCPI?.errDate);
      errMontant = this.getBoolean(model?.solutionFiscale?.support?.supportSCPI.errMontant);
      errRevenueLocatifMenssuel = this.getBoolean(model?.solutionFiscale?.support?.supportSCPI.errRevenueLocatifMenssuel);
      errRevenueLocatifPourcent = this.getBoolean(model?.solutionFiscale?.support?.supportSCPI.errRevenueLocatifPourcent);
      errTaux = this.getBoolean(model?.solutionFiscale?.support?.supportSCPI.errTaux);
    }

    let resultatSection = "resultats";
    if (scpi) {
      resultatSection = "support scpi";
    }

    if (!scpi) {
      if (errDate && errExonerea && errExonerependant && errMontant && errMontantTaxe && errRevenueLocatifMenssuel && errRevenueLocatifPourcent) {
        statutOk = false;
        this.showMsgErr("Veuillez remplir un support immobilier dans votre dossier fiscal.", resultatSection);
        return;
      }
    }
    else {
      if (errDate && errMontant && errRevenueLocatifMenssuel && errRevenueLocatifPourcent && errTaux) {
        statutOk = false;
        this.showMsgErr("Veuillez remplir un support immobilier dans votre dossier fiscal.", resultatSection);
        return;
      }
    }

    if (errDate) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir une date de livraison relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (errMontant) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le montant total du bien relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (errTaux) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le taux de revalorisation du bien relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (errRevenueLocatifMenssuel) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le montant du loyer relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (errRevenueLocatifPourcent) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le taux de revalorisation du loyer relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (!scpi && errMontantTaxe) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le montant de la taxe foncière relatif à votre support immobilier.", resultatSection);
      return;
    }

    if (!scpi && errExonerependant) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir la durée d'exonération de la taxe foncière relatif à votre support immobilier.", resultatSection);
      return;
    }
    if (!scpi && errExonerea) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir le taux d'exonération de la taxe foncièr relatif à votre support immobilier.", resultatSection);
      return;
    }


    if (!scpi) {
      switch (model?.solutionFiscale?.currentFiscalite) {
        case model?.solutionFiscale?.typeFiscaliteModel?.pinel?.loi:
        case model?.solutionFiscale?.typeFiscaliteModel?.denormandie?.loi:
        case model?.solutionFiscale?.typeFiscaliteModel?.giradin?.loi:
        case model?.solutionFiscale?.typeFiscaliteModel?.duflot?.loi:
        case model?.solutionFiscale?.typeFiscaliteModel?.scellier?.loi:
        case model?.solutionFiscale?.typeFiscaliteModel?.scellierInterm?.loi:
        case "BORLOO_POPULAIRE":
        case "ROBIEN_EN_ZRR":
        case "ROBIEN_RECENTRE":

          const loyer = model?.solutionFiscale?.support?.revenuLocatifImmobillier?.montantRevenueLocatif?.montant;
          const loyerMax = model?.solutionFiscale?.support?.supportImmobilier?.choixImmobilier?.loyer_maxi;
          if (flag !== true) {
            if (null != loyer && null != loyerMax && loyer > loyerMax) {
              this.props.model.solutionFiscale.support.isAdd = true;
              model.loading = false;
              replaceModel(model);
              statutOk = false;
              //history.push("/solution-fiscal");
              return this.setState({
                showModal: true,
                size: "lg",
                data: <Dialog />,
                showOk: false,
                conditionnalValide: true,
                showNon: true,
                title: "Easy Defisc",
                message: "Le plafond légal du loyer semble être dépassé. Voulez-vous modifier le loyer ?",
              });
            }
          }
          break;
      }
    }

    if (!scpi && (model?.solutionFiscale?.currentFiscalite === model?.solutionFiscale?.typeFiscaliteModel?.giradin.loi)) {
      let surface = 0;
      if (scpi) {
        surface = model?.solutionFiscale?.support?.supportSCPI?.choixImmobilier?.surface_totale | 0;
      }
      else {
        surface = model?.solutionFiscale?.support?.supportImmobilier?.choixImmobilier?.surface_totale | 0;
      }

      if (surface <= 0 || surface == null) {
        statutOk = false;
        this.showMsgErr("Vous devez saisir la surface associée à votre support immobilier.", resultatSection);
        return;
      }
    }

    // MONO FINANCEMENT
    let financement = model?.solutionFiscale?.financement;

    if (model?.solutionFiscale?.mono_financement_mode && !financement?.monofinancement.choix_financement.pasFinancement) {
      let financement = model?.solutionFiscale?.financement;
      financement?.getActiveAddMonoFinancement();

      if (financement.monofinancement.errMontantFinencent && financement.monofinancement.errdureefinancement && financement.monofinancement.errdureefinancement && financement.monofinancement.errassuranceDece) {
        statutOk = false;
        this.showMsgErr("Veuillez remplir un financement dans votre dossier fiscal.", "mono");
        return;
      }
      if (financement.monofinancement.errMontantFinencent) {
        statutOk = false;
        this.showMsgErr("Le montant du financement n'est pas renseigné.", "mono");
        return;
      }

      if (financement.monofinancement.errdureefinancement) {
        statutOk = false;
        this.showMsgErr("Vous devez saisir la durée du financement.", "mono");
        return;
      }

      if (financement.monofinancement.errtauxInteret) {
        statutOk = false;
        this.showMsgErr("Vous devez saisir le taux d'intérêt du financement.", "mono");
        return;
      }

      if (financement.monofinancement.errassuranceDece) {
        statutOk = false;
        this.showMsgErr("Vous devez saisir le montant de l'ADI.", "mono");
        return;
      }


      if (financement.monofinancement.caracteristique_financement.inFine) {
        if (financement.monofinancement.errtauxCapitalisation) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux de capitalisation du produit d'adossement.", "mono");
          return;
        }

        if (this.isNull(financement.monofinancement.trouve_mensualite.versement_mensuel)
          && this.isNull(financement.monofinancement.apport_initial.versement_initial)
          && this.isNull(financement.monofinancement.capital_terme.capitale_aterme)
        ) {
          statutOk = false;
          this.showMsgErr("Vous oubliez de saisir des informations dans le module de calcul In Fine.", "multi");
          return;
        }
      }
    }
    else if (!model?.solutionFiscale?.mono_financement_mode && !model?.solutionFiscale?.financement?.pasFinancement) {
      financement?.getActiveMultiFinancement();

      if (financement.errassuranceDece) {
        statutOk = false;
        this.showMsgErr("Vous devez saisir le montant de l'ADI.", "multi");
        return;
      }

      // Ammortissement 1
      if (financement.checkAmmort1) {

        if (financement.amortissement1.errMontantFinencent && financement.amortissement1.errdureefinancement && financement.amortissement1.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Veillez saisir les données relatives au financement n°1. Si vous ne voulez pas de ce financement, désélectionnez ce financement.", "multi");
          return;
        }

        if (financement.amortissement1.errMontantFinencent) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le montant du financement n°1. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement1.errdureefinancement) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir la durée du financement n°1. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement1.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux d'intérêt du financement n°1. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }
      }


      // Ammortissement 2
      if (financement.checkAmmort2) {
        if (financement.amortissement2.errMontantFinencent && financement.amortissement2.errdureefinancement && financement.amortissement2.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Veillez saisir les données relatives au financement n°2. Si vous ne voulez pas de ce financement, désélectionnez ce financement.", "multi");
          return;
        }
        if (financement.amortissement2.errMontantFinencent) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le montant du financement n°2. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement2.errdureefinancement) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir la durée du financement n°2. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement2.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux d'intérêt du financement n°2. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }
      }
      // Ammortissement 3
      if (financement.checkAmmort3) {
        if (financement.amortissement3.errMontantFinencent && financement.amortissement3.errdureefinancement && financement.amortissement3.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Veillez saisir les données relatives au financement n°3. Si vous ne voulez pas de ce financement, désélectionnez ce financement.", "multi");
          return;
        }
        if (financement.amortissement3.errMontantFinencent) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le montant du financement n°3. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement3.errdureefinancement) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir la durée du financement n°3. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.amortissement3.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux d'intérêt du financement n°3. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }
      }

      // inFine 4
      if (financement.checkinfine) {
        if (financement.inFine.errMontantFinencent && financement.inFine.errdureefinancement && financement.inFine.errtauxInteret && financement.inFine.errtauxCapitalisation) {
          statutOk = false;
          this.showMsgErr("Veillez saisir les données relatives au financement n°4. Si vous ne voulez pas de ce financement, désélectionnez ce financement.", "multi");
          return;
        }
        if (financement.inFine.errMontantFinencent) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le montant du financement n°4. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.inFine.errdureefinancement) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir la durée du financement n°4. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }

        if (financement.inFine.errtauxInteret) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux d'intérêt du financement n°4. Si vous ne voulez pas de ce financement, supprimer les champs remplis.", "multi");
          return;
        }


        if (financement.inFine.errtauxCapitalisation) {
          statutOk = false;
          this.showMsgErr("Vous devez saisir le taux de capitalisation du produit d'adossement associé à votre financement n°4.", "multi");
          return;
        }

        if (this.isNull(financement.inFine.trouve_mensualite.versement_mensuel)
          && this.isNull(financement.inFine.apport_initial.versement_initial)
          && this.isNull(financement.inFine.capital_terme.capitale_aterme)
        ) {
          statutOk = false;
          this.showMsgErr("Vous oubliez de saisir des informations dans le module de calcul In Fine associé à votre financement n°4.", "multi");
          return;
        }
      }
    }
    // Placement TVA
    const tvaLmnp = model.solutionFiscale.typeFiscaliteModel.lmnp.tva_oui;
    const tvaLmp = model.solutionFiscale.typeFiscaliteModel.lmp.tva_oui;
    const tvaMonument = model.solutionFiscale.typeFiscaliteModel.monument.tva_oui;
    const tvaRegime = model.solutionFiscale.typeFiscaliteModel.regime.tva_oui;
    const typeFiscalite = model.solutionFiscale.currentFiscalite;

    let valid = (model.solutionFiscale.placement.capitalisationExedMulti && model.solutionFiscale.placement.getValidPlacement()) || !model.solutionFiscale.placement.capitalisationExedMulti;
    let validTva = (
      model.solutionFiscale.financement.isinfine
        && (
          (typeFiscalite === "LMNP" && tvaLmnp) ||
          (typeFiscalite === "LMP" && tvaLmp) ||
          (typeFiscalite === "MONUMENT_HISTORIQUE histo" && tvaMonument) ||
          (typeFiscalite === "REGIME_GENERAL" && tvaRegime)
        )
        && model.solutionFiscale.financement.monofinancement.produit_adossement.capitalisation_tva
        ? model.solutionFiscale.placement.getValidPlacementTVA() : true
    )

    if (!valid) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir la durée et/ou le taux de capitalisation associé à votre placement.", "placement");
      return;
    }

    if (!validTva) {
      statutOk = false;
      this.showMsgErr("Vous devez saisir la durée et/ou le taux de capitalisation associé à votre placement TVA.", "placement");
      return;
    }

    if (!model?.solutionFiscale?.mono_financement_mode) {
      financement.sommeMontFinance();
      const resteAfinancer = financement.montantAfinancer - financement.sommeMontantFinanc;
      if (resteAfinancer !== 0) {
        let msg =
          <div >
            <p className="px-2 text-left">Le montant du financement est différent du montant de l'opération</p>
            <p className="px-2 text-left">Il y a une différence de {resteAfinancer} €.</p>
            <p className="px-2 text-left">Vous devez modifier :</p>
            <p className="px-4 text-left">- le montant d'un emprunt</p>
            <p className="px-4 text-left">- le montant de l'apport</p>
          </div>
        this.showMsgErr(msg, "multi");
        return;
      }
    }
    return statutOk;
  }

  isNull(value: any) {
    return (value == null || value === 0);
  }

  getBoolean(value: any) {
    if (null == value) {
      return false;
    }
    else if (!Boolean(value)) {
      return false;
    }
    else {
      return value;
    }
  }

  showMsgErr(msg: string | JSX.Element, section: string) {
    const { model, changeSectionSolution } = this.props;
    this.props.model.solutionFiscale.support.isAdd = true;
    model.solutionFiscale.placement.isAdd = true;
    this.props.model.solutionFiscale.financement.isAdd = true;
    this.props.model.solutionFiscale.support.isAdd = true;
    history.push("/solution-fiscal");
    this.modalError(msg);
    changeSectionSolution(model, section, true);
  }

  modalError(msg: string | JSX.Element) {
    const { model } = this.props;
    model.isModalOpen = true;
    model.loading = false;
    // model.modal.provider =
    //   model.solutionFiscale.support.revenuLocatifImmobillier;
    model.modal.size = "sm";
    model.modal.data = <div className="px-2 text-center">{msg}</div>;
    model.modal.showCancel = false;
    model.modal.showOk = true;
    model.modal.mode = "error";
    model.modal.title = "Easy Defisc";
    // setTimeout(() => {
    //   replaceModel(model);
    // }, 10000);
  }

  navigueToShowOui(section: string) {
    const { model, changeSectionSolution } = this.props;
    this.props.model.solutionFiscale.support.isAdd = true;
    model.solutionFiscale.placement.isAdd = true;
    this.props.model.solutionFiscale.financement.isAdd = true;
    this.props.model.solutionFiscale.support.isAdd = true;
    history.push("/solution-fiscal");
    changeSectionSolution(model, section, true);
  }

}
const connected = connector(DashboardContent);
export { connected as DashboardContent };

