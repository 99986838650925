export class SolutionDispositif{
    id : number = 0;
    dateLivraison : string = "";
    fraisAnnexe : string = "";
    labelProjetSolution : string = "";
    loyerMensuel : string = "";
    projet : string = "";
    superficieFiscale : string = "";
    valeurBienATerme : string = "";
    valorisation : string = "";

    formJson(data : any){
        if(!data){
            return;
        }else{
            this.id = data?.id;
            this.dateLivraison = data?.dateLivraison;
            this.fraisAnnexe = data?.fraisAnnexe;
            this.labelProjetSolution = data?.labelProjetSolution;
            this.loyerMensuel = data?.loyerMensuel;
            this.projet = data?.projet;
            this.superficieFiscale = data?.superficieFiscale;
            this.valeurBienATerme = data?.valeurBienATerme;
            this.valorisation = data?.valorisation;
        }
    }
}

 